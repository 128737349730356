import { Question } from './question.model';

export class Section {
  constructor();
  constructor(id: number, nameTranslationKey: string, order: number, description: string, questions: Question[], subsections: Section[]);
  constructor(
    public id?: number,
    public nameTranslationKey?: string,
    public order?: number,
    public description?: string,
    public questions?: Question[],
    public subsections?: Section[]
  ) {}
}
