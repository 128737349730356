import { Injectable } from '@angular/core';
import { StyleBuilder, StyleDefinition, StyleUtils } from '@angular/flex-layout/core';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
export const CLEAR_MARGIN_CSS: any = {
  'margin-left': null,
  'margin-right': null,
  'margin-top': null,
  'margin-bottom': null
};
export const GRID_SPECIFIER = ' grid';

@Injectable()
export class CustomLayoutGapStyleBuilder extends StyleBuilder {
  /**
   * @param {?} _styler
   */
  _styler: StyleUtils;
  constructor(_styler: StyleUtils) {
    super();
    this._styler = _styler;
  }
  /**
   * @param {?} gapValue
   * @param {?} parent
   * @return {?}
   */
  public buildStyles(gapValue: string, parent?: any): StyleDefinition {
    if (!!gapValue && gapValue.endsWith(GRID_SPECIFIER)) {
      gapValue = gapValue.slice(0, gapValue.indexOf(GRID_SPECIFIER));
      // Add the margin to the host element
      return this._buildGridMargin(gapValue, parent.directionality);
    } else {
      return {};
    }
  }
  /**
   * @param {?} gapValue
   * @param {?} _styles
   * @param {?} parent
   * @return {?}
   */
  public sideEffect(gapValue: string, _styles: StyleDefinition, parent?: any): void {
    /** @type {?} */
    const items: any = parent.items;
    if (!!gapValue && gapValue.endsWith(GRID_SPECIFIER)) {
      gapValue = gapValue.slice(0, gapValue.indexOf(GRID_SPECIFIER));
      // For each `element` children, set the padding
      /** @type {?} */
      const paddingStyles: any = this._buildGridPadding(gapValue, parent.directionality);
      this._styler.applyStyleToElements(paddingStyles, parent.items);
    } else {
      /** @type {?} */
      const lastItem: any = /** @type {?} */ items.pop();
      // For each `element` children EXCEPT the last,
      // set the margin right/bottom styles...
      /** @type {?} */
      const gapCss: any = this._buildGapCSS(gapValue, parent);
      this._styler.applyStyleToElements(gapCss, items);
      // Clear all gaps for all visible elements
      this._styler.applyStyleToElements(CLEAR_MARGIN_CSS, [lastItem]);
    }
  }
  /**
   * @param {?} value
   * @param {?} directionality
   * @return {?}
   */
  private _buildGridMargin(value: string, directionality: any): any {
    /** @type {?} */
    let marginTop: string = '0px';
    /** @type {?} */
    let marginRight: string = '0px';
    /** @type {?} */
    let marginBottom: string = '-' + value;
    /** @type {?} */
    let marginLeft: string = '0px';
    if (directionality === 'rtl') {
      marginLeft = '-' + value;
    } else {
      marginRight = '-' + value;
    }
    return { margin: `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}` };
  }

  /**
   * @param {?} gapValue
   * @param {?} parent
   * @return {?}
   */
  private _buildGapCSS(gapValue: string, parent: any): any {
    /** @type {?} */
    const key: any = this._getMarginType(parent.directionality, parent.layout);
    /** @type {?} */
    const margins: any = Object.assign({}, CLEAR_MARGIN_CSS);
    margins[key] = gapValue;
    return margins;
  }

  private _buildGridPadding(value: string, directionality: string): any {
    /** @type {?} */
    let paddingTop: any = '0px';
    /** @type {?} */
    let paddingRight: any = '0px';
    /** @type {?} */
    let paddingBottom: any = value;
    /** @type {?} */
    let paddingLeft: any = '0px';
    if (directionality === 'rtl') {
      paddingLeft = value;
    } else {
      paddingRight = value;
    }
    return { padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}` };
  }

  /**
   * @param {?} directionality
   * @param {?} layout
   * @return {?}
   */
  private _getMarginType(directionality: string, layout: string): string {
    switch (layout) {
      case 'column':
        return 'margin-bottom';
      case 'column-reverse':
        return 'margin-top';
      case 'row':
        return directionality === 'rtl' ? 'margin-left' : 'margin-right';
      case 'row-reverse':
        return directionality === 'rtl' ? 'margin-right' : 'margin-left';
      default:
        return directionality === 'rtl' ? 'margin-left' : 'margin-right';
    }
  }
}
