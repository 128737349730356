import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/core/services';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.checkLogin();
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    return this.checkLogin();
  }

  private checkLogin(): Observable<boolean> | boolean {
    return this.authService.isLoadingFinished().pipe(
      filter(isLoadingFinished => isLoadingFinished),
      switchMap(_ => {
        return this.authService.isAuthenticated();
      }),
      first()
    );
  }
}
