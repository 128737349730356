import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { convertToPartial, isNotNullNorUndefined } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Survey, SurveySite, TravelPlan } from '../model';
import { ApiTravelPlan, ApiTravelPlanService } from './api-travel-plan.service';

export interface ApiSurvey {
  id?: number;
  survey_name_en?: string;
  survey_name_fr?: string;
  survey_name_nl?: string;
  bce_num?: string;
  export_url?: string;
  sites: ApiSurveySite[];
}

export interface ApiSurveySite {
  id?: number;
  site_adress?: string;
  site_name_en?: string;
  site_name_fr?: string;
  site_name_nl?: string;
  export_url?: string;
}

interface ApiSurveyPayload {
  export_url: string;
  survey_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiSurveyService {
  constructor(private http: HttpClient, private apiTravelPlanService: ApiTravelPlanService) {}

  getAllSurveys(): Observable<Survey[]> {
    return this.http.get<ApiSurvey[]>(`${environment.apiUrl}/gismob/all`).pipe(
      map(apiSurveys => {
        return apiSurveys.map(apiSurvey => this.surveyFromApiToModel(apiSurvey));
      })
    );
  }

  importSurvey(planId: number, exportURL: string, surveyID: number): Observable<TravelPlan> {
    const payload: ApiSurveyPayload = {
      export_url: exportURL,
      survey_id: surveyID
    };
    return this.http.post<ApiTravelPlan>(`${environment.apiUrl}/gismob/${planId}`, payload).pipe(
      map(apiTravelPlan => {
        if ('validation_status' in apiTravelPlan) {
          return apiTravelPlan;
        } else {
          return this.apiTravelPlanService.travelPlanFromApiToModel(apiTravelPlan);
        }
      })
    );
  }

  importExcelFile(planId: number, excelFileName: string, excelFileAsBase64: string, ignoreValidation: boolean): Observable<TravelPlan> {
    const payload = {
      filename: excelFileName,
      file: excelFileAsBase64,
      ignore_validation: ignoreValidation
    };
    return this.http.post<ApiTravelPlan>(`${environment.apiUrl}/gismob/${planId}`, payload).pipe(
      map(apiTravelPlan => {
        if ('validation_status' in apiTravelPlan) {
          return apiTravelPlan;
        } else {
          return this.apiTravelPlanService.travelPlanFromApiToModel(apiTravelPlan);
        }
      })
    );
  }

  surveyFromApiToModel(apiSurvey: ApiSurvey): Survey {
    return {
      id: apiSurvey.id,
      nameFR: apiSurvey.survey_name_fr,
      nameNL: apiSurvey.survey_name_nl,
      nameEN: apiSurvey.survey_name_en,
      bceNumber: apiSurvey.bce_num,
      exportURL: apiSurvey.export_url,
      sites: isNotNullNorUndefined(apiSurvey.sites)
        ? apiSurvey.sites.map(apiSurveySite => this.surveySiteFromApiToModel(apiSurveySite))
        : []
    };
  }

  surveyFromModelToApi(survey: Partial<Survey>): Partial<ApiSurvey> {
    const apiSurvey: ApiSurvey = {
      id: survey.id,
      survey_name_fr: survey.nameFR,
      survey_name_nl: survey.nameNL,
      survey_name_en: survey.nameEN,
      bce_num: survey.bceNumber,
      export_url: survey.exportURL,
      sites: isNotNullNorUndefined(survey.sites) ? survey.sites.map(surveySite => this.surveySiteFromModelToApi(surveySite)) : []
    };

    return convertToPartial(apiSurvey);
  }

  surveySiteFromApiToModel(apiSurveySite: ApiSurveySite): SurveySite {
    return {
      id: apiSurveySite.id,
      nameFR: apiSurveySite.site_name_fr,
      nameNL: apiSurveySite.site_name_nl,
      nameEN: apiSurveySite.site_name_en,
      exportURL: apiSurveySite.export_url,
      address: apiSurveySite.site_adress
    };
  }

  surveySiteFromModelToApi(surveySite: Partial<SurveySite>): Partial<ApiSurveySite> {
    const apiSurvey: ApiSurveySite = {
      id: surveySite.id,
      site_name_fr: surveySite.nameFR,
      site_name_nl: surveySite.nameNL,
      site_name_en: surveySite.nameEN,
      export_url: surveySite.exportURL,
      site_adress: surveySite.address
    };

    return convertToPartial(apiSurvey);
  }
}
