import { HttpErrorResponse } from '@angular/common/http';
import { LoadCurrentUserResponse, LoginResponse } from '@app/core/api';
import { LoginCredentials } from '@app/core/model';
import { RegistrationData } from '@app/core/model/registration-data.model';
import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGOUT = '[Auth] Logout',
  LOAD_CURRENT_USER = '[Auth] Load Current User',
  LOAD_CURRENT_USER_SUCCESS = '[Auth] Load Current User Success',
  LOAD_CURRENT_USER_FAILURE = '[Auth] Load Current User Failure',
  REGISTER = '[Auth] Register',
  REGISTER_OLD_USER = '[Auth] Register Old User',
  REGISTER_SUCCESS = '[Auth] Register Success',
  REGISTER_FAILURE = '[Auth] Register Failure',
  RESET_PASSWORD = '[Auth] reset password',
  RESET_PASSWORD_SUCCESS = '[Auth] reset password Success',
  RESET_PASSWORD_FAILURE = '[Auth] reset password Failure',
  SET_NEW_PASSWORD = '[Auth] set new password',
  SET_NEW_PASSWORD_SUCCESS = '[Auth] set new password Success',
  SET_NEW_PASSWORD_FAILURE = '[Auth] set new password Failure',
  DELETE_ACCOUNT = '[Auth] Delete Account',
  DELETE_ACCOUNT_SUCCESS = '[Auth] Delete Account Success',
  DELETE_ACCOUNT_FAILURE = '[Auth] Delete Account Failure'
}

export class Login implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public credentials: LoginCredentials) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public loginResponse: LoginResponse) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public error: HttpErrorResponse) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class LoadCurrentUser implements Action {
  readonly type = AuthActionTypes.LOAD_CURRENT_USER;
}

export class LoadCurrentUserSuccess implements Action {
  readonly type = AuthActionTypes.LOAD_CURRENT_USER_SUCCESS;
  constructor(public loadCurrentUserResponse: LoadCurrentUserResponse) {}
}

export class LoadCurrentUserFailure implements Action {
  readonly type = AuthActionTypes.LOAD_CURRENT_USER_FAILURE;
}

export class Register implements Action {
  readonly type = AuthActionTypes.REGISTER;
  constructor(public registrationData: RegistrationData) {}
}

export class RegisterOldUser implements Action {
  readonly type = AuthActionTypes.REGISTER_OLD_USER;
  constructor(public registrationData: RegistrationData, public token: string) {}
}

export class RegisterSuccess implements Action {
  readonly type = AuthActionTypes.REGISTER_SUCCESS;
  constructor(public loginResponse: LoginResponse) {}
}

export class RegisterFailure implements Action {
  readonly type = AuthActionTypes.REGISTER_FAILURE;
}

export class ResetPassword implements Action {
  readonly type = AuthActionTypes.RESET_PASSWORD;
  constructor(public emailAddress: string) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = AuthActionTypes.RESET_PASSWORD_SUCCESS;
  constructor(public userData: LoginResponse) {}
}

export class ResetPasswordFailure implements Action {
  readonly type = AuthActionTypes.RESET_PASSWORD_FAILURE;
}

export class SetNewPassword implements Action {
  readonly type = AuthActionTypes.SET_NEW_PASSWORD;
  constructor(public password: string, public token: string) {}
}

export class SetNewPasswordSuccess implements Action {
  readonly type = AuthActionTypes.SET_NEW_PASSWORD_SUCCESS;
  constructor(public userData: LoginResponse) {}
}

export class SetNewtPasswordFailure implements Action {
  readonly type = AuthActionTypes.SET_NEW_PASSWORD_FAILURE;
}

export class DeleteAccount implements Action {
  readonly type = AuthActionTypes.DELETE_ACCOUNT;
  constructor() {}
}

export class DeleteAccountSuccess implements Action {
  readonly type = AuthActionTypes.DELETE_ACCOUNT_SUCCESS;
  constructor() {}
}

export class DeleteAccountFailure implements Action {
  readonly type = AuthActionTypes.DELETE_ACCOUNT_FAILURE;
  constructor(public error: HttpErrorResponse) {}
}

export type AuthActions =
  | Login
  | LoginSuccess
  | LoginFailure
  | Logout
  | LoadCurrentUser
  | LoadCurrentUserSuccess
  | LoadCurrentUserFailure
  | Register
  | RegisterOldUser
  | RegisterSuccess
  | RegisterFailure
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | SetNewPassword
  | SetNewPasswordSuccess
  | SetNewtPasswordFailure
  | DeleteAccount
  | DeleteAccountSuccess
  | DeleteAccountFailure;
