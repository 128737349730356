import { Injectable } from '@angular/core';
import { ApiRoleService } from '@app/core/api';
import { SnackbarService } from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { GetCompany } from '../actions';
import {
  AddRole,
  AddRoleFailure,
  AddRoleSuccess,
  DeleteRole,
  DeleteRoleFailure,
  DeleteRoleSuccess,
  GetAllRoles,
  GetRole,
  GetRoleFailure,
  GetRolesByCompanyId,
  GetRolesByUserId,
  GetRolesFailure,
  GetRolesSuccess,
  GetRoleSuccess,
  PromoteUser,
  PromoteUserFailure,
  PromoteUserSuccess,
  RoleActionTypes,
  UpdateRole,
  UpdateRoleFailure,
  UpdateRoleSuccess
} from '../actions/role.actions';

@Injectable()
export class RoleEffects {
  constructor(private snackbarService: SnackbarService, private actions: Actions, private apiRoleService: ApiRoleService) {}

  @Effect()
  getAllRoles$: Observable<any> = this.actions.pipe(
    ofType<GetAllRoles>(RoleActionTypes.GET_ALL_ROLES),
    concatMap(() => {
      return this.apiRoleService.getAllRoles().pipe(
        map(roles => {
          return new GetRolesSuccess(roles);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetRolesFailure());
        })
      );
    })
  );

  @Effect()
  GetRolesByUserId$: Observable<any> = this.actions.pipe(
    ofType<GetRolesByUserId>(RoleActionTypes.GET_ROLES_BY_USER_ID),
    concatMap(action => {
      return this.apiRoleService.getRolesByUserId(action.userId).pipe(
        map(roles => {
          return new GetRolesSuccess(roles);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetRolesFailure());
        })
      );
    })
  );

  @Effect()
  GetRolesByCompanyId$: Observable<any> = this.actions.pipe(
    ofType<GetRolesByCompanyId>(RoleActionTypes.GET_ROLES_BY_COMPANY_ID),
    concatMap(action => {
      return this.apiRoleService.getRolesByCompanyId(action.companyId).pipe(
        map(roles => {
          return new GetRolesSuccess(roles);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetRolesFailure());
        })
      );
    })
  );

  @Effect()
  getRole$: Observable<any> = this.actions.pipe(
    ofType<GetRole>(RoleActionTypes.GET_ROLE),
    concatMap(action => {
      return this.apiRoleService.getRole(action.roleId).pipe(
        map(role => {
          return new GetRoleSuccess(role);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetRoleFailure());
        })
      );
    })
  );

  @Effect()
  addRole$: Observable<any> = this.actions.pipe(
    ofType<AddRole>(RoleActionTypes.ADD_ROLE),
    concatMap(action => {
      return this.apiRoleService.addRole(action.role).pipe(
        switchMap(role => {
          this.snackbarService.openWithTranslation('i18n.RoleEffects.add-role-success');
          return [new GetCompany(role.companyId), new AddRoleSuccess(role)];
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.RoleEffects.add-role-failure');
          return of(new AddRoleFailure());
        })
      );
    })
  );

  @Effect()
  updateRole$: Observable<any> = this.actions.pipe(
    ofType<UpdateRole>(RoleActionTypes.UPDATE_ROLE),
    concatMap(action => {
      return this.apiRoleService.updateRole(action.update).pipe(
        map(_ => {
          this.snackbarService.openWithTranslation('i18n.RoleEffects.update-role-success');
          console.log(action.update);
          return new UpdateRoleSuccess(action.update);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.RoleEffects.update-role-failure');
          return of(new UpdateRoleFailure());
        })
      );
    })
  );

  @Effect()
  deleteRole$: Observable<any> = this.actions.pipe(
    ofType<DeleteRole>(RoleActionTypes.DELETE_ROLE),
    concatMap(action => {
      return this.apiRoleService.deleteRole(action.roleId).pipe(
        map(_ => {
          this.snackbarService.openWithTranslation('i18n.RoleEffects.delete-role-success');
          return new DeleteRoleSuccess(action.roleId);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.RoleEffects.delete-role-failure');
          return of(new DeleteRoleFailure());
        })
      );
    })
  );

  @Effect()
  promoteUser$: Observable<any> = this.actions.pipe(
    ofType<PromoteUser>(RoleActionTypes.PROMOTE_USER),
    concatMap(action => {
      return this.apiRoleService.promoteUser(action.payload).pipe(
        map(role => {
          this.snackbarService.openWithTranslation('i18n.RoleEffects.update-role-success');
          return new PromoteUserSuccess(action.payload);
        }),
        catchError(error => {
          console.log(error);
          return of(new PromoteUserFailure());
        })
      );
    })
  );
}
