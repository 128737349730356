<mat-toolbar class="header-toolbar" fxLayout="row" fxLayoutAlign="center stretch">
  <div class="responsive-width" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
      <img src="assets/images/tree-icon.svg" class="Tree_icon" />
      <span class="header-toolbar-title primary-color" ngClass.gt-sm="mat-h2" ngClass.lt-md="mat-h3" ngClass.lt-sm="mat-h4">
        {{ 'i18n.HeaderComponent.title' | translate }}
      </span>
    </div>

    <div class="mat-h4">
      <span
        class="header-language-selector"
        [ngClass]="{ 'header-language-selector-active': isCurrentLanguageFrench() }"
        (click)="changeLanguageToFrench()"
      >
        FR
      </span>
      <span class="primary-color"> - </span>
      <span
        class="header-language-selector"
        [ngClass]="{ 'header-language-selector-active': isCurrentLanguageDutch() }"
        (click)="changeLanguageToDutch()"
      >
        NL
      </span>
    </div>
  </div>
</mat-toolbar>

<div class="header-navbar" fxLayout="row" fxLayoutAlign="center stretch">
  <div class="responsive-width" fxLayout="row" fxLayoutAlign="space-between center">
    <a mat-button [ngClass]="{ 'active-path': isCurrentPathHome }" (click)="onHomeButtonPressed()" fxFlex="0 0 auto">{{
      'i18n.HeaderComponent.home' | translate
    }}</a>
    <a
      mat-button
      [ngClass]="{ 'active-path': isCurrentPathMyForms }"
      (click)="onMyFormsButtonPressed()"
      [disabled]="!currentUser"
      fxFlex="0 0 auto"
      >{{ 'i18n.HeaderComponent.my-forms' | translate }}</a
    >

    <button mat-button [disabled]="!currentUser" (click)="onMySurveysButtonPressed()" fxFlex="0 0 auto">
      {{ 'i18n.HeaderComponent.my-surveys' | translate }}
    </button>

    <ng-container *ngIf="currentUser; else loginButtonTemplate">
      <a
        mat-flat-button
        [ngClass]="{ 'active-path': isCurrentPathMyAccount }"
        color="primary"
        (click)="onMyAccountButtonPressed()"
        [matBadge]="numberOfPendingRolesFromCompaniesWhereCurrentUserIsAdmin$ | async"
        [matBadgeHidden]="!(isPendingRolesFromCompaniesWhereCurrentUserIsAdmin$ | async)"
        fxFlex="0 0 auto"
      >
        <mat-icon>account_circle</mat-icon>
        {{ 'i18n.HeaderComponent.my-account' | translate }}
      </a>
    </ng-container>
    <ng-template #loginButtonTemplate>
      <button mat-flat-button color="primary" (click)="onLoginButtonPressed()" fxFlex="0 0 auto">
        <mat-icon>account_circle</mat-icon>
        {{ 'i18n.HeaderComponent.login' | translate }}
      </button>
    </ng-template>
  </div>
</div>
