import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CustomFormFieldSettings, CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';
import { environment } from '@env/environment';
import { SubSink } from 'subsink';

function matchingPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.parent || !control.parent.get('password')) {
      return null;
    }
    const passwordControl = control.parent.get('password');
    const passwordConfirmationControl = control;

    const passwordNotMatching =
      passwordControl &&
      passwordConfirmationControl &&
      passwordConfirmationControl.value &&
      passwordControl.value !== passwordConfirmationControl.value;

    return passwordNotMatching ? { passwordNotMatching: { value: true } } : null;
  };
}

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
  @Output() cancelButtonPressed = new EventEmitter();
  @Output() submitButtonPressed = new EventEmitter<any>();

  form: FormGroup;
  fieldsSettings: CustomFormFieldSettings[];

  subSink = new SubSink();

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern(environment.passwordPattern)]),
      passwordConfirmation: new FormControl('', [Validators.required, matchingPasswordValidator()])
    });

    this.subSink.sink = this.form.get('password').valueChanges.subscribe(changes => {
      this.form.get('passwordConfirmation').updateValueAndValidity();
    });

    this.fieldsSettings = [
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.PasswordInput,
        label: 'i18n.ChangePasswordDialogComponent.old-password',
        required: true,
        control: this.form.get('oldPassword') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.PasswordInput,
        label: 'i18n.User.password',
        required: true,
        control: this.form.get('password') as FormControl,
        customError: { errorName: 'pattern', message: 'i18n.RegisterDialogComponent.password-not-following-pattern' }
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.PasswordInput,
        label: 'i18n.RegisterDialogComponent.password-confirmation',
        required: true,
        control: this.form.get('passwordConfirmation') as FormControl,
        customError: { errorName: 'passwordNotMatching', message: 'i18n.RegisterDialogComponent.password-not-matching-error' }
      }
    ];
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onCancelButtonPressed(): void {
    this.cancelButtonPressed.emit();
  }

  onSubmitButtonPressed(): void {
    this.submitButtonPressed.emit({
      old_password: this.form.get('oldPassword').value,
      new_password: this.form.get('password').value,
      new_password_confirmation: this.form.get('passwordConfirmation').value
    });
  }

  isSubmitButtonDisabled(): boolean {
    return this.form.invalid;
  }
}
