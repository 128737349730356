import { UserType } from '@app/core/model';
import { AuthActions, AuthActionTypes } from '../actions';

export interface AuthState {
  isAuthenticated: boolean;
  currentToken: string;
  currentUserId: number;
  isLoadingFinished: boolean;
  // TODO: Maybe use currentUserType through the UserState instead
  currentUserType: UserType;
}

const initialState: AuthState = {
  isAuthenticated: false,
  currentToken: null,
  currentUserId: null,
  isLoadingFinished: false,
  currentUserType: null
};

export function authReducer(state = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        currentToken: action.loginResponse.token,
        currentUserId: action.loginResponse.user.id,
        currentUserType: action.loginResponse.user.type
      };
    }
    case AuthActionTypes.LOGOUT: {
      return { ...initialState, isLoadingFinished: state.isLoadingFinished };
    }
    case AuthActionTypes.LOAD_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        currentToken: action.loadCurrentUserResponse.token,
        currentUserId: action.loadCurrentUserResponse.currentUser.id,
        isLoadingFinished: true,
        currentUserType: action.loadCurrentUserResponse.currentUser.type
      };
    }
    case AuthActionTypes.LOAD_CURRENT_USER_FAILURE: {
      return { ...initialState, isLoadingFinished: true };
    }
    default: {
      return state;
    }
  }
}
