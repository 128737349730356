import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language, User, UserType } from '@app/core/model';
import { CustomizedGlobalErrorHandler } from '@app/errorhandler/customizedglobalerrorhandler';
import { convertToPartial } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ApiUserType {
  Basic = 'Basic',
  AdminBE = 'AdminBE'
}

export enum ApiLanguage {
  French = 'French',
  Dutch = 'Dutch'
}

export interface ApiUser {
  id: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  profession?: string;
  language?: ApiLanguage;
  mobile_number?: string;
  mobility_contact_communication?: boolean;
  enews?: boolean;
  distribution_letter?: boolean;
  type_user?: ApiUserType;
}

@Injectable({
  providedIn: 'root'
})
export class ApiUserService extends CustomizedGlobalErrorHandler {
  constructor(private http: HttpClient) {
    super();
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<ApiUser[]>(`${environment.apiUrl}/users`).pipe(
      map(apiUsers => {
        return apiUsers.map(apiUser => this.userFromApiToModel(apiUser));
      })
    );
  }

  getAllAdminUsers(): Observable<User[]> {
    const params = new HttpParams().set('type_user', ApiUserType.AdminBE);

    return this.http.get<ApiUser[]>(`${environment.apiUrl}/users`, { params }).pipe(
      map(apiAdminUsers => {
        return apiAdminUsers.map(apiAdminUser => this.userFromApiToModel(apiAdminUser));
      })
    );
  }

  getUser(id: number): Observable<User> {
    return this.http.get<ApiUser>(`${environment.apiUrl}/users/${id}`).pipe(
      map(apiUser => {
        return this.userFromApiToModel(apiUser);
      })
    );
  }

  getUserByToken(token: string): Observable<User> {
    return this.http.get<ApiUser>(`${environment.apiUrl}/users/get_user/${token}`).pipe(
      map(apiUser => {
        return this.userFromApiToModel(apiUser);
      })
    );
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<ApiUser>(`${environment.apiUrl}/users/${user.id}`, this.userFromModelToApi(user)).pipe(
      map(apiUser => {
        return this.userFromApiToModel(apiUser);
      })
    );
  }

  changePassword(data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users/changepassword`, data);
  }

  userFromApiToModel(apiUser: ApiUser): User {
    const language = this.languageFromApitoModel(apiUser.language);

    return {
      id: apiUser.id,
      firstName: apiUser.first_name,
      lastName: apiUser.last_name,
      emailAddress: apiUser.email,
      phoneNumber: apiUser.phone_number,
      profession: apiUser.profession,
      language,
      mobileNumber: apiUser.mobile_number,
      mobilityContactCommunication: apiUser.mobility_contact_communication,
      enews: apiUser.enews,
      distributionLetter: apiUser.distribution_letter,
      type: this.userTypeFromApitoModel(apiUser.type_user)
    };
  }

  userFromModelToApi(user: Partial<User>): Partial<ApiUser> {
    const language = user.language ? this.languageFromModelToApi(user.language) : undefined;
    // tslint:disable-next-line: variable-name
    const type_user = user.type ? this.userTypeFromModelToApi(user.type) : undefined;

    return convertToPartial({
      id: user.id,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.emailAddress,
      phone_number: user.phoneNumber,
      profession: user.profession,
      language,
      mobile_number: user.mobileNumber,
      mobility_contact_communication: user.mobilityContactCommunication,
      enews: user.enews,
      distribution_letter: user.distributionLetter,
      type_user
    });
  }

  userTypeFromApitoModel(apiUserType: ApiUserType): UserType {
    switch (apiUserType) {
      case ApiUserType.Basic:
        return UserType.Basic;
      case ApiUserType.AdminBE:
        return UserType.AdminBE;
      default:
        return UserType.Basic;
    }
  }

  userTypeFromModelToApi(userType: UserType): ApiUserType {
    switch (userType) {
      case UserType.Basic:
        return ApiUserType.Basic;
      case UserType.AdminBE:
        return ApiUserType.AdminBE;
      default:
        console.log(`userType was not recognized: ${userType}`);
        return null;
    }
  }

  languageFromApitoModel(apiLanguage: ApiLanguage): Language {
    switch (apiLanguage) {
      case ApiLanguage.French:
        return Language.French;
      case ApiLanguage.Dutch:
        return Language.Dutch;
      default:
        console.log(`apiLanguage was not recognized: ${apiLanguage}`);
        return null;
    }
  }

  languageFromModelToApi(language: Language): ApiLanguage {
    switch (language) {
      case Language.French:
        return ApiLanguage.French;
      case Language.Dutch:
        return ApiLanguage.Dutch;
      default:
        console.log(`language was not recognized: ${language}`);
        return null;
    }
  }
}
