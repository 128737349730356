import { Role, RoleStatus } from '@app/core/model/role.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RoleActions, RoleActionTypes } from '../actions';

export interface RoleState extends EntityState<Role> {
  // additional entity state properties
}

export const roleAdapter: EntityAdapter<Role> = createEntityAdapter<Role>();

const initialState: RoleState = roleAdapter.getInitialState({
  // additional entity state properties
});

export function roleReducer(state = initialState, action: RoleActions): RoleState {
  switch (action.type) {
    case RoleActionTypes.GET_ROLES_SUCCESS: {
      return roleAdapter.addAll(action.roles, state);
    }
    case RoleActionTypes.GET_ROLE_SUCCESS: {
      return roleAdapter.addOne(action.role, state);
    }
    case RoleActionTypes.ADD_ROLE_SUCCESS: {
      return roleAdapter.addOne(action.role, state);
    }
    case RoleActionTypes.UPDATE_ROLE_SUCCESS: {
      return roleAdapter.updateOne(action.update, state);
    }
    case RoleActionTypes.DELETE_ROLE_SUCCESS: {
      return roleAdapter.removeOne(action.roleId, state);
    }
    case RoleActionTypes.PROMOTE_USER_SUCCESS: {
      const newAdmin = Object.values(state.entities).filter(role => role.id === action.payload.id)[0];
      const roles = Object.values(state.entities)
        .filter(role => role.id !== newAdmin.id && role.companyId === newAdmin.companyId)
        .map(role => {
          return { id: role.id, changes: { status: RoleStatus.Affiliated } };
        });
      return roleAdapter.updateMany([action.payload, ...roles], state);
    }
    default: {
      return state;
    }
  }
}
