import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { isNullOrUndefined } from 'util';

export enum CUSTOM_FORM_FIELD_TYPE {
  TextInput = 'text',
  EmailInput = 'email',
  PasswordInput = 'password',
  Integer = 'number',
  TextArea = 'textarea',
  Select = 'select',
  Checkbox = 'checkbox',
  MultiCheckbox = 'multicheckbox',
  DateInput = 'date',
  Radio = 'radio',
  Slider = 'slider',
  File = 'file',
  Percentage = 'percentage',
  Double = 'double',
  YesNo = 'yesno'
}

export interface CustomFormFieldSelectOption {
  text: string;
  value: any;
}

export interface CustomFormFieldCustomError {
  errorName: string;
  message: string;
}

export class CustomFormFieldSettings {
  type: CUSTOM_FORM_FIELD_TYPE = CUSTOM_FORM_FIELD_TYPE.TextInput;
  placeholder: string;
  label: string;
  required = false;
  autocomplete = 'off';
  hint: string;
  options: CustomFormFieldSelectOption[];
  control: FormControl = new FormControl('');
  removeHintPadding = false;
  customError: CustomFormFieldCustomError;
  isInline = false;
  hasBoldLabel = true;
  description: string;
  unit: string;
  min: number;
  max: number;
  isSmallFileInputButton: boolean;
  acceptMultipleFiles = true;
  onlyAcceptPDFFiles = false;
  maximumFileSizeInBytes: number;
}

/** Error when invalid control is dirty or touched */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-custom-form-field',
  templateUrl: './custom-form-field.component.html',
  styleUrls: ['./custom-form-field.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class CustomFormFieldComponent implements OnInit, OnChanges, OnDestroy {
  readonly TYPE_ENUM = CUSTOM_FORM_FIELD_TYPE; // Used to access to the enum in the template file
  @Input() settings: CustomFormFieldSettings = new CustomFormFieldSettings();
  @Input() isReadOnly = false;
  @Input() fileUploadSuccess: EventEmitter<boolean>;
  @Output() fileUploaded = new EventEmitter<File>();
  @Output() fileInputForbiddenExtension = new EventEmitter();
  @Output() fileInputIsTooLarge = new EventEmitter();
  @Output() fileInputAlreadyExist = new EventEmitter();
  @Output() fileDownloadPressed = new EventEmitter<string>();
  @Output() fileDeletePressed = new EventEmitter<string>();

  readonly appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'outline';
  readonly floatLabel: 'auto' | 'never' | 'always' = 'always';
  readonly textAreaAutosizeMinRows = 3;
  readonly textAreaAutosizeMaxRows = 15;
  readonly acceptedFileTypes: string = '.pdf, image/*, .xlsx, .xls, .docx, .doc, .rar, .zip, .tar, .7z, .gz';
  readonly maxNumberOfFiles = 5;
  readonly maximumFileSizeInBytes = 1048576 * 10; // 10MB
  readonly maxDate = new Date(Date.now());
  errorStateMatcher = new MyErrorStateMatcher();
  numberInputMin = 0;
  numberInputMax = null;
  fileNamesList: string[] = [];
  isImportFileButtonVisible = true;

  multiCheckboxFormGroup: FormGroup = new FormGroup({});
  multiCheckboxChangesSubscription: Subscription;

  subSink = new SubSink();

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  constructor() {}

  ngOnInit() {
    if (isNotNullNorUndefined(this.fileUploadSuccess)) {
      this.subSink.sink = this.fileUploadSuccess.subscribe((isFileUploadSuccess: boolean) => {
        if (!isFileUploadSuccess) {
          this._removeLastFileFromInput();
          this._clearFileInput();
        }
      });
    }

    if (this.settings.type === CUSTOM_FORM_FIELD_TYPE.File) {
      if (isNotNullNorUndefined(this.settings.control.value)) {
        this.fileNamesList = this._parseMultipleFilesValueToList(this.settings.control.value);
      } else {
        this.settings.control.updateValueAndValidity();
      }
    }

    this._updateIsImportFileButtonVisible();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings) {
      this.numberInputMin = isNullOrUndefined(this.settings.min) ? this.numberInputMin : this.settings.min;
      this.numberInputMax = isNullOrUndefined(this.settings.max) ? this.numberInputMax : this.settings.max;

      if (this.settings.type === CUSTOM_FORM_FIELD_TYPE.MultiCheckbox) {
        this._initMultiCheckboxFormGroup(this.settings.options);
        this._updateMultiCheckboxFormGroupWithAnswer();
        this._updateMultiCheckboxFormGroupDisable();

        if (isNotNullNorUndefined(this.multiCheckboxChangesSubscription)) {
          this.multiCheckboxChangesSubscription.unsubscribe();
        }

        this.multiCheckboxChangesSubscription = this.multiCheckboxFormGroup.valueChanges.subscribe(changes => {
          const value: string = this._parseMultiCheckboxValueToString();
          this.settings.control.setValue(value);
        });

        this.subSink.add(this.multiCheckboxChangesSubscription);
      }

      if (this.settings.type === CUSTOM_FORM_FIELD_TYPE.File) {
        if (isNotNullNorUndefined(this.settings.control.value)) {
          this.fileNamesList = this._parseMultipleFilesValueToList(this.settings.control.value);
        } else {
          this.fileNamesList = [];
          this.settings.control.updateValueAndValidity();
        }

        this._updateIsImportFileButtonVisible();
      }
    }

    if (changes.isReadOnly) {
      this._updateMultiCheckboxFormGroupDisable();
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  private _initMultiCheckboxFormGroup(options: CustomFormFieldSelectOption[]): void {
    if (isNullOrUndefined(options)) {
      return;
    }

    this.multiCheckboxFormGroup = new FormGroup({}); // TODO: Maybe change this to clear without changing reference
    options.forEach(option => this._addCheckboxFormControl(option));
  }

  private _updateMultiCheckboxFormGroupDisable(): void {
    if (this.isReadOnly) {
      this.multiCheckboxFormGroup.disable();
    } else {
      this.multiCheckboxFormGroup.enable();
    }
  }

  private _updateMultiCheckboxFormGroupWithAnswer(): void {
    const formGroupValue: {
      [key: string]: boolean;
    } = {};

    const checkedOptions: string[] = this._parseStringToCheckedOptionsList(this.settings.control.value);

    this.settings.options.forEach(option => {
      formGroupValue[option.value] = checkedOptions.includes(option.value);
    });

    this.multiCheckboxFormGroup.patchValue(formGroupValue);
  }

  private _addCheckboxFormControl(option: CustomFormFieldSelectOption): void {
    if (isNullOrUndefined(option) || isNullOrUndefined(option.value)) {
      return;
    }

    this.multiCheckboxFormGroup.addControl(option.value, new FormControl(false));
  }

  private _parseMultiCheckboxValueToString(): string {
    const checkedOptions: string[] = Object.entries(this.multiCheckboxFormGroup.getRawValue())
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    if (isNullOrUndefined(checkedOptions) || checkedOptions.length === 0) {
      return null;
    }

    let result = '[';
    checkedOptions.forEach(option => {
      result += `"${option}"`;
      result += checkedOptions.length - 1 > checkedOptions.indexOf(option) ? ', ' : '';
    });
    result += ']';

    return result;
  }

  private _parseStringToCheckedOptionsList(value: string): string[] {
    const matchArray: RegExpMatchArray = value
      .replace(/^\[(.*)\]$/gm, '$1') // Remove first and last brackets
      .match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/gm); // Match every values seperated by a comma

    if (!isNotNullNorUndefined(matchArray)) {
      return [];
    }

    return matchArray.map(v => (isNotNullNorUndefined(v) ? v.replace(/^"(.*)"$/, '$1') : null)); // Remove quotes if they are the first and last characters of the string)
  }

  getHintMessage(): string {
    if (!this.settings.required && !this.settings.hint) {
      return 'i18n.CustomFormFieldComponent.optional-field';
    }

    return this.settings.hint;
  }

  getErrorMessage(): string {
    if (this.settings.control.hasError('required')) {
      return 'i18n.CustomFormFieldComponent.required-error';
    } else if (this.settings.control.hasError('min')) {
      // TODO: Show min value in error message
      return 'i18n.CustomFormFieldComponent.min-error';
    } else if (this.settings.control.hasError('max')) {
      // TODO: Show max value in error message
      return 'i18n.CustomFormFieldComponent.max-error';
    } else if (this.settings.customError && this.settings.control.hasError(this.settings.customError.errorName)) {
      return this.settings.customError.message;
    } else if (this.settings.control.hasError('email')) {
      return 'i18n.CustomFormFieldComponent.email-error';
    }
    return null;
  }

  isFormControlValueEmpty(): boolean {
    return (
      !isNotNullNorUndefined(this.settings.control) ||
      !isNotNullNorUndefined(this.settings.control.value) ||
      (this.settings.control.value as string).length === 0
    );
  }

  getAcceptedFileTypes(): string {
    if (isNotNullNorUndefined(this.settings) && this.settings.onlyAcceptPDFFiles) {
      return '.pdf';
    }
    return this.acceptedFileTypes;
  }

  getMaximumFileSizeInBytes(): number {
    return isNotNullNorUndefined(this.settings) && isNotNullNorUndefined(this.settings.maximumFileSizeInBytes)
      ? this.settings.maximumFileSizeInBytes
      : this.maximumFileSizeInBytes;
  }

  uploadFile(fileInputEvent: any): void {
    if (isNotNullNorUndefined(this.settings) && isNotNullNorUndefined(this.settings.control)) {
      const selectedFiles: FileList = fileInputEvent.target.files;
      const file: File = selectedFiles.length > 0 ? selectedFiles[0] : null;

      if (isNullOrUndefined(file)) {
        return;
      }

      const fileExtension: string = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
      const acceptedFileTypesArray: string[] = this.getAcceptedFileTypes()
        .split(',')
        .map(x => x.substring(x.lastIndexOf('.') + 1, x.length) || x);

      if (!file.type.startsWith('image/') && !acceptedFileTypesArray.includes(fileExtension)) {
        this.fileInputForbiddenExtension.emit();
        return;
      }

      if (file.size > this.getMaximumFileSizeInBytes()) {
        this.fileInputIsTooLarge.emit();
        return;
      }

      if (this.fileNamesList.includes(file.name)) {
        this.fileInputAlreadyExist.emit();
        return;
      }

      this._addFileToInput(file.name);
      this.fileUploaded.emit(file);
    }
  }

  downloadFile(fileName: string): void {
    this.fileDownloadPressed.emit(fileName);
  }

  deleteFile(fileName: string): void {
    this._removeFileFromInput(fileName);
    this.fileDeletePressed.emit(fileName);
  }

  isFileNamesListNotEmpty(): boolean {
    return isNotNullNorUndefined(this.fileNamesList) && this.fileNamesList.length !== 0;
  }

  sliderOnClick(control: FormControl): void {
    if (!control.value) {
      this.settings.control.setValue(this.settings.min);
    }
  }

  private _updateIsImportFileButtonVisible(): void {
    this.isImportFileButtonVisible =
      (this.settings.acceptMultipleFiles && this.fileNamesList.length < this.maxNumberOfFiles) || this.fileNamesList.length === 0;
  }

  private _addFileToInput(fileName: string): void {
    this.fileNamesList = [...this.fileNamesList, fileName];
    this.settings.control.setValue(this._parseMultipleFilesListToString(this.fileNamesList));
    this._updateIsImportFileButtonVisible();
  }

  private _removeFileFromInput(fileName: string): void {
    this.fileNamesList = this.fileNamesList.filter(fileNameInList => fileNameInList !== fileName);
    this.settings.control.setValue(this._parseMultipleFilesListToString(this.fileNamesList));
    this._updateIsImportFileButtonVisible();
  }

  private _removeLastFileFromInput(): void {
    this.fileNamesList.pop();
  }

  private _clearFileInput(): void {
    this.fileInput.nativeElement.value = null;
  }

  private _parseMultipleFilesValueToList(value: string): string[] {
    const matchArray: RegExpMatchArray = value
      .replace(/(\[|\])/gm, '') // Remove every brackets
      // TODO: Watch out if files names contains brackets
      .match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/gm); // Match every values seperated by a comma

    if (!isNotNullNorUndefined(matchArray)) {
      return [];
    }

    return matchArray.map(v => (isNotNullNorUndefined(v) ? v.replace(/^"(.*)"$/, '$1') : null)); // Remove quotes if they are the first and last characters of the string)
  }

  private _parseMultipleFilesListToString(fileNames: string[]): string {
    if (isNullOrUndefined(fileNames) || fileNames.length === 0) {
      return null;
    }

    let result = '[';
    fileNames.forEach(fileName => {
      result += `"${fileName}"`;
      result += fileNames.length - 1 > fileNames.indexOf(fileName) ? ', ' : '';
    });
    result += ']';

    return result;
  }
}
