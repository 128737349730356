import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CreateCompany, CreateFidusCompany, GetAllCompanies, GetCompany } from '@app/store/actions';
import { AppState } from '@app/store/app.states';
import {
  selectAllCompanies,
  selectCompanyByBceNumber,
  selectCompanyById,
  selectCurrentUserAcceptedCompanies,
  selectCurrentUserCompanies,
  selectCurrentUserPendingCompanies
} from '@app/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Company } from '../model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private store: Store<AppState>, private router: Router) {}

  loadAllCompanies(): void {
    this.store.dispatch(new GetAllCompanies());
  }

  loadCompany(companyId: number): void {
    this.store.dispatch(new GetCompany(companyId));
  }

  getAllCompanies(): Observable<Company[]> {
    return this.store.select(selectAllCompanies);
  }

  getCompanyById(companyId: number): Observable<Company> {
    return this.store.select(selectCompanyById(), { companyId });
  }

  getCompanyByBceNumber(bceNumber: string): Observable<Company> {
    return this.store.select(selectCompanyByBceNumber(), { bceNumber });
  }

  getCurrentUserCompanies(): Observable<Company[]> {
    return this.store.select(selectCurrentUserCompanies);
  }

  getCurrentUserPendingCompanies(): Observable<Company[]> {
    return this.store.select(selectCurrentUserPendingCompanies);
  }

  getCurrentUserAcceptedCompanies(): Observable<Company[]> {
    return this.store.select(selectCurrentUserAcceptedCompanies);
  }

  createCompany(company: any): void {
    this.store.dispatch(new CreateCompany(company));
  }

  getCompanyFromDbOrFidus(company: any): void {
    this.store.dispatch(new CreateFidusCompany(company));
  }

  navigateToNewCompany(): void {
    this.router.navigate([`/companies/new`]);
  }
}
