import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { convertToPartial, isNotNullNorUndefined } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Answer, AnswerStatus, TravelPlan, TravelPlanImportType, TravelPlanLanguage, TravelPlanStatus } from '../model';
import { Conformity, ConformityStatus, ConformityType } from '../model/conformity.model';
import { ApiUploadedFile, FileService } from '../services';

export interface ApiTravelPlan {
  id: number;
  form_id?: number;
  site_id?: number;
  company_id?: number;
  status?: ApiTravelPlanStatus;
  name?: string;
  language?: ApiTravelPlanLanguage;
  created_at?: Date;
  updated_at?: Date;
  answers?: ApiAnswer[];
  conformites?: ApiConformity[];
  type_dimport?: ApiTravelPlanImportType;
  agentbe_id?: number;
  import_trav_count?: number;
  import_trav_ratio?: number;
  is_expired?: boolean;
}

export interface ApiAnswer {
  id: number;
  question_id?: number;
  plan_id?: number;
  user_id?: number;
  value?: string;
  comment?: string;
  status?: ApiAnswerStatus;
  version?: number;
}

export enum ApiAnswerStatus {
  Completed = 'Completed',
  ModifiedByAdminBE = 'ModifiedByAdminBE',
  ToCorrect = 'ToCorrect',
  Corrected = 'Corrected'
}

export enum ApiTravelPlanStatus {
  Draft = 'Draft',
  Sent = 'Completed',
  Incomplete = 'Modified',
  Corrected = 'Corrected',
  Accepted = 'Accepted'
}

export enum ApiTravelPlanLanguage {
  French = 'FR',
  Dutch = 'NL',
  English = 'EN'
}

export interface ApiConformity {
  id: number;
  type: ApiConformityType;
  status: ApiConformityStatus;
  comment_be: string;
  description: string;
  deadline: Date;
  proof: string;
}

export enum ApiConformityType {
  Contact = 'Contact',
  PDE_Info = 'Info_PDE',
  Awareness_Actions = 'Action_sensi',
  Multimodal_Access_Map = 'Plan_Access',
  Bicycle_Parking = 'Parking vélo',
  Ecoscore = 'Ecoscore',
  PicPol_And_Emergency_Plan = 'Plan_Picpol_Urgence',
  Public_Transport = 'TC'
}

export enum ApiConformityStatus {
  Planned = 'Planned',
  Compliant = 'Oui',
  Non_Compliant = 'Non',
  Derogation_Request = 'Derogation_Request' // TODO: Check value when the backend is done
}

export enum ApiTravelPlanImportType {
  None = 'aucun',
  Excel = 'excel',
  Survey = 'survey'
}

@Injectable({
  providedIn: 'root'
})
export class ApiTravelPlanService {
  constructor(private http: HttpClient, private fileService: FileService) {}

  getAllTravelPlans(): Observable<TravelPlan[]> {
    return this.http.get<ApiTravelPlan[]>(`${environment.apiUrl}/plans`).pipe(
      map(apiTravelPlans => {
        return apiTravelPlans.map(apiTravelPlan => this.travelPlanFromApiToModel(apiTravelPlan));
      })
    );
  }

  getTravelPlan(id: number): Observable<TravelPlan> {
    return this.http.get<ApiTravelPlan>(`${environment.apiUrl}/plans/${id}`).pipe(
      map(apiTravelPlan => {
        return this.travelPlanFromApiToModel(apiTravelPlan);
      })
    );
  }

  getTravelPlanBySiteIdAndFormId(siteId: number, formId: number): Observable<TravelPlan> {
    const params = new HttpParams().set('site_id', siteId.toString()).set('form_id', formId.toString());

    return this.http.get<ApiTravelPlan>(`${environment.apiUrl}/plans`, { params }).pipe(
      map(apiTravelPlan => {
        return this.travelPlanFromApiToModel(apiTravelPlan);
      })
    );
  }

  getTravelPlansBySiteId(siteId: number): Observable<TravelPlan[]> {
    const params = new HttpParams().set('site_id', siteId.toString());

    return this.http.get<ApiTravelPlan[]>(`${environment.apiUrl}/plans`, { params }).pipe(
      map(apiTravelPlans => {
        return apiTravelPlans.map(apiTravelPlan => this.travelPlanFromApiToModel(apiTravelPlan));
      })
    );
  }

  updateTravelPlan(update: Update<TravelPlan>): Observable<Partial<TravelPlan>> {
    return this.http.patch<ApiTravelPlan>(`${environment.apiUrl}/plans/${update.id}`, this.travelPlanFromModelToApi(update.changes)).pipe(
      map(apiTravelPlan => {
        const travelPlan: TravelPlan = this.travelPlanFromApiToModel(apiTravelPlan);
        // TODO: Maybe change empty answers and conformities array to null to avoid store update problems
        const partialAnswers: Partial<Answer>[] = isNotNullNorUndefined(travelPlan.answers)
          ? travelPlan.answers.map(answer => convertToPartial(answer))
          : null;
        return convertToPartial({ ...travelPlan, answers: partialAnswers });
      })
    );
  }

  acceptTravelPlan(travelPlanId: number, file: File): Observable<any> {
    return this.fileService.convertFileToBase64(file).pipe(
      map(fileAsBase64 => {
        return convertToPartial({
          file_name: file.name,
          file: fileAsBase64
        } as ApiUploadedFile);
      }),
      switchMap(apiUploadedFile => {
        return this.http.post(`${environment.apiUrl}/plans/${travelPlanId}/accept`, apiUploadedFile);
      })
    );
  }

  deleteTravelPlan(travelPlanId: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/plans/${travelPlanId}`);
  }

  copyTravelPlan(siteId: number, formId: number, oldPlanId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/plans/copy`, { siteId, formId, oldPlanId });
  }

  getPlansToCopy(formId: number, siteId: number): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/plans/get_forms`, { siteId, formId });
  }

  addTravelPlan(apiTravelPlan: TravelPlan): Observable<TravelPlan> {
    return this.http.post<ApiTravelPlan>(`${environment.apiUrl}/plans`, this.travelPlanFromModelToApi(apiTravelPlan)).pipe(
      map(apiAddedPlan => {
        return this.travelPlanFromApiToModel(apiAddedPlan);
      })
    );
  }

  travelPlanFromApiToModel(apiTravelPlan: ApiTravelPlan): TravelPlan {
    const status = this.travelPlanStatusFromApitoModel(apiTravelPlan.status);
    const language = this.travelPlanLanguageFromApitoModel(apiTravelPlan.language);
    const importType = this.travelPlanImportTypeFromApitoModel(apiTravelPlan.type_dimport);

    return {
      id: apiTravelPlan.id,
      formId: apiTravelPlan.form_id,
      siteId: apiTravelPlan.site_id,
      companyId: apiTravelPlan.company_id,
      status,
      name: apiTravelPlan.name,
      language,
      creationDate: apiTravelPlan.created_at,
      lastUpdate: apiTravelPlan.updated_at,
      answers: isNotNullNorUndefined(apiTravelPlan.answers) ? apiTravelPlan.answers.map(answer => this.answerFromApiToModel(answer)) : [],
      conformities: isNotNullNorUndefined(apiTravelPlan.conformites)
        ? apiTravelPlan.conformites.map(conformity => this.conformityFromApiToModel(conformity))
        : [],
      importType,
      adminBEID: apiTravelPlan.agentbe_id,
      travCount: apiTravelPlan.import_trav_count,
      travRatio: apiTravelPlan.import_trav_ratio,
      isExpired: apiTravelPlan.is_expired
    };
  }

  travelPlanFromModelToApi(travelPlan: Partial<TravelPlan>): Partial<ApiTravelPlan> {
    const status = travelPlan.status ? this.travelPlanStatusFromModelToApi(travelPlan.status) : undefined;
    const language = travelPlan.language ? this.travelPlanLanguageFromModelToApi(travelPlan.language) : undefined;
    const importType = travelPlan.importType ? this.travelPlanImportTypeFromModelToApi(travelPlan.importType) : undefined;

    return convertToPartial({
      id: travelPlan.id,
      formId: travelPlan.formId,
      siteId: travelPlan.siteId,
      companyId: travelPlan.companyId,
      status,
      name: travelPlan.name,
      language,
      created_at: travelPlan.creationDate,
      updated_at: travelPlan.lastUpdate,
      answers: travelPlan.answers.map(answer => this.answerFromModelToApi(answer)),
      conformities: isNotNullNorUndefined(travelPlan.conformities)
        ? travelPlan.conformities.map(conformity => this.conformityFromModelToApi(conformity))
        : null,
      type_dimport: importType,
      agentbe_id: travelPlan.adminBEID,
      import_trav_count: travelPlan.travCount,
      import_trav_ratio: travelPlan.travRatio
    } as ApiTravelPlan);
  }

  answerFromApiToModel(apiAnswer: ApiAnswer): Answer {
    const status = this.answerStatusFromApitoModel(apiAnswer.status);

    return {
      id: apiAnswer.id,
      questionId: apiAnswer.question_id,
      planId: apiAnswer.plan_id,
      value: apiAnswer.value,
      userId: apiAnswer.user_id,
      comment: apiAnswer.comment,
      status,
      version: apiAnswer.version
    };
  }

  answerFromModelToApi(answer: Partial<Answer>): Partial<ApiAnswer> {
    const status = answer.status ? this.answerStatusFromModelToApi(answer.status) : undefined;

    return convertToPartial({
      id: answer.id,
      question_id: answer.questionId,
      user_id: answer.userId,
      value: answer.value,
      comment: answer.comment,
      status,
      version: answer.version
    } as ApiAnswer);
  }

  answerStatusFromApitoModel(apiAnswerStatus: ApiAnswerStatus): AnswerStatus {
    switch (apiAnswerStatus) {
      case ApiAnswerStatus.Completed:
        return AnswerStatus.Completed;
      case ApiAnswerStatus.ModifiedByAdminBE:
        return AnswerStatus.ModifiedByAdminBE;
      case ApiAnswerStatus.ToCorrect:
        return AnswerStatus.ToCorrect;
      case ApiAnswerStatus.Corrected:
        return AnswerStatus.Corrected;
      default:
        console.log(`apiAnswerStatus was not recognized: ${apiAnswerStatus}`);
        return null;
    }
  }

  answerStatusFromModelToApi(answerStatus: AnswerStatus): ApiAnswerStatus {
    switch (answerStatus) {
      case AnswerStatus.Completed:
        return ApiAnswerStatus.Completed;
      case AnswerStatus.ModifiedByAdminBE:
        return ApiAnswerStatus.ModifiedByAdminBE;
      case AnswerStatus.ToCorrect:
        return ApiAnswerStatus.ToCorrect;
      case AnswerStatus.Corrected:
        return ApiAnswerStatus.Corrected;
      default:
        console.log(`answerStatus was not recognized: ${answerStatus}`);
        return null;
    }
  }

  travelPlanStatusFromApitoModel(apiTravelPlanStatus: ApiTravelPlanStatus): TravelPlanStatus {
    switch (apiTravelPlanStatus) {
      case ApiTravelPlanStatus.Draft:
        return TravelPlanStatus.Draft;
      case ApiTravelPlanStatus.Sent:
        return TravelPlanStatus.Sent;
      case ApiTravelPlanStatus.Incomplete:
        return TravelPlanStatus.Incomplete;
      case ApiTravelPlanStatus.Corrected:
        return TravelPlanStatus.Corrected;
      case ApiTravelPlanStatus.Accepted:
        return TravelPlanStatus.Accepted;
      default:
        console.log(`apiTravelPlanStatus was not recognized: ${apiTravelPlanStatus}`);
        return null;
    }
  }

  travelPlanStatusFromModelToApi(travelPlanStatus: TravelPlanStatus): ApiTravelPlanStatus {
    switch (travelPlanStatus) {
      case TravelPlanStatus.Draft:
        return ApiTravelPlanStatus.Draft;
      case TravelPlanStatus.Sent:
        return ApiTravelPlanStatus.Sent;
      case TravelPlanStatus.Incomplete:
        return ApiTravelPlanStatus.Incomplete;
      case TravelPlanStatus.Corrected:
        return ApiTravelPlanStatus.Corrected;
      case TravelPlanStatus.Accepted:
        return ApiTravelPlanStatus.Accepted;
      default:
        console.log(`travelPlanStatus was not recognized: ${travelPlanStatus}`);
        return null;
    }
  }

  travelPlanLanguageFromApitoModel(apiTravelPlanLanguage: ApiTravelPlanLanguage): TravelPlanLanguage {
    switch (apiTravelPlanLanguage) {
      case ApiTravelPlanLanguage.French:
        return TravelPlanLanguage.French;
      case ApiTravelPlanLanguage.Dutch:
        return TravelPlanLanguage.Dutch;
      case ApiTravelPlanLanguage.English:
        return TravelPlanLanguage.English;
      default:
        return null;
    }
  }

  travelPlanLanguageFromModelToApi(travelPlanLanguage: TravelPlanLanguage): ApiTravelPlanLanguage {
    switch (travelPlanLanguage) {
      case TravelPlanLanguage.French:
        return ApiTravelPlanLanguage.French;
      case TravelPlanLanguage.Dutch:
        return ApiTravelPlanLanguage.Dutch;
      case TravelPlanLanguage.English:
        return ApiTravelPlanLanguage.English;
      default:
        console.log(`travelPlanLanguage was not recognized: ${travelPlanLanguage}`);
        return null;
    }
  }

  conformityFromApiToModel(apiConfirmity: ApiConformity): Conformity {
    const type = this.conformityTypeFromApitoModel(apiConfirmity.type);
    const status = this.conformityStatusFromApitoModel(apiConfirmity.status);

    return {
      id: apiConfirmity.id,
      type,
      status,
      BEComment: apiConfirmity.comment_be,
      description: apiConfirmity.description,
      deadline: apiConfirmity.deadline,
      proof: apiConfirmity.proof
    };
  }

  conformityFromModelToApi(conformity: Partial<Conformity>): Partial<ApiConformity> {
    const type = conformity.type ? this.conformityTypeFromModelToApi(conformity.type) : undefined;
    const status = conformity.status ? this.conformityStatusFromModelToApi(conformity.status) : undefined;

    return convertToPartial({
      id: conformity.id,
      type,
      status,
      comment_be: conformity.BEComment,
      description: conformity.description,
      deadline: conformity.deadline,
      proof: conformity.proof
    } as ApiConformity);
  }

  conformityTypeFromApitoModel(apiConformityType: ApiConformityType): ConformityType {
    switch (apiConformityType) {
      case ApiConformityType.Contact:
        return ConformityType.Contact;
      case ApiConformityType.PDE_Info:
        return ConformityType.PDE_Info;
      case ApiConformityType.Awareness_Actions:
        return ConformityType.Awareness_Actions;
      case ApiConformityType.Multimodal_Access_Map:
        return ConformityType.Multimodal_Access_Map;
      case ApiConformityType.Bicycle_Parking:
        return ConformityType.Bicycle_Parking;
      case ApiConformityType.Ecoscore:
        return ConformityType.Ecoscore;
      case ApiConformityType.PicPol_And_Emergency_Plan:
        return ConformityType.PicPol_And_Emergency_Plan;
      case ApiConformityType.Public_Transport:
        return ConformityType.Public_Transport;
      default:
        console.log(`apiConformityType was not recognized: ${apiConformityType}`);
        return null;
    }
  }

  conformityTypeFromModelToApi(conformityType: ConformityType): ApiConformityType {
    switch (conformityType) {
      case ConformityType.Contact:
        return ApiConformityType.Contact;
      case ConformityType.PDE_Info:
        return ApiConformityType.PDE_Info;
      case ConformityType.Awareness_Actions:
        return ApiConformityType.Awareness_Actions;
      case ConformityType.Multimodal_Access_Map:
        return ApiConformityType.Multimodal_Access_Map;
      case ConformityType.Bicycle_Parking:
        return ApiConformityType.Bicycle_Parking;
      case ConformityType.Ecoscore:
        return ApiConformityType.Ecoscore;
      case ConformityType.PicPol_And_Emergency_Plan:
        return ApiConformityType.PicPol_And_Emergency_Plan;
      case ConformityType.Public_Transport:
        return ApiConformityType.Public_Transport;
      default:
        console.log(`conformityType was not recognized: ${conformityType}`);
        return null;
    }
  }

  conformityStatusFromApitoModel(apiConformityStatus: ApiConformityStatus): ConformityStatus {
    switch (apiConformityStatus) {
      case ApiConformityStatus.Planned:
        return ConformityStatus.Planned;
      case ApiConformityStatus.Compliant:
        return ConformityStatus.Compliant;
      case ApiConformityStatus.Non_Compliant:
        return ConformityStatus.Non_Compliant;
      case ApiConformityStatus.Derogation_Request:
        return ConformityStatus.Derogation_Request;
      default:
        console.log(`apiConformityStatus was not recognized: ${apiConformityStatus}`);
        return null;
    }
  }

  conformityStatusFromModelToApi(conformityStatus: ConformityStatus): ApiConformityStatus {
    switch (conformityStatus) {
      case ConformityStatus.Planned:
        return ApiConformityStatus.Planned;
      case ConformityStatus.Compliant:
        return ApiConformityStatus.Compliant;
      case ConformityStatus.Non_Compliant:
        return ApiConformityStatus.Non_Compliant;
      case ConformityStatus.Derogation_Request:
        return ApiConformityStatus.Derogation_Request;
      default:
        console.log(`conformityStatus was not recognized: ${conformityStatus}`);
        return null;
    }
  }

  travelPlanImportTypeFromApitoModel(apiTravelPlanImportType: ApiTravelPlanImportType): TravelPlanImportType {
    switch (apiTravelPlanImportType) {
      case ApiTravelPlanImportType.None:
        return TravelPlanImportType.None;
      case ApiTravelPlanImportType.Excel:
        return TravelPlanImportType.Excel;
      case ApiTravelPlanImportType.Survey:
        return TravelPlanImportType.Survey;
      case null:
        return null;
      default:
        console.log(`apiTravelPlanImportType was not recognized: ${apiTravelPlanImportType}`);
        return null;
    }
  }

  travelPlanImportTypeFromModelToApi(travelPlanImportType: TravelPlanImportType): ApiTravelPlanImportType {
    switch (travelPlanImportType) {
      case TravelPlanImportType.None:
        return ApiTravelPlanImportType.None;
      case TravelPlanImportType.Excel:
        return ApiTravelPlanImportType.Excel;
      case TravelPlanImportType.Survey:
        return ApiTravelPlanImportType.Survey;
      case null:
        return null;
      default:
        console.log(`travelPlanImportType was not recognized: ${travelPlanImportType}`);
        return null;
    }
  }
}
