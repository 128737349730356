<h3 mat-dialog-title>
  {{ (prefilledUser ? 'i18n.RegisterDialogComponent.confirm-title' : 'i18n.RegisterDialogComponent.title') | translate }}
</h3>

<div mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
      <div *ngFor="let settings of fieldsSettings" fxFlex.gt-xs="0 0 100%">
        <app-custom-form-field [settings]="settings"></app-custom-form-field>
      </div>

      <button mat-flat-button color="primary" (click)="onRegisterButtonPressed()" [disabled]="isRegisterButtonDisabled()">
        {{ (prefilledUser ? 'i18n.RegisterDialogComponent.confirm-button' : 'i18n.RegisterDialogComponent.register') | translate }}
      </button>
    </div>
  </form>
</div>
