import { EstablishmentUnit } from '@app/core/model/establishment-unit.model';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

export enum EstablishmentUnitActionTypes {
  GET_ALL_ESTABLISHMENT_UNITS = '[EstablishmentUnit] Get All Establishment Units',
  GET_ALL_ESTABLISHMENT_UNITS_SUCCESS = '[EstablishmentUnit] Get All Establishment Units Success',
  GET_ALL_ESTABLISHMENT_UNITS_FAILURE = '[EstablishmentUnit] Get All Establishment Units Failure',
  GET_ESTABLISHMENT_UNIT = '[EstablishmentUnit] Get Establishment Unit',
  GET_ESTABLISHMENT_UNIT_SUCCESS = '[EstablishmentUnit] Get Establishment Unit Success',
  GET_ESTABLISHMENT_UNIT_FAILURE = '[EstablishmentUnit] Get Establishment Unit Failure',
  UPDATE_ESTABLISHMENT_UNIT = '[EstablishmentUnit] Update EstablishmentUnit',
  UPDATE_ESTABLISHMENT_UNIT_SUCCESS = '[EstablishmentUnit] Update EstablishmentUnit Success',
  UPDATE_ESTABLISHMENT_UNIT_FAILURE = '[EstablishmentUnit] Update EstablishmentUnit Failure',
  DELETE_ESTABLISHMENT_UNIT = '[EstablishmentUnit] DELETE EstablishmentUnit',
  DELETE_ESTABLISHMENT_UNIT_SUCCESS = '[EstablishmentUnit] DELETE EstablishmentUnit Success',
  DELETE_ESTABLISHMENT_UNIT_FAILURE = '[EstablishmentUnit] DELETE EstablishmentUnit Failure'
}

export class GetAllEstablishmentUnits implements Action {
  readonly type = EstablishmentUnitActionTypes.GET_ALL_ESTABLISHMENT_UNITS;
}

export class GetAllEstablishmentUnitsSuccess implements Action {
  readonly type = EstablishmentUnitActionTypes.GET_ALL_ESTABLISHMENT_UNITS_SUCCESS;
  constructor(public establishmentUnits: EstablishmentUnit[]) {}
}

export class GetAllEstablishmentUnitsFailure implements Action {
  readonly type = EstablishmentUnitActionTypes.GET_ALL_ESTABLISHMENT_UNITS_FAILURE;
}

export class GetEstablishmentUnit implements Action {
  readonly type = EstablishmentUnitActionTypes.GET_ESTABLISHMENT_UNIT;
  constructor(public establishmentUnitId: number) {}
}

export class GetEstablishmentUnitSuccess implements Action {
  readonly type = EstablishmentUnitActionTypes.GET_ESTABLISHMENT_UNIT_SUCCESS;
  constructor(public establishmentUnit: EstablishmentUnit) {}
}

export class GetEstablishmentUnitFailure implements Action {
  readonly type = EstablishmentUnitActionTypes.GET_ESTABLISHMENT_UNIT_FAILURE;
}

export class UpdateEstablishmentUnit implements Action {
  readonly type = EstablishmentUnitActionTypes.UPDATE_ESTABLISHMENT_UNIT;
  constructor(public update: Update<EstablishmentUnit>) {}
}

export class UpdateEstablishmentUnitSuccess implements Action {
  readonly type = EstablishmentUnitActionTypes.UPDATE_ESTABLISHMENT_UNIT_SUCCESS;
  constructor(public update: Update<EstablishmentUnit>) {}
}

export class UpdateEstablishmentUnitFailure implements Action {
  readonly type = EstablishmentUnitActionTypes.UPDATE_ESTABLISHMENT_UNIT_FAILURE;
}

export class DeleteEstablishmentUnit implements Action {
  readonly type = EstablishmentUnitActionTypes.DELETE_ESTABLISHMENT_UNIT;
  constructor(public unitID: number) {}
}

export class DeleteEstablishmentUnitSuccess implements Action {
  readonly type = EstablishmentUnitActionTypes.DELETE_ESTABLISHMENT_UNIT_SUCCESS;
  constructor(public unitID: number) {}
}

export class DeleteEstablishmentUnitFailure implements Action {
  readonly type = EstablishmentUnitActionTypes.DELETE_ESTABLISHMENT_UNIT_FAILURE;
}

export type EstablishmentUnitActions =
  | GetAllEstablishmentUnits
  | GetAllEstablishmentUnitsSuccess
  | GetAllEstablishmentUnitsFailure
  | GetEstablishmentUnit
  | GetEstablishmentUnitSuccess
  | GetEstablishmentUnitFailure
  | UpdateEstablishmentUnit
  | UpdateEstablishmentUnitSuccess
  | UpdateEstablishmentUnitFailure
  | DeleteEstablishmentUnit
  | DeleteEstablishmentUnitFailure
  | DeleteEstablishmentUnitSuccess;
