import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { convertToPartial, isNotNullNorUndefined } from '@app/shared/utils';
import { environment } from '@env/environment';
import { saveAs } from 'file-saver';
import { Observable, Observer, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { UploadedFile } from '../model';

export interface ApiUploadedFile {
  id?: number;
  plan_id: number;
  question_id: number;
  file_name: string;
  file?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http: HttpClient) {}

  uploadFile(uploadedFile: UploadedFile): Observable<any> {
    return this._uploadedFileFromModelToApi(uploadedFile).pipe(
      switchMap(apiUploadedFile => {
        return this.http.post<ApiUploadedFile>(`${environment.apiUrl}/plans/${apiUploadedFile.plan_id}/files`, apiUploadedFile);
      })
    );
  }

  downloadFile(uploadedFile: UploadedFile): Observable<any> {
    return this._uploadedFileFromModelToApi(uploadedFile).pipe(
      switchMap(apiUploadedFile => {
        return this.http
          .post(`${environment.apiUrl}/plans/${apiUploadedFile.plan_id}/files`, apiUploadedFile, { responseType: 'blob' })
          .pipe(
            tap(responseBlob => {
              saveAs(responseBlob, uploadedFile.fileName);
            })
          );
      })
    );
  }

  deleteFile(uploadedFile: UploadedFile): void {
    // TODO: Implement
    console.log('deleteFile() is not implemented yet');
  }

  private _uploadedFileFromModelToApi(uploadedFile: Partial<UploadedFile>): Observable<Partial<ApiUploadedFile>> {
    if (isNotNullNorUndefined(uploadedFile.file)) {
      return this.convertFileToBase64(uploadedFile.file).pipe(
        map(fileAsBase64 => {
          return convertToPartial({
            id: uploadedFile.id,
            plan_id: uploadedFile.planId,
            question_id: uploadedFile.questionId,
            file_name: uploadedFile.fileName,
            file: fileAsBase64
          } as ApiUploadedFile);
        })
      );
    }

    return of(
      convertToPartial({
        id: uploadedFile.id,
        plan_id: uploadedFile.planId,
        question_id: uploadedFile.questionId,
        file_name: uploadedFile.fileName
      } as ApiUploadedFile)
    );
  }

  convertFileToBase64(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const fileAsBase64 = reader.result.toString();
        observer.next(fileAsBase64);
        observer.complete();
      };
    }).pipe(first());
  }
}
