import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginCredentials, User } from '@app/core/model';
import { RegistrationData } from '@app/core/model/registration-data.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiLanguage, ApiUser, ApiUserService } from './api-user.service';

interface ApiLoginCredentials {
  email: string;
  password: string;
}

interface ApiResetData {
  email: string;
}

interface ApiRegistrationData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  profession: string;
  language: ApiLanguage;
  mobile_number: string;
  mobility_contact_communication: boolean;
  enews: boolean;
  distribution_letter: boolean;
  password: string;
}

interface ApiLoginResponse extends ApiUser {
  status: string;
  message: string;
  Authorization: string;
}

export interface LoginResponse {
  token: string;
  user: User;
}

export interface LoadCurrentUserResponse {
  token: string;
  currentUser: User;
}

@Injectable({
  providedIn: 'root'
})
export class ApiAuthService {
  constructor(private http: HttpClient, private apiUserService: ApiUserService) {}

  login(credentials: LoginCredentials): Observable<LoginResponse> {
    return this.http
      .post<ApiLoginResponse>(`${environment.apiUrl}/users/authenticate`, this.loginCredentialsFromModelToApi(credentials))
      .pipe(
        map(apiLoginResponse => {
          return this.loginResponseFromApiToModel(apiLoginResponse);
        })
      );
  }

  register(registrationData: RegistrationData): Observable<LoginResponse> {
    return this.http
      .post<ApiLoginResponse>(`${environment.apiUrl}/users/register`, this.registrationDataFromModelToApi(registrationData))
      .pipe(
        map(apiLoginResponse => {
          return this.loginResponseFromApiToModel(apiLoginResponse);
        })
      );
  }

  registerOldUser(registrationData: RegistrationData, token: string): Observable<LoginResponse> {
    return this.http
      .patch<ApiLoginResponse>(`${environment.apiUrl}/users/get_user/${token}`, this.registrationDataFromModelToApi(registrationData))
      .pipe(
        map(apiLoginResponse => {
          return this.loginResponseFromApiToModel(apiLoginResponse);
        })
      );
  }

  resetPassword(emailAddress: string): Observable<LoginResponse> {
    return this.http.post<ApiLoginResponse>(`${environment.apiUrl}/users/resetpassword`, { email: emailAddress }).pipe(
      map(apiLoginResponse => {
        return this.loginResponseFromApiToModel(apiLoginResponse);
      })
    );
  }

  setNewPassword(password: string, token: string): Observable<any> {
    return this.http.patch<any>(`${environment.apiUrl}/users/newpass/${token}`, { password, token });
  }

  deleteAccount(): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/users/disable_account`);
  }

  loginCredentialsFromModelToApi(credentials: LoginCredentials): ApiLoginCredentials {
    return { email: credentials.emailAddress, password: credentials.password };
  }

  registrationDataFromModelToApi(registrationData: RegistrationData): ApiRegistrationData {
    const language = this.apiUserService.languageFromModelToApi(registrationData.language);

    return {
      first_name: registrationData.firstName,
      last_name: registrationData.lastName,
      email: registrationData.emailAddress,
      phone_number: registrationData.phoneNumber,
      profession: registrationData.profession,
      language,
      mobile_number: registrationData.mobileNumber,
      mobility_contact_communication: registrationData.mobilityContactCommunication,
      enews: registrationData.enews,
      distribution_letter: registrationData.distributionLetter,
      password: registrationData.password
    };
  }

  resetDataForApi(userData: any): ApiResetData {
    return {
      email: userData.emailAddress
    };
  }

  loginResponseFromApiToModel(apiLoginResponse: ApiLoginResponse): LoginResponse {
    const apiUser: ApiUser = {
      id: apiLoginResponse.id,
      first_name: apiLoginResponse.first_name,
      last_name: apiLoginResponse.last_name,
      email: apiLoginResponse.email,
      phone_number: apiLoginResponse.phone_number,
      profession: apiLoginResponse.phone_number,
      language: apiLoginResponse.language,
      mobile_number: apiLoginResponse.mobile_number,
      mobility_contact_communication: apiLoginResponse.mobility_contact_communication,
      enews: apiLoginResponse.enews,
      distribution_letter: apiLoginResponse.distribution_letter,
      type_user: apiLoginResponse.type_user
    };
    const user: User = this.apiUserService.userFromApiToModel(apiUser);

    return {
      token: apiLoginResponse.Authorization,
      user
    };
  }
}
