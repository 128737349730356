<ng-container *ngIf="isLoading">
  <mat-progress-bar mode="determinate" [value]="loadingPercentage"></mat-progress-bar>
</ng-container>

<div *ngIf="hasLoadingError" class="loading-error-message" fxLayout="column" fxLayoutAlign="start center">
  <span>
    {{ 'i18n.LegalNoticesDialogComponent.loading-error' | translate }}
  </span>
</div>

<pdf-viewer
  [src]="pdfSrc"
  [render-text]="true"
  style="display: block;"
  (error)="onError($event)"
  (on-progress)="onProgress($event)"
  (after-load-complete)="onLoadingComplete($event)"
></pdf-viewer>
