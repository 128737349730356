import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission } from '@app/core/model/permission.model';

@Injectable({
  providedIn: 'root'
})
export class ApiPermissionService {
  constructor(private http: HttpClient) {}

  getAllPermission(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/permission`).pipe(
      map(apiResponse => {
        return apiResponse;
      })
    );
  }

  permissionFromApiToModel(permissions: string[]): Permission {
    return {
      permissions
    };
  }
}
