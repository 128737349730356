import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Output() legalNoticesButtonPressed = new EventEmitter();
  @Output() helpButtonPressed = new EventEmitter();
  @Output() faqButtonPressed = new EventEmitter();

  isLanguageNL: boolean;
  subSink = new SubSink();

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.subSink.sink = this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this._updateIsLanguageNL(langChangeEvent);
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onLegalNoticesButtonPressed(): void {
    this.legalNoticesButtonPressed.emit();
  }

  onHelpButtonPressed(): void {
    this.helpButtonPressed.emit();
  }

  onFaqButtonPressed(): void {
    this.faqButtonPressed.emit();
  }

  private _updateIsLanguageNL(langChangeEvent: LangChangeEvent): void {
    this.isLanguageNL = langChangeEvent.lang === 'nl';
  }
}
