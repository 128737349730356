<ng-container *ngIf="!isDataSourceEmpty(); else emptyDataSourceTemplate">
  <mat-table
    [ngClass]="{ 'expandable-custom-table': isTableExpandable() }"
    [dataSource]="currentPageDataSource"
    class="custom-table mat-elevation-z0"
    multiTemplateDataRows
    fxFill
  >
    <ng-container *ngIf="isTableExpandable()" [matColumnDef]="expandIndicatorColumnKey">
      <mat-header-cell class="expand-indicator-cell" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="expand-indicator-cell" *matCellDef="let element">
        <div fxLayout="row" fxLayoutAlign="center center">
          <button
            class="expand-button"
            *ngIf="!isExpandableDataSourceEmpty(element) && !isExpandIndicatorHidden(element)"
            mat-icon-button
            aria-label="Expand icon button"
            (click)="expandRow(element)"
            [@expandIndicatorRotate]="element.id == expandedRowId ? 'expanded' : 'collapsed'"
            disableRipple
          >
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let columnKey of getDataSourceKeys()" [matColumnDef]="columnKey">
      <mat-header-cell *matHeaderCellDef>{{ getColumnHeaderText(columnKey) | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div
          *ngIf="element[columnKey]"
          class="cell-text truncated-cell-text"
          [ngClass]="getCustomColorClassName(columnKey, element)"
          [ngStyle]="{ color: getCustomColor(columnKey, element), 'font-weight': isBold(columnKey) ? 'bold' : 'normal' }"
          matTooltip="{{ element[columnKey] + '' | translate }}"
        >
          <div
            [ngClass]="{ 'text-with-action': isColumnAction(columnKey) }"
            (click)="isColumnAction(columnKey) && triggerAction(columnKey, element)"
            fxLayout="row"
          >
            <mat-icon
              [ngClass]="getCustomColorClassName(columnKey, element)"
              *ngIf="getPrefixIconName(columnKey, element)"
              class="prefix-icon"
            >
              {{ getPrefixIconName(columnKey, element) }}
            </mat-icon>

            <span class="truncated-cell-text">{{ element[columnKey] + '' | translate }}</span>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="actionColumnKey">
      <mat-header-cell
        class="action-cell"
        [ngStyle]="{ flex: '0 0 ' + maximumNumberOfVisibleActions * 40 + 'px' }"
        *matHeaderCellDef
      ></mat-header-cell>
      <mat-cell class="action-cell" [ngStyle]="{ flex: '0 0 ' + maximumNumberOfVisibleActions * 40 + 'px' }" *matCellDef="let element">
        <div fxLayout="row" fxLayoutAlign="end center" fxFlex>
          <div
            *ngFor="let action of settings.actions"
            [matTooltip]="getActionTooltip(element, action)"
            [matTooltipDisabled]="!hasActionTooltip(element, action)"
          >
            <button
              class="action-button"
              [ngStyle]="{ color: action.color }"
              [ngClass]="{ 'action-button-with-custom-color': action.color, 'action-button-with-multiple-icons': action.iconNameWhenHover }"
              *ngIf="!isActionHidden(element, action)"
              mat-icon-button
              aria-label="Action icon button"
              (click)="triggerAction(action.name, element)"
              [disabled]="isActionDisabled(element, action)"
            >
              <mat-icon class="action-button-icon">{{ action.iconName }}</mat-icon>
              <mat-icon *ngIf="action.iconNameWhenHover" class="action-button-icon-when-hover">{{ action.iconNameWhenHover }}</mat-icon>
            </button>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Selection Column -->
    <ng-container [matColumnDef]="selectionColumnKey">
      <mat-header-cell class="selection-cell" *matHeaderCellDef fxFlex="0 0 60px">
        {{ settings.selectionColumnHeaderText | translate }}
      </mat-header-cell>
      <mat-cell class="selection-cell" *matCellDef="let row" fxFlex="0 0 60px">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
          <ng-container *ngIf="!settings.allowMultiSelect; else multiSelectTemplate">
            <mat-radio-button
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [disabled]="isRowReadOnly(row) || settings.isSelectionReadOnly"
              [aria-label]="checkboxAriaLabel(row)"
            ></mat-radio-button>
          </ng-container>
          <ng-template #multiSelectTemplate>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxAriaLabel(row)"
              [disabled]="isRowReadOnly(row) || settings.isSelectionReadOnly || (!settings.allowNoSelection && isOnlyElementSelected(row))"
            >
            </mat-checkbox>
          </ng-template>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container *ngIf="isTableExpandable()">
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element">
          <div class="element-detail" [@detailExpand]="element.id == expandedRowId ? 'expanded' : 'collapsed'" fxFlex>
            <ng-container *ngIf="!isExpandableDataSourceEmpty(element); else emptyExpandableDataSourceTemplate">
              <mat-table [dataSource]="getExpandableDataSource(element)" class="mat-elevation-z0" fxFlex>
                <ng-container *ngIf="isTableExpandable()" [matColumnDef]="expandIndicatorColumnKey">
                  <mat-header-cell class="expand-indicator-cell" *matHeaderCellDef></mat-header-cell>
                  <mat-cell class="expand-indicator-cell" *matCellDef="let innerElement"></mat-cell>
                </ng-container>

                <ng-container *ngFor="let columnKey of getDataSourceKeys()" [matColumnDef]="columnKey">
                  <!-- <mat-header-cell *matHeaderCellDef>{{ getColumnHeaderText(columnKey) | translate }}</mat-header-cell> -->
                  <mat-cell *matCellDef="let innerElement">
                    <ng-container *ngIf="isColumnDisplayedInExpandableTable(columnKey)">
                      <div
                        *ngIf="innerElement[columnKey]"
                        class="cell-text truncated-cell-text"
                        [ngClass]="getCustomColorClassName(columnKey, innerElement)"
                        [ngStyle]="{ color: getCustomColor(columnKey, innerElement), 'font-weight': isBold(columnKey) ? 'bold' : 'normal' }"
                        matTooltip="{{ innerElement[columnKey] | translate }}"
                      >
                        <div
                          [ngClass]="{ 'text-with-action': isColumnAction(columnKey) }"
                          (click)="isColumnAction(columnKey) && triggerAction(columnKey, innerElement)"
                          fxLayout="row"
                        >
                          <mat-icon
                            [ngClass]="getCustomColorClassName(columnKey, innerElement)"
                            *ngIf="getPrefixIconName(columnKey, innerElement)"
                            class="prefix-icon"
                          >
                            {{ getPrefixIconName(columnKey, innerElement) }}
                          </mat-icon>

                          <span class="truncated-cell-text">{{ innerElement[columnKey] | translate }}</span>
                        </div>
                      </div>
                    </ng-container>
                  </mat-cell>
                </ng-container>

                <ng-container [matColumnDef]="actionColumnKey">
                  <mat-header-cell
                    class="action-cell"
                    [ngStyle]="{ flex: '0 0 ' + maximumNumberOfVisibleActions * 40 + 'px' }"
                    *matHeaderCellDef
                  ></mat-header-cell>
                  <mat-cell
                    class="action-cell"
                    [ngStyle]="{ flex: '0 0 ' + maximumNumberOfVisibleActions * 40 + 'px' }"
                    *matCellDef="let innerElement"
                  >
                    <div fxLayout="row" fxLayoutAlign="end center" fxFlex>
                      <div
                        *ngFor="let action of getExpandedTableActions()"
                        [matTooltip]="getActionTooltip(element, action)"
                        [matTooltipDisabled]="!hasActionTooltip(element, action)"
                      >
                        <button
                          [ngStyle]="{ color: action.color }"
                          [ngClass]="{
                            'action-button-with-custom-color': action.color,
                            'action-button-with-multiple-icons': action.iconNameWhenHover
                          }"
                          class="action-button"
                          *ngIf="!isActionHidden(innerElement, action)"
                          mat-icon-button
                          aria-label="Action icon button"
                          (click)="triggerAction(action.name, innerElement)"
                          [disabled]="isActionDisabled(innerElement, action)"
                        >
                          <mat-icon class="action-button-icon">{{ action.iconName }}</mat-icon>
                          <mat-icon *ngIf="action.iconNameWhenHover" class="action-button-icon-when-hover">
                            {{ action.iconNameWhenHover }}
                          </mat-icon>
                        </button>
                      </div>
                    </div>
                  </mat-cell>
                </ng-container>

                <!-- Selection Column -->
                <ng-container [matColumnDef]="selectionColumnKey">
                  <mat-header-cell class="selection-cell" *matHeaderCellDef fxFlex="0 0 60px">
                    {{ settings.selectionColumnHeaderText | translate }}
                  </mat-header-cell>
                  <mat-cell class="selection-cell" *matCellDef="let row" fxFlex="0 0 60px">
                    <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
                      <ng-container *ngIf="!settings.allowMultiSelect; else innerMultiSelectTemplate">
                        <mat-radio-button
                          (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [disabled]="isRowReadOnly(row) || settings.isSelectionReadOnly"
                          [aria-label]="checkboxAriaLabel(row)"
                        ></mat-radio-button>
                      </ng-container>
                      <ng-template #innerMultiSelectTemplate>
                        <mat-checkbox
                          (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxAriaLabel(row)"
                          [disabled]="
                            isRowReadOnly(row) || settings.isSelectionReadOnly || (!settings.allowNoSelection && isOnlyElementSelected(row))
                          "
                        >
                        </mat-checkbox>
                      </ng-template>
                    </div>
                  </mat-cell>
                </ng-container>

                <!-- <mat-header-row *matHeaderRowDef="getDisplayedColumnsInExpandedPanel()"></mat-header-row> -->
                <mat-row class="inner-element-row" *matRowDef="let row; columns: getDisplayedColumns()"></mat-row>
              </mat-table>
            </ng-container>

            <ng-template #emptyExpandableDataSourceTemplate>
              <div class="empty-data-row" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex>
                <!-- TODO: Use translation -->
                <mat-icon class="empty-data-source-text" aria-hidden="false" aria-label="Empty data source icon">error_outline</mat-icon>
                <span class="empty-data-source-text">{{ getEmptyExpandableDataSourceText() | translate }}</span>
              </div>
            </ng-template>
          </div>
        </mat-cell>
      </ng-container>
    </ng-container>
    <!-- End of the Expanded Content Column -->

    <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: getDisplayedColumns()"
      class="element-row"
      [class.expanded-row]="expandedRowId === row.id"
    ></mat-row>
    <ng-container *ngIf="isTableExpandable()">
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></mat-row>
    </ng-container>
  </mat-table>

  <mat-paginator
    *ngIf="settings.dataSource.length >= minimumToDisplayPaginator"
    [length]="settings.dataSource.length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="updateCurrentPageDataSourceWithPageEvent($event)"
  >
  </mat-paginator>
</ng-container>

<ng-template #emptyDataSourceTemplate>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" fxFill>
    <!-- TODO: Use translation -->
    <mat-icon class="empty-data-source-text" aria-hidden="false" aria-label="Empty data source icon">error_outline</mat-icon>
    <span class="empty-data-source-text">{{ settings.emptyDataSourceText | translate }}</span>
  </div>
</ng-template>
