import { createSelector } from '@ngrx/store';
import { AppState } from '../app.states';
import { AuthState } from '../reducers';

export const selectAuth = (state: AppState) => state.auth;

export const selectAuthIsAuthenticated = createSelector(
  selectAuth,
  (state: AuthState) => state.isAuthenticated
);

export const selectAuthIsLoadingFinished = createSelector(
  selectAuth,
  (state: AuthState) => state.isLoadingFinished
);

export const selectAuthCurrentUserId = createSelector(
  selectAuth,
  (state: AuthState) => state.currentUserId
);

export const selectAuthCurrentUserType = createSelector(
  selectAuth,
  (state: AuthState) => state.currentUserType
);
