import { Form } from '@app/core/model';
import { Action } from '@ngrx/store';

export enum FormActionTypes {
  GET_ALL_FORMS = '[Form] Get All Forms',
  GET_ALL_FORMS_SUCCESS = '[Form] Get All Forms Success',
  GET_ALL_FORMS_FAILURE = '[Form] Get All Forms Failure',
  GET_FORM = '[Form] Get Form',
  GET_FORM_SUCCESS = '[Form] Get Form Success',
  GET_FORM_FAILURE = '[Form] Get Form Failure'
}

export class GetAllForms implements Action {
  readonly type = FormActionTypes.GET_ALL_FORMS;
}

export class GetAllFormsSuccess implements Action {
  readonly type = FormActionTypes.GET_ALL_FORMS_SUCCESS;
  constructor(public forms: Form[]) {}
}

export class GetAllFormsFailure implements Action {
  readonly type = FormActionTypes.GET_ALL_FORMS_FAILURE;
}

export class GetForm implements Action {
  readonly type = FormActionTypes.GET_FORM;
  constructor(public formId: number) {}
}

export class GetFormSuccess implements Action {
  readonly type = FormActionTypes.GET_FORM_SUCCESS;
  constructor(public form: Form) {}
}

export class GetFormFailure implements Action {
  readonly type = FormActionTypes.GET_FORM_FAILURE;
}

export type FormActions = GetAllForms | GetAllFormsSuccess | GetAllFormsFailure | GetForm | GetFormSuccess | GetFormFailure;
