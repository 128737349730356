export class Survey {
  constructor();
  constructor(id: number, nameFR: string, nameNL: string, nameEN: string, bceNumber: string, exportURL: string, sites: SurveySite[]);
  constructor(
    public id?: number,
    public nameFR?: string,
    public nameNL?: string,
    public nameEN?: string,
    public bceNumber?: string,
    public exportURL?: string,
    public sites?: SurveySite[]
  ) {}
}

export class SurveySite {
  constructor();
  constructor(id: number, nameFR: string, nameNL: string, nameEN: string, address: string, exportURL: string);
  constructor(
    public id?: number,
    public nameFR?: string,
    public nameNL?: string,
    public nameEN?: string,
    public address?: string,
    public exportURL?: string
  ) {}
}
