import { Survey } from '@app/core/model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SurveyActions, SurveyActionTypes } from '../actions';
export interface InvalidPostalCode {
  line: number;
  postcode: string;
}
export interface SurveyState extends EntityState<Survey> {
  // additional entity state properties
  survey_num_worker: { trav: number; worker: number }; // from the gismob_service.py
  survey_excel_import_error: boolean;
  survey_excel_import_error_msg: string;
  survey_excel_invalid_postcode: InvalidPostalCode[];
  excelFileName: string; // from the file upload dialog, but we saved for later use
  excelFileAsBase64: string;
  planId: number;
}

export const surveyAdapter: EntityAdapter<Survey> = createEntityAdapter<Survey>();

const initialState: SurveyState = surveyAdapter.getInitialState({
  // additional entity state properties
  survey_num_worker: { trav: 0, worker: 0 },
  survey_excel_import_error: false,
  survey_excel_import_error_msg: '',
  survey_excel_invalid_postcode: [],
  excelFileName: '',
  excelFileAsBase64: '',
  planId: 0
});

export function surveyReducer(state = initialState, action: SurveyActions): SurveyState {
  switch (action.type) {
    case SurveyActionTypes.GET_ALL_SURVEYS_SUCCESS: {
      return surveyAdapter.addAll(action.surveys, state);
    }
    case SurveyActionTypes.IMPORT_EXCEL_FILE_NOT_VALID: {
      const newstate = {
        survey_excel_invalid_postcode: action.result.validation_result[0].invalid_list,
        survey_excel_import_error_msg: action.result.validation_status
      };
      const isError = ['import-plan-error-trav-lt-04', 'import-plan-error-trav-gt-11'].includes(action.result.validation_status);
      if (isError) {
        return {
          ...state,
          ...newstate,
          survey_excel_import_error: true
        };
      } else {
        return {
          ...state,
          ...newstate
        };
      }
    }
    case SurveyActionTypes.IMPORT_CLEAR_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
