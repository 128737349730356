import { Injectable } from '@angular/core';
import { ChangeUserPassword, GetAllAdminUsers, GetAllUsers, GetUser, UpdateUser } from '@app/store/actions';
import { AppState } from '@app/store/app.states';
import { selectAllUsers, selectAllUsersFromCurrentUserCompanies, selectCurrentUser, selectUserById } from '@app/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { User } from '../model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private store: Store<AppState>) {}

  toString(user: User): string {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    } else if (user.firstName && isNullOrUndefined(user.lastName)) {
      return user.firstName;
    } else if (user.lastName && isNullOrUndefined(user.firstName)) {
      return user.lastName;
    } else {
      return null;
    }
  }

  loadAllUsers(): void {
    this.store.dispatch(new GetAllUsers());
  }

  loadAllAdminUsers(): void {
    this.store.dispatch(new GetAllAdminUsers());
  }

  loadUser(userId: number): void {
    this.store.dispatch(new GetUser(userId));
  }

  updateUser(user: User): void {
    this.store.dispatch(new UpdateUser(user));
  }

  getAllUsers(): Observable<User[]> {
    return this.store.select(selectAllUsers);
  }

  getUserById(userId: number): Observable<User> {
    return this.store.select(selectUserById(), { userId });
  }

  getCurrentUser(): Observable<User> {
    return this.store.select(selectCurrentUser);
  }

  changePassword(data: any): void {
    this.store.dispatch(new ChangeUserPassword(data));
  }

  getAllUsersFromCurrentUserCompanies(): Observable<User[]> {
    return this.store.select(selectAllUsersFromCurrentUserCompanies);
  }
}
