import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';
import { isNotNullNorUndefined } from '@app/shared/utils';
import {
  AcceptTravelPlan,
  AddTravelPlan,
  CopyTravelPlan,
  DeleteTravelPlan,
  GetAllTravelPlans,
  GetTravelPlan,
  GetTravelPlanBySiteIdAndFormId,
  GetTravelPlansBySiteId,
  UpdateTravelPlan,
  UpdateTravelPlanWorkerRatio
} from '@app/store/actions';
import { AppState } from '@app/store/app.states';
import {
  selectAllTravelPlans,
  selectAnswerByQuestionId,
  selectIsGetTravelPlansLoading,
  selectIsLoading,
  selectTravelPlanById,
  selectTravelPlanBySiteIdAndFormId,
  selectTravelPlansBySiteId
} from '@app/store/selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import {
  Answer,
  AnswerStatus,
  Form,
  Question,
  QuestionSettingsCalculation,
  QuestionSettingsCalculationOperationType,
  QuestionSettingsCondition,
  QuestionSettingsConditionOperationType,
  QuestionSettingsError,
  QuestionSettingsWarning,
  Section,
  SPECIFIC_FIELD_TYPE,
  TableQuestionCellSettings,
  TravelPlan,
  TravelPlanImportType,
  TravelPlanStatus
} from '../model';
import { saveAs } from 'file-saver';

export interface FormFieldValue {
  questionId: number;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class TravelPlanService {
  constructor(private router: Router, private store: Store<AppState>, private http: HttpClient) {}

  loadAllTravelPlans(): void {
    this.store.dispatch(new GetAllTravelPlans());
  }

  loadTravelPlan(travelPlanId: number): void {
    this.store.dispatch(new GetTravelPlan(travelPlanId));
  }

  loadTravelPlanBySiteIdAndFormId(siteId: number, formId: number): void {
    this.store.dispatch(new GetTravelPlanBySiteIdAndFormId(siteId, formId));
  }

  loadTravelPlansBySiteId(siteId: number): void {
    this.store.dispatch(new GetTravelPlansBySiteId(siteId));
  }

  addTravelPlan(travelPlan: TravelPlan): void {
    this.store.dispatch(new AddTravelPlan(travelPlan));
  }

  patchTravelPlan(travelPlanId: number, changes: Partial<TravelPlan>): void {
    this.store.dispatch(new UpdateTravelPlan({ id: travelPlanId, changes }));
  }

  acceptTravelPlan(travelPlanId: number, file: File): void {
    this.store.dispatch(new AcceptTravelPlan(travelPlanId, file));
  }

  deleteTravelPlan(travelPlanId: number): void {
    this.store.dispatch(new DeleteTravelPlan(travelPlanId));
  }

  copySite(siteId: number, formId: number, oldPlanId: number): void {
    this.store.dispatch(new CopyTravelPlan(oldPlanId, formId, siteId));
  }

  isLoading(): Observable<boolean> {
    return this.store.select(selectIsLoading);
  }

  isGetTravelPlansLoading(): Observable<boolean> {
    return this.store.select(selectIsGetTravelPlansLoading);
  }

  getAllTravelPlans(): Observable<TravelPlan[]> {
    return this.store.select(selectAllTravelPlans).pipe(
      filter(travelPlans => isNotNullNorUndefined(travelPlans)),
      distinctUntilChanged()
    );
  }

  getTravelPlanById(travelPlanId: number): Observable<TravelPlan> {
    return this.store.select(selectTravelPlanById(), { travelPlanId }).pipe(
      filter(travelPlan => isNotNullNorUndefined(travelPlan)),
      distinctUntilChanged()
    );
  }

  getTravelPlanBySiteIdAndFormId(siteId: number, formId: number): Observable<TravelPlan> {
    return this.store.select(selectTravelPlanBySiteIdAndFormId(), { siteId, formId }).pipe(
      filter(travelPlan => isNotNullNorUndefined(travelPlan)),
      distinctUntilChanged()
    );
  }

  getTravelPlansBySiteId(siteId: number): Observable<TravelPlan[]> {
    return this.store.select(selectTravelPlansBySiteId(), { siteId }).pipe(
      filter(travelPlans => isNotNullNorUndefined(travelPlans)),
      distinctUntilChanged()
    );
  }

  getTravelPlanStatus(siteId: number, formId: number): Observable<TravelPlanStatus> {
    return this.store.select(selectTravelPlanBySiteIdAndFormId(), { siteId, formId }).pipe(
      map(travelPlan => (isNotNullNorUndefined(travelPlan) ? travelPlan.status : TravelPlanStatus.NotCreated)),
      distinctUntilChanged()
    );
  }

  getAnswerByQuestionId(travelPlanId: number, questionId: number): Observable<Answer> {
    return this.store.select(selectAnswerByQuestionId(), { travelPlanId, questionId }).pipe(
      filter(answer => isNotNullNorUndefined(answer)),
      distinctUntilChanged()
    );
  }

  exportTravelPlanPDF(travelPlanId: number, language: 'FR' | 'NL'): Observable<any> {
    return this.http.post(`${environment.apiUrl}/report/${travelPlanId}/plan/${language}`, null, { responseType: 'blob' }).pipe(
      tap(responseBlob => {
        saveAs(responseBlob, `PDE-report-plan-${travelPlanId}.pdf`);
      })
    );
  }

  resetTravelPlanImport(travelPlan: TravelPlan): void {
    let questionsIdsToReset: number[] = [];

    switch (travelPlan.importType) {
      case TravelPlanImportType.Excel:
        questionsIdsToReset = [21];
        break;
      case TravelPlanImportType.Survey:
        questionsIdsToReset = [19, 21, 23, 29, 30];
        break;
    }

    const answerToReset: Partial<Answer>[] = [];
    questionsIdsToReset.forEach(questionId => {
      let value = '';
      const answer: Answer = travelPlan.answers.find(a => a.questionId === questionId);
      const version = isNotNullNorUndefined(answer) && isNotNullNorUndefined(answer.version) ? answer.version : 0;
      if (questionId === 21 && travelPlan.importType === TravelPlanImportType.Excel) {
        if (isNotNullNorUndefined(answer) && isNotNullNorUndefined(answer.value)) {
          value = answer.value.replace(/\[([^\[\]]*), ([^\[\]]*), ([^\[\]]*), ([^\[\]]*), ([^\[\]]*)\]/g, '[$1, null, $3, $4, $5]');
        }
      }

      if (isNotNullNorUndefined(answer) && (questionId === 21 || (version === 1 && questionId !== 21))) {
        answerToReset.push({ questionId, value });
      }
    });
    this.patchTravelPlan(travelPlan.id, {
      importType: TravelPlanImportType.None,
      travRatio: 0,
      travCount: 0,
      answers: answerToReset
    });
  }

  isAnswerValid(question: Question, answer: Answer, form: Form, travelPlan: TravelPlan): boolean {
    // The question is valid if the travel plan is accepted
    if (isNotNullNorUndefined(travelPlan) && travelPlan.status === TravelPlanStatus.Accepted) {
      return true;
    }

    // The question is not valid if the status is ToCorrect
    if (isNotNullNorUndefined(answer.status) && answer.status === AnswerStatus.ToCorrect) {
      return false;
    }

    if (question.id !== answer.questionId) {
      throw new Error(`QuestionIds are not matching (${question.id} !== ${answer.questionId})`);
    }

    // TODO: Check if answer value is right type according to question type

    // The question is not valid if it's required and doesn't have a value
    if (question.required && (isNullOrUndefined(answer) || isNullOrUndefined(answer.value) || answer.value.length === 0)) {
      return false;
    }

    // Check cells in table and array questions
    if (question.fieldType === SPECIFIC_FIELD_TYPE.Table) {
      const questionException = [85, 88, 95];
      if (isNotNullNorUndefined(question.settings.columns) || isNotNullNorUndefined(question.settings.lines)) {
        let columnList = question.settings.columns;
        // Need to clone question so we can modifiy column list for questionException.
        // With this getTableQuestionCellValue will only get the correct value and not returning null
        // using spread operator doesn't work since the object is nested
        const questionCopy = JSON.parse(JSON.stringify(question));
        if (questionException.includes(question.id)) {
          columnList = columnList.filter(name => name === 'objective');
          questionCopy.settings.columns = columnList;
        }
        if (
          questionCopy.settings.columns.some(columnName => {
            return questionCopy.settings.lines.some(lineName => {
              if (!this.isTableQuestionCellVisible(questionCopy, columnName, lineName, form, travelPlan, [])) {
                return false;
              }

              if (
                isNotNullNorUndefined(questionCopy.settings.optionalColumns) &&
                questionCopy.settings.optionalColumns.includes(columnName)
              ) {
                return false;
              }

              const cellValue: string | number | boolean = this.getTableQuestionCellValue(questionCopy, columnName, lineName, answer.value);
              return isNullOrUndefined(cellValue) || cellValue.toString().trim().length === 0;
            });
          })
        ) {
          return false;
        }
      }
    }

    if (question.fieldType === SPECIFIC_FIELD_TYPE.Array) {
      const valuesArray: string[] = this._getValuesArrayFromString(answer.value);

      if (isNotNullNorUndefined(question.settings.columns)) {
        if (
          question.settings.columns.some((columnName, index, self) => {
            if (isNotNullNorUndefined(question.settings.optionalColumns) && question.settings.optionalColumns.includes(columnName)) {
              return false;
            }

            const columnValues: string[] = valuesArray.filter((value, valueIndex) => valueIndex % self.length === index);
            return columnValues.some(value => isNullOrUndefined(value) || value.length === 0);
          })
        ) {
          return false;
        }
      }
    }

    // The question is not valid if there's at least one custom error
    if (
      isNotNullNorUndefined(question.settings) &&
      isNotNullNorUndefined(question.settings.errorIf) &&
      question.settings.errorIf.length > 0 &&
      question.settings.errorIf.some(questionError => this.isErrorTrue(questionError, form, travelPlan, []))
    ) {
      return false;
    }

    return true;
  }

  getCompletionPercentage(section: Section, form: Form, travelPlan: TravelPlan): number {
    if (isNullOrUndefined(section) || isNullOrUndefined(travelPlan)) {
      return 0;
    }

    if (travelPlan.status === TravelPlanStatus.Accepted) {
      return 100;
    }

    const allVisibleQuestions: Question[] = this.getFlattenedQuestions(section).filter(question =>
      this.isQuestionVisible(question, form, travelPlan, [])
    );
    const totalNumberOfVisibleQuestions: number = allVisibleQuestions.length;

    const allRequiredVisibleQuestions: Question[] = allVisibleQuestions.filter(question => question.required);
    const totalNumberOfRequiredVisibleQuestions: number = allRequiredVisibleQuestions.length;

    if (totalNumberOfVisibleQuestions === 0) {
      return 100; // TODO: Maybe change default value
    }

    if (totalNumberOfRequiredVisibleQuestions === 0) {
      const numberOfVisibleQuestionsWithValidAnswer: number = allVisibleQuestions
        .map(question => {
          const answer = travelPlan.answers.find(a => a.questionId === question.id);
          return isNotNullNorUndefined(answer) ? this.isAnswerValid(question, answer, form, travelPlan) : false;
        })
        .filter(isAnswerValid => isAnswerValid).length;

      return Math.round((numberOfVisibleQuestionsWithValidAnswer / totalNumberOfVisibleQuestions) * 100);
    }

    const numberOfRequiredVisibleQuestionsWithValidAnswer: number = allRequiredVisibleQuestions
      .map(question => {
        const answer = travelPlan.answers.find(a => a.questionId === question.id);
        return isNotNullNorUndefined(answer) ? this.isAnswerValid(question, answer, form, travelPlan) : false;
      })
      .filter(isAnswerValid => isAnswerValid).length;

    const allOptionalVisibleQuestionsToCorrect: Question[] = allVisibleQuestions
      .filter(question => !question.required)
      .filter(optionalQuestion => {
        const answer = travelPlan.answers.find(a => a.questionId === optionalQuestion.id);
        return isNotNullNorUndefined(answer) ? answer.status === AnswerStatus.ToCorrect : false;
      });

    const totalNumberOfOptionalVisibleQuestionsToCorrect: number = allOptionalVisibleQuestionsToCorrect.length;

    return Math.round(
      (numberOfRequiredVisibleQuestionsWithValidAnswer /
        (totalNumberOfRequiredVisibleQuestions + totalNumberOfOptionalVisibleQuestionsToCorrect)) *
        100
    );
  }

  isSectionValid(section: Section, form: Form, travelPlan: TravelPlan): boolean {
    if (isNullOrUndefined(section) || isNullOrUndefined(form) || isNullOrUndefined(travelPlan)) {
      return false;
    }

    const allVisibleQuestions: Question[] = this.getFlattenedQuestions(section).filter(question =>
      this.isQuestionVisible(question, form, travelPlan, [])
    );

    return allVisibleQuestions.every(question => {
      const answer = travelPlan.answers.find(a => a.questionId === question.id);
      return isNotNullNorUndefined(answer) ? this.isAnswerValid(question, answer, form, travelPlan) : false;
    });
  }

  isFormValid(form: Form, travelPlan: TravelPlan): boolean {
    if (!isNotNullNorUndefined(form)) {
      return false;
    }

    return form.sections.every(section => this.isSectionValid(section, form, travelPlan));
  }

  getFlattenedQuestions(section: Section): Question[] {
    if (!isNotNullNorUndefined(section)) {
      return [];
    }

    if (!isNotNullNorUndefined(section.subsections)) {
      return section.questions;
    }

    const concatReducer = (accumulator: Question[], currentValue: Question[]) => [...accumulator, ...currentValue];

    return [
      ...section.questions,
      ...section.subsections.map(subsection => this.getFlattenedQuestions(subsection)).reduce(concatReducer, [])
    ];
  }

  getFlattenedQuestionsByForm(form: Form): Question[] {
    if (isNotNullNorUndefined(form) && isNotNullNorUndefined(form.sections)) {
      const sectionQuestions: Question[][] = form.sections.map(section => this.getFlattenedQuestions(section));

      return [].concat(...sectionQuestions);
    }

    return [];
  }

  // TODO: Replace by an async method in the future
  getQuestionByName(form: Form, questionName: string): Question {
    const question: Question = this.getFlattenedQuestionsByForm(form).find(q => q.nameTranslationKey === questionName);
    return question;
  }

  // TODO: Make this method async
  isQuestionVisible(question: Question, form: Form, travelPlan: TravelPlan, currentSectionFieldsValues: FormFieldValue[]): boolean {
    if (
      isNotNullNorUndefined(question.settings) &&
      isNotNullNorUndefined(question.settings.visibleIf) &&
      question.settings.visibleIf.length > 0
    ) {
      const conditions: QuestionSettingsCondition[] = question.settings.visibleIf;

      if (this._hasMandatoryConditions(conditions)) {
        return this._everyMandatoryConditionsMet(conditions, form, travelPlan, currentSectionFieldsValues);
      }

      if (this._hasNotMandatoryConditions(conditions)) {
        return this._someNotMandatoryConditionsMet(conditions, form, travelPlan, currentSectionFieldsValues);
      }
    }

    return true;
  }

  isTableQuestionCellVisible(
    question: Question,
    columnName: string,
    lineName: string,
    form: Form,
    travelPlan: TravelPlan,
    currentSectionFieldsValues: FormFieldValue[]
  ): boolean {
    if (
      isNotNullNorUndefined(question.settings) &&
      isNotNullNorUndefined(question.settings.cellsSettings) &&
      isNotNullNorUndefined(question.settings.cellsSettings.length > 0)
    ) {
      const cellSettings: TableQuestionCellSettings = question.settings.cellsSettings.find(
        cs => cs.column === columnName && cs.line === lineName
      );

      if (
        isNotNullNorUndefined(cellSettings) &&
        isNotNullNorUndefined(cellSettings.visibleIf) &&
        isNotNullNorUndefined(cellSettings.visibleIf.length > 0)
      ) {
        const conditions: QuestionSettingsCondition[] = cellSettings.visibleIf;

        if (this._hasMandatoryConditions(conditions)) {
          return this._everyMandatoryConditionsMet(conditions, form, travelPlan, currentSectionFieldsValues);
        }

        if (this._hasNotMandatoryConditions(conditions)) {
          return this._someNotMandatoryConditionsMet(conditions, form, travelPlan, currentSectionFieldsValues);
        }
      }
    }

    return true;
  }

  isSectionVisible(section: Section, form: Form, travelPlan: TravelPlan, currentSectionFieldsValues: FormFieldValue[]): boolean {
    const questions: Question[] = this.getFlattenedQuestions(section);
    return isNotNullNorUndefined(questions)
      ? questions.some(question => this.isQuestionVisible(question, form, travelPlan, currentSectionFieldsValues))
      : false;
  }

  getColumnType(tableQuestion: Question, columnIndex: number): CUSTOM_FORM_FIELD_TYPE {
    if (
      isNullOrUndefined(tableQuestion) ||
      isNullOrUndefined(tableQuestion.settings) ||
      isNullOrUndefined(tableQuestion.settings.columnsTypes) ||
      tableQuestion.settings.columnsTypes.length <= columnIndex
    ) {
      console.log(`Type for column ${columnIndex} is set to default type (text)`);
      return CUSTOM_FORM_FIELD_TYPE.TextInput;
    }

    return tableQuestion.settings.columnsTypes[columnIndex];
  }

  getColumnTypeByName(tableQuestion: Question, columnName: string): CUSTOM_FORM_FIELD_TYPE {
    if (
      isNullOrUndefined(tableQuestion) ||
      isNullOrUndefined(tableQuestion.settings) ||
      isNullOrUndefined(tableQuestion.settings.columns)
    ) {
      console.log(`Type for column ${columnName} is set to default type (text)`);
      return CUSTOM_FORM_FIELD_TYPE.TextInput;
    }

    const columnIndex: number = tableQuestion.settings.columns.indexOf(columnName);
    return this.getColumnType(tableQuestion, columnIndex);
  }

  getTableQuestionCellValue(
    tableQuestion: Question,
    columnName: string,
    lineName: string,
    tableQuestionValue: string
  ): string | number | boolean {
    if (
      isNullOrUndefined(tableQuestionValue) ||
      isNullOrUndefined(tableQuestion.settings) ||
      isNullOrUndefined(tableQuestion.settings.columns) ||
      isNullOrUndefined(tableQuestion.settings.lines)
    ) {
      return null;
    }

    const columnIndex: number = tableQuestion.settings.columns.indexOf(columnName);
    const lineIndex: number = tableQuestion.settings.lines.indexOf(lineName);

    if (columnIndex === -1 || lineIndex === -1) {
      return null;
    }

    const valuesArray: string[] = this._getValuesArrayFromString(tableQuestionValue);
    const cellIndex = lineIndex * tableQuestion.settings.columns.length + columnIndex;

    if (valuesArray.length <= cellIndex) {
      return null;
    }

    const valueAsString: string = valuesArray[cellIndex];
    const columnType: CUSTOM_FORM_FIELD_TYPE = this.getColumnType(tableQuestion, columnIndex);

    return this._convertValueToType(valueAsString, columnType);
  }

  getTableQuestionColumnTotal(tableQuestion: Question, columnName: string, tableQuestionValue: string): number {
    if (
      isNullOrUndefined(tableQuestionValue) ||
      isNullOrUndefined(tableQuestion.settings) ||
      isNullOrUndefined(tableQuestion.settings.columns)
    ) {
      return null;
    }

    const columnIndex: number = tableQuestion.settings.columns.indexOf(columnName);

    if (columnIndex === -1) {
      return null;
    }

    const columnStringValues: string[] = this._getValuesArrayFromString(tableQuestionValue).filter(
      (value, index, array) => index % tableQuestion.settings.columns.length === columnIndex
    );

    const columnNumberValues: number[] = columnStringValues
      .filter(value => isNotNullNorUndefined(value))
      .map(value => Number.parseFloat(value))
      .map(valueAsFloat => (Number.isNaN(valueAsFloat) ? 0 : valueAsFloat));

    const addFunction: (a: number, b: number) => number = (a, b) => a + b;

    let columnTotal: number = columnNumberValues.reduce(addFunction, 0);
    columnTotal = Math.round(columnTotal * 1e12) / 1e12; // Solve precision problem
    return columnTotal;
  }

  hasCalculatedValue(question: Question): boolean {
    return isNotNullNorUndefined(question.settings) && isNotNullNorUndefined(question.settings.calculatedValue);
  }

  getCalculatedValue(question: Question, form: Form, travelPlan: TravelPlan, currentSectionFieldsValues: FormFieldValue[]): number {
    if (!this.hasCalculatedValue(question)) {
      return null;
    }

    return this._processCalculation(question.settings.calculatedValue, form, travelPlan, currentSectionFieldsValues);
  }

  isErrorTrue(
    questionError: QuestionSettingsError,
    form: Form,
    travelPlan: TravelPlan,
    currentSectionFieldsValues: FormFieldValue[]
  ): boolean {
    if (isNullOrUndefined(questionError.conditions)) {
      return false;
    }

    if (this._hasMandatoryConditions(questionError.conditions)) {
      return this._everyMandatoryConditionsMet(questionError.conditions, form, travelPlan, currentSectionFieldsValues);
    }

    if (this._hasNotMandatoryConditions(questionError.conditions)) {
      return this._someNotMandatoryConditionsMet(questionError.conditions, form, travelPlan, currentSectionFieldsValues);
    }
  }

  isWarningTrue(
    questionWarning: QuestionSettingsWarning,
    form: Form,
    travelPlan: TravelPlan,
    currentSectionFieldsValues: FormFieldValue[]
  ): boolean {
    if (isNullOrUndefined(questionWarning.conditions)) {
      return false;
    }

    if (this._hasMandatoryConditions(questionWarning.conditions)) {
      return this._everyMandatoryConditionsMet(questionWarning.conditions, form, travelPlan, currentSectionFieldsValues);
    }

    if (this._hasNotMandatoryConditions(questionWarning.conditions)) {
      return this._someNotMandatoryConditionsMet(questionWarning.conditions, form, travelPlan, currentSectionFieldsValues);
    }
  }

  getQuestionValueByQuestionName(
    questionName: string,
    form: Form,
    travelPlan: TravelPlan,
    currentSectionFieldsValues: FormFieldValue[]
  ): string | number | boolean {
    const question: Question = this.getQuestionByName(form, questionName);

    if (isNullOrUndefined(question)) {
      return null;
    }

    let questionValueAsString: string;
    const questionFieldValue: FormFieldValue = isNotNullNorUndefined(currentSectionFieldsValues)
      ? currentSectionFieldsValues.find(fieldValue => fieldValue.questionId === question.id)
      : null;

    if (isNotNullNorUndefined(questionFieldValue)) {
      questionValueAsString = questionFieldValue.value;
    } else {
      const answer: Answer = isNotNullNorUndefined(travelPlan) ? travelPlan.answers.find(a => a.questionId === question.id) : null;
      questionValueAsString = isNotNullNorUndefined(answer) ? answer.value : null;
    }

    return this._convertValueToType(questionValueAsString, question.fieldType);
  }

  private _processCalculation(
    questionSettingsCalculation: QuestionSettingsCalculation,
    form: Form,
    travelPlan: TravelPlan,
    currentSectionFieldsValues: FormFieldValue[]
  ): number {
    let questionsValues: number[] = [];

    if (isNotNullNorUndefined(questionSettingsCalculation.questionsNames)) {
      questionsValues = questionSettingsCalculation.questionsNames.map(questionName => {
        const value: string | number | boolean = this.getQuestionValueByQuestionName(
          questionName,
          form,
          travelPlan,
          currentSectionFieldsValues
        );
        return Number.isNaN(Number(value)) ? 0 : Number(value); // TODO: Maybe change default value
      });
    }

    if (isNotNullNorUndefined(questionSettingsCalculation.questionCells)) {
      const questionCellsValues: number[] = questionSettingsCalculation.questionCells.map(tableQuestionCell => {
        const tableQuestion: Question = this.getQuestionByName(form, tableQuestionCell.questionName);

        if (isNullOrUndefined(tableQuestion)) {
          return 0;
        }

        const tableQuestionValue: string | number | boolean = this.getQuestionValueByQuestionName(
          tableQuestionCell.questionName,
          form,
          travelPlan,
          currentSectionFieldsValues
        );

        if (isNullOrUndefined(tableQuestionValue)) {
          return 0;
        }

        let value: string | number | boolean;

        if (isNullOrUndefined(tableQuestionCell.line)) {
          value = this.getTableQuestionColumnTotal(tableQuestion, tableQuestionCell.column, tableQuestionValue.toString());
        } else {
          value = this.getTableQuestionCellValue(
            tableQuestion,
            tableQuestionCell.column,
            tableQuestionCell.line,
            tableQuestionValue.toString()
          );
        }

        return Number.isNaN(Number(value)) ? 0 : Number(value); // TODO: Maybe change default value
      });

      questionsValues = [...questionsValues, ...questionCellsValues];
    }

    let sum: number;
    switch (questionSettingsCalculation.operationType) {
      case QuestionSettingsCalculationOperationType.SUM:
        return questionsValues.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      case QuestionSettingsCalculationOperationType.SUM_PLUS_MARGIN:
        sum = questionsValues.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        const margin = 1.2;
        return sum * margin;
      case QuestionSettingsCalculationOperationType.SUM_DIVIDED_BY_SPECIFIC_NUMBER:
        sum = questionsValues.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        return sum / questionSettingsCalculation.operationSpecificNumber;
      case QuestionSettingsCalculationOperationType.SUM_MULTIPLED_BY_SPECIFIC_NUMBER:
        sum = questionsValues.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        return sum * questionSettingsCalculation.operationSpecificNumber;
      default:
        // TODO: Maybe change default value
        return null;
    }
  }

  private _convertValueToType(valueToConvert: string, type: CUSTOM_FORM_FIELD_TYPE | SPECIFIC_FIELD_TYPE): string | number | boolean {
    if (isNullOrUndefined(valueToConvert)) {
      if (type === CUSTOM_FORM_FIELD_TYPE.Checkbox) {
        return false;
      }
      return null;
    }

    switch (type) {
      case CUSTOM_FORM_FIELD_TYPE.DateInput:
        return new Date(valueToConvert).toISOString();
      case CUSTOM_FORM_FIELD_TYPE.Integer:
      case CUSTOM_FORM_FIELD_TYPE.Double:
      case CUSTOM_FORM_FIELD_TYPE.Percentage:
      case CUSTOM_FORM_FIELD_TYPE.Slider:
        const valueAsFloat: number = Number.parseFloat(valueToConvert);
        return Number.isNaN(valueAsFloat) ? valueToConvert : valueAsFloat;
      case CUSTOM_FORM_FIELD_TYPE.Checkbox:
        const valueAsBoolean: boolean = valueToConvert === ('true' || '1');
        return valueAsBoolean;
      default:
        return valueToConvert;
    }
  }

  private _getValuesArrayFromString(tableQuestionValue: string): string[] {
    const matchArray: RegExpMatchArray = tableQuestionValue
      .replace(/(\[|\])/gm, '') // Remove every brackets
      .match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g); // Match every values seperated by a comma

    if (!isNotNullNorUndefined(matchArray)) {
      return [];
    }

    return matchArray
      .map(v => (v === 'null' ? null : v)) // Parse "null" strings into null values
      .map(v => (isNotNullNorUndefined(v) ? v.replace(/^"(.*)"$/, '$1') : null)); // Remove quotes if they are the first and last characters of the string)
  }

  private _isConditionMet(
    condition: QuestionSettingsCondition,
    form: Form,
    travelPlan: TravelPlan,
    currentSectionFieldsValues: FormFieldValue[]
  ): boolean {
    const conditionQuestion: Question = this.getQuestionByName(form, condition.questionName);

    if (isNullOrUndefined(conditionQuestion)) {
      return false;
    }

    let actualQuestionValueAsString: string;
    const questionFieldValue: FormFieldValue = isNotNullNorUndefined(currentSectionFieldsValues)
      ? currentSectionFieldsValues.find(fieldValue => fieldValue.questionId === conditionQuestion.id)
      : null;

    if (isNotNullNorUndefined(questionFieldValue)) {
      actualQuestionValueAsString = questionFieldValue.value;
    } else {
      const answer: Answer = isNotNullNorUndefined(travelPlan) ? travelPlan.answers.find(a => a.questionId === conditionQuestion.id) : null;
      actualQuestionValueAsString = isNotNullNorUndefined(answer) ? answer.value : null;
    }

    if (condition.operationType === QuestionSettingsConditionOperationType.IS_NULL) {
      return isNullOrUndefined(actualQuestionValueAsString) || actualQuestionValueAsString.trim().length === 0;
    } else if (condition.operationType === QuestionSettingsConditionOperationType.IS_NOT_NULL) {
      return isNotNullNorUndefined(actualQuestionValueAsString) && actualQuestionValueAsString.trim().length !== 0;
    }

    let expectedValueAsString: string;

    if (isNotNullNorUndefined(condition.expectedValue)) {
      expectedValueAsString = condition.expectedValue;
    } else if (isNotNullNorUndefined(condition.expectedValueQuestion)) {
      const expectedValueQuestion: Question = this.getQuestionByName(form, condition.expectedValueQuestion);

      const expectedValueQuestionFieldValue: FormFieldValue = isNotNullNorUndefined(currentSectionFieldsValues)
        ? currentSectionFieldsValues.find(fieldValue => fieldValue.questionId === expectedValueQuestion.id)
        : null;

      if (isNotNullNorUndefined(expectedValueQuestionFieldValue)) {
        expectedValueAsString = expectedValueQuestionFieldValue.value;
      } else {
        const answer: Answer = isNotNullNorUndefined(travelPlan)
          ? travelPlan.answers.find(a => a.questionId === expectedValueQuestion.id)
          : null;
        expectedValueAsString = isNotNullNorUndefined(answer) ? answer.value : null;
      }
    } else if (isNotNullNorUndefined(condition.expectedCalculatedValue)) {
      const calculationResult: number = this._processCalculation(
        condition.expectedCalculatedValue,
        form,
        travelPlan,
        currentSectionFieldsValues
      );
      expectedValueAsString = isNotNullNorUndefined(calculationResult) ? calculationResult.toString(10) : null;
    }

    if (isNullOrUndefined(expectedValueAsString)) {
      return false;
    }

    let actualValue: string | number | boolean;
    let expectedValue: string | number | boolean;

    switch (conditionQuestion.fieldType) {
      case SPECIFIC_FIELD_TYPE.Table:
        const conditionQuestionCopy = JSON.parse(JSON.stringify(conditionQuestion));
        const questionException = [85, 88, 95];
        if (questionException.includes(conditionQuestionCopy.id)) {
          const valuesArray: string[] =
            isNotNullNorUndefined(actualQuestionValueAsString) && actualQuestionValueAsString !== ''
              ? JSON.parse(actualQuestionValueAsString)
              : [];
          if (isNotNullNorUndefined(valuesArray) && valuesArray.length > 0 && valuesArray[0].length === 1) {
            conditionQuestionCopy.settings.columns = conditionQuestion.settings.columns.filter(name => name === 'objective');
          }
        }
        if (isNotNullNorUndefined(condition.line)) {
          // Get cell value if condition is on table cell
          actualValue = this.getTableQuestionCellValue(
            conditionQuestionCopy,
            condition.column,
            condition.line,
            actualQuestionValueAsString
          );
        } else {
          // Get total column value if condition is on table column
          actualValue = this.getTableQuestionColumnTotal(conditionQuestionCopy, condition.column, actualQuestionValueAsString);
        }

        expectedValue = this._convertValueToType(expectedValueAsString, this.getColumnTypeByName(conditionQuestionCopy, condition.column));
        break;
      case SPECIFIC_FIELD_TYPE.Array:
        // TODO: Maybe handle Array questions
        break;
      default:
        actualValue = this._convertValueToType(actualQuestionValueAsString, conditionQuestion.fieldType);
        expectedValue = this._convertValueToType(expectedValueAsString, conditionQuestion.fieldType);
        break;
    }

    if (isNullOrUndefined(actualValue) || (actualValue.toString().trim().length === 0 && expectedValue.toString().trim().length !== 0)) {
      return false;
    }

    switch (condition.operationType) {
      case QuestionSettingsConditionOperationType.EQUALS_TO:
        if (conditionQuestion.fieldType === CUSTOM_FORM_FIELD_TYPE.MultiCheckbox) {
          const checkedOptions: string[] = this._parseStringToCheckedOptionsList(actualValue.toString());

          return isNotNullNorUndefined(checkedOptions) ? checkedOptions.includes(expectedValue.toString()) : false;
        }

        return actualValue === expectedValue;
      case QuestionSettingsConditionOperationType.NOT_EQUALS_TO:
        if (conditionQuestion.fieldType === CUSTOM_FORM_FIELD_TYPE.MultiCheckbox) {
          const checkedOptions: string[] = this._parseStringToCheckedOptionsList(actualValue.toString());

          return isNotNullNorUndefined(checkedOptions) ? !checkedOptions.includes(expectedValue.toString()) : true;
        }

        return actualValue !== expectedValue;
      case QuestionSettingsConditionOperationType.GREATER_THAN:
        return actualValue > expectedValue;
      case QuestionSettingsConditionOperationType.LOWER_THAN:
        return actualValue < expectedValue;
      case QuestionSettingsConditionOperationType.GREATER_THAN_OR_EQUALS_TO:
        return actualValue >= expectedValue;
      case QuestionSettingsConditionOperationType.LOWER_THAN_OR_EQUALS_TO:
        return actualValue <= expectedValue;
      default:
        return false; // TODO: Maybe change default value
    }
  }

  private _hasMandatoryConditions(conditions: QuestionSettingsCondition[]): boolean {
    const mandatoryConditions: QuestionSettingsCondition[] = conditions.filter(condition => condition.mandatory !== false);
    return isNotNullNorUndefined(mandatoryConditions) && mandatoryConditions.length > 0;
  }

  private _everyMandatoryConditionsMet(
    conditions: QuestionSettingsCondition[],
    form: Form,
    travelPlan: TravelPlan,
    currentSectionFieldsValues: FormFieldValue[]
  ): boolean {
    const mandatoryConditions: QuestionSettingsCondition[] = conditions.filter(condition => condition.mandatory !== false);
    return mandatoryConditions.every(condition => this._isConditionMet(condition, form, travelPlan, currentSectionFieldsValues));
  }

  private _hasNotMandatoryConditions(conditions: QuestionSettingsCondition[]): boolean {
    const notMandatoryConditions: QuestionSettingsCondition[] = conditions.filter(condition => condition.mandatory === false);
    return isNotNullNorUndefined(notMandatoryConditions) && notMandatoryConditions.length > 0;
  }

  private _someNotMandatoryConditionsMet(
    conditions: QuestionSettingsCondition[],
    form: Form,
    travelPlan: TravelPlan,
    currentSectionFieldsValues: FormFieldValue[]
  ): boolean {
    const notMandatoryConditions: QuestionSettingsCondition[] = conditions.filter(condition => condition.mandatory === false);
    return notMandatoryConditions.some(condition => this._isConditionMet(condition, form, travelPlan, currentSectionFieldsValues));
  }

  private _parseStringToCheckedOptionsList(value: string): string[] {
    const matchArray: RegExpMatchArray = value
      .replace(/^\[(.*)\]$/gm, '$1') // Remove first and last brackets
      .match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/gm); // Match every values seperated by a comma

    if (!isNotNullNorUndefined(matchArray)) {
      return [];
    }

    return matchArray.map(v => (isNotNullNorUndefined(v) ? v.replace(/^"(.*)"$/, '$1') : null)); // Remove quotes if they are the first and last characters of the string)
  }
}
