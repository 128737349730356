import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomFormFieldSettings, CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';

@Component({
  selector: 'app-forgotten-dialog',
  templateUrl: './forgotten-dialog.component.html',
  styleUrls: ['./forgotten-dialog.component.scss']
})
export class ForgottenDialogComponent implements OnInit {
  @Output() forgottenButtonPressed = new EventEmitter<{ emailAddress: string }>();

  form: FormGroup;
  fieldsSettings: CustomFormFieldSettings[];

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      emailAddress: new FormControl('', [Validators.required, Validators.email])
    });

    this.fieldsSettings = [
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.EmailInput,
        placeholder: '',
        label: 'i18n.User.emailAddress',
        required: true,
        hint: '',
        control: this.form.get('emailAddress') as FormControl
      }
    ];
  }

  onForgottenButtonPressed(): void {
    this.forgottenButtonPressed.emit({
      emailAddress: this.form.get('emailAddress').value
    });
  }
}
