import { Survey } from '@app/core/model';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { surveyAdapter, SurveyState } from '../reducers';

export const selectSurveyState = createFeatureSelector<SurveyState>('surveys');

const { selectIds, selectEntities, selectAll, selectTotal } = surveyAdapter.getSelectors();

export const selectSurveyIds = createSelector(
  selectSurveyState,
  selectIds
);

export const selectSurveyEntities = createSelector(
  selectSurveyState,
  selectEntities
);

export const selectAllSurveys = createSelector(
  selectSurveyState,
  selectAll
);

export const selectSurveyTotal = createSelector(
  selectSurveyState,
  selectTotal
);

export const selectSurveyById = () =>
  createSelector(
    selectSurveyEntities,
    (entities: Dictionary<Survey>, props: { surveyId: number }) => entities[props.surveyId]
  );

export const selectSurveyNumWorker = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.survey_num_worker
);

export const selectSurveyExcelImportError = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.survey_excel_import_error
);

export const selectSurveyExcelImportErrorMsg = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.survey_excel_import_error_msg
);

export const selectSurveyExcelImportInvalidPostcode = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.survey_excel_invalid_postcode
);

export const selectExcelImportPlanId = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.planId
);

export const selectExcelImportFileName = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.excelFileName
);

export const selectExcelImportBinary = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.excelFileAsBase64
);
