import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Company } from '@app/core/model';
import { AddressService } from '@app/core/services/address.service';
import { CustomFormFieldSettings, CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';
import {
  CustomTableActionEvent,
  CustomTableDataType,
  CustomTableSettings
} from '@app/shared/components/custom-table/custom-table.component';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';

interface ApplicationTableDataType extends CustomTableDataType {
  companyId: number;
  bceNumber: string;
  companyName: string;
  address: string;
}

@Component({
  selector: 'app-affiliation-application-dialog',
  templateUrl: './affiliation-application-dialog.component.html',
  styleUrls: ['./affiliation-application-dialog.component.scss']
})
export class AffiliationApplicationDialogComponent implements OnInit, OnChanges {
  @Input() companiesApplied: Company[];
  @Input() isApplicationLoading = false;
  @Output() cancelButtonPressed = new EventEmitter<boolean>();
  @Output() sendButtonPressed = new EventEmitter<boolean>();
  @Output() affiliationApplicationAdded = new EventEmitter<string>();
  @Output() affiliationApplicationRemoved = new EventEmitter<number>();

  bceNumberControl = new FormControl('', Validators.pattern(/^[01](\.?\d){9}$/m));
  bceNumberFieldSettings: CustomFormFieldSettings;
  applicationsTableSettings: CustomTableSettings;

  constructor(private addressService: AddressService, private translateService: TranslateService) {}

  ngOnInit() {
    this.bceNumberFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.TextInput,
      placeholder: 'i18n.AffiliationApplicationDialogComponent.bceNumber',
      required: false,
      hint: '',
      control: this.bceNumberControl,
      removeHintPadding: true
    };

    this.initApplicationTableSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDataSource();
  }

  initApplicationTableSettings(): void {
    this.applicationsTableSettings = {
      ...new CustomTableSettings(),
      emptyDataSourceText: 'i18n.AffiliationApplicationDialogComponent.empty-applications-table',
      displayedColumns: ['bceNumber', 'companyName', 'address'],
      columnSettings: [
        { key: 'bceNumber', headerText: 'i18n.Company.bceNumber' },
        { key: 'companyName', headerText: 'i18n.Company.nameFR' },
        { key: 'address', headerText: 'i18n.Company.address' }
      ],
      actions: [{ name: 'clear', iconName: 'clear', isHidden: () => false, isDisabled: () => false }]
    };

    this.updateDataSource();
  }

  updateDataSource(): void {
    const dataSource = this.companiesApplied.map(company => {
      const address = this.addressService.toString(company.address);

      return {
        id: company.id,
        companyId: company.id,
        bceNumber: company.bceNumber,
        companyName: this._isLanguageNL() ? company.nameNL : company.nameFR,
        address: isNotNullNorUndefined(address) ? address : 'i18n.ActiveSitesComponent.unknown-address'
      } as ApplicationTableDataType;
    });

    this.applicationsTableSettings = {
      ...this.applicationsTableSettings,
      dataSource
    };
  }

  isAddButtonDisabled(): boolean {
    return !this.bceNumberControl.value || this.bceNumberControl.invalid;
  }

  onAddButtonPressed(): void {
    this.affiliationApplicationAdded.emit(this.bceNumberControl.value);
    this.bceNumberControl.setValue('');
  }

  onActionTriggered(actionEvent: CustomTableActionEvent): void {
    switch (actionEvent.actionName) {
      case 'clear':
        const companyId = (actionEvent.row as ApplicationTableDataType).companyId;
        this.affiliationApplicationRemoved.emit(companyId);
        break;

      default:
        break;
    }
  }

  onCancelButtonPressed(): void {
    this.cancelButtonPressed.emit(true);
  }

  onSendButtonPressed(): void {
    this.sendButtonPressed.emit(true);
  }

  isSendButtonDisabled(): boolean {
    return this.companiesApplied.length === 0;
  }

  private _isLanguageNL(): boolean {
    return this.translateService.currentLang === 'nl';
  }
}
