import { Answer } from './answer.model';
import { Conformity } from './conformity.model';

export class TravelPlan {
  constructor();
  constructor(
    id: number,
    formId: number,
    siteId: number,
    companyId: number,
    travCount: number,
    travRatio: number,
    status: TravelPlanStatus,
    name: string,
    language: TravelPlanLanguage,
    creationDate: Date,
    lastUpdate: Date,
    answers: Answer[],
    conformities: Conformity[],
    importType: TravelPlanImportType,
    isExpired: boolean,
    adminBEID?: number
  );
  constructor(
    public id?: number,
    public formId?: number,
    public siteId?: number,
    public companyId?: number,
    public travCount?: number,
    public travRatio?: number,
    public status?: TravelPlanStatus,
    public name?: string,
    public language?: TravelPlanLanguage,
    public creationDate?: Date,
    public lastUpdate?: Date,
    public answers?: Answer[],
    public conformities?: Conformity[],
    public importType?: TravelPlanImportType,
    public isExpired?: boolean,
    public adminBEID?: number
  ) {}
}

export enum TravelPlanStatus {
  NotCreated = 'Not Created',
  Draft = 'Draft',
  Sent = 'Sent',
  Incomplete = 'Incomplete',
  Corrected = 'Corrected',
  Accepted = 'Accepted',
  Expired = 'Expired'
}

export enum TravelPlanLanguage {
  French = 'French',
  Dutch = 'Dutch',
  English = 'English'
}

export enum TravelPlanImportType {
  None = 'None',
  Excel = 'Excel',
  Survey = 'Survey'
}
