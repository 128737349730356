import { Injectable } from '@angular/core';
import { ApiTravelPlanService } from '@app/core/api/api-travel-plan.service';
import { TravelPlanStatus } from '@app/core/model';
import { SnackbarService } from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, takeUntil } from 'rxjs/operators';
import {
  AcceptTravelPlan,
  AcceptTravelPlanSuccess,
  AddTravelPlan,
  AddTravelPlanFailure,
  AddTravelPlanSuccess,
  AuthActionTypes,
  CopyTravelPlan,
  CopyTravelPlanFailure,
  DeleteTravelPlan,
  DeleteTravelPlanFailure,
  DeleteTravelPlanSuccess,
  GetAllTravelPlans,
  GetAllTravelPlansFailure,
  GetAllTravelPlansSuccess,
  GetTravelPlan,
  GetTravelPlanBySiteIdAndFormId,
  GetTravelPlanFailure,
  GetTravelPlansBySiteId,
  GetTravelPlansBySiteIdFailure,
  GetTravelPlansBySiteIdSuccess,
  GetTravelPlanSuccess,
  TravelPlanActionTypes,
  UpdateTravelPlan,
  UpdateTravelPlanFailure,
  UpdateTravelPlanSuccess
} from '../actions';
@Injectable()
export class TravelPlanEffects {
  constructor(private actions: Actions, private apiTravelPlanService: ApiTravelPlanService, private snackbarService: SnackbarService) {}

  @Effect()
  getAllTravelPlans$: Observable<any> = this.actions.pipe(
    ofType<GetAllTravelPlans>(TravelPlanActionTypes.GET_ALL_TRAVEL_PLANS),
    concatMap(() => {
      return this.apiTravelPlanService.getAllTravelPlans().pipe(
        map(travelPlans => {
          return new GetAllTravelPlansSuccess(travelPlans);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetAllTravelPlansFailure());
        }),
        takeUntil(this.actions.pipe(ofType(AuthActionTypes.LOGOUT)))
      );
    })
  );

  @Effect()
  getTravelPlan$: Observable<any> = this.actions.pipe(
    ofType<GetTravelPlan>(TravelPlanActionTypes.GET_TRAVEL_PLAN),
    map(action => action.travelPlanId),
    concatMap(id => {
      return this.apiTravelPlanService.getTravelPlan(id).pipe(
        map(travelPlan => {
          return new GetTravelPlanSuccess(travelPlan);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetTravelPlanFailure());
        })
      );
    })
  );

  @Effect()
  getTravelPlanBySiteIdAndFormId$: Observable<any> = this.actions.pipe(
    ofType<GetTravelPlanBySiteIdAndFormId>(TravelPlanActionTypes.GET_TRAVEL_PLAN_BY_SITE_ID_AND_FORM_ID),
    concatMap(action => {
      return this.apiTravelPlanService.getTravelPlanBySiteIdAndFormId(action.siteId, action.formId).pipe(
        map(travelPlan => {
          return new GetTravelPlanSuccess(travelPlan);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetTravelPlanFailure());
        })
      );
    })
  );

  @Effect()
  getTravelPlansBySiteId$: Observable<any> = this.actions.pipe(
    ofType<GetTravelPlansBySiteId>(TravelPlanActionTypes.GET_TRAVEL_PLANS_BY_SITE_ID),
    concatMap(action => {
      return this.apiTravelPlanService.getTravelPlansBySiteId(action.siteId).pipe(
        map(travelPlans => {
          return new GetTravelPlansBySiteIdSuccess(travelPlans);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetTravelPlansBySiteIdFailure());
        })
      );
    })
  );

  @Effect()
  updateTravelPlan$: Observable<any> = this.actions.pipe(
    ofType<UpdateTravelPlan>(TravelPlanActionTypes.UPDATE_TRAVEL_PLAN),
    concatMap(action => {
      return this.apiTravelPlanService.updateTravelPlan(action.update).pipe(
        map(changes => {
          if (action.update.changes.status === TravelPlanStatus.Sent) {
            this.snackbarService.openWithTranslation('i18n.TravelPlanEffects.send-travel-plan-success');
          } else {
            this.snackbarService.openWithTranslation('i18n.TravelPlanEffects.update-travel-plan-success');
          }

          return new UpdateTravelPlanSuccess({ id: action.update.id.toString(), changes });
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.TravelPlanEffects.update-travel-plan-failure');
          return of(new UpdateTravelPlanFailure());
        })
      );
    })
  );

  @Effect()
  acceptTravelPlan$: Observable<any> = this.actions.pipe(
    ofType<AcceptTravelPlan>(TravelPlanActionTypes.ACCEPT_TRAVEL_PLAN),
    concatMap(action => {
      return this.apiTravelPlanService.acceptTravelPlan(action.travelPlanId, action.file).pipe(
        map(changes => {
          this.snackbarService.openWithTranslation('i18n.TravelPlanEffects.accept-travel-plan-success');
          return new AcceptTravelPlanSuccess(action.travelPlanId);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.TravelPlanEffects.accept-travel-plan-failure');
          return of(new UpdateTravelPlanFailure());
        })
      );
    })
  );

  @Effect()
  addTravelPlan$: Observable<any> = this.actions.pipe(
    ofType<AddTravelPlan>(TravelPlanActionTypes.ADD_TRAVEL_PLAN),
    concatMap(action => {
      return this.apiTravelPlanService.addTravelPlan(action.travelPlan).pipe(
        map(travelPlan => {
          this.snackbarService.openWithTranslation('i18n.TravelPlanEffects.add-travel-plan-success');
          return new AddTravelPlanSuccess(travelPlan);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.TravelPlanEffects.add-travel-plan-failure');
          return of(new AddTravelPlanFailure());
        })
      );
    })
  );

  @Effect()
  deleteTravelPlan$: Observable<any> = this.actions.pipe(
    ofType<DeleteTravelPlan>(TravelPlanActionTypes.DELETE_TRAVEL_PLAN),
    concatMap(action => {
      return this.apiTravelPlanService.deleteTravelPlan(action.id).pipe(
        map(_ => {
          this.snackbarService.openWithTranslation('i18n.TravelPlanEffects.delete-travel-plan-success');
          return new DeleteTravelPlanSuccess(action.id);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.TravelPlanEffects.delete-travel-plan-failure');
          return of(new DeleteTravelPlanFailure());
        })
      );
    })
  );

  @Effect()
  copyTravelPlan$: Observable<any> = this.actions.pipe(
    ofType<CopyTravelPlan>(TravelPlanActionTypes.COPY_TRAVEL_PLAN),
    concatMap(action => {
      return this.apiTravelPlanService.copyTravelPlan(action.siteId, action.formId, action.oldPlanId).pipe(
        map(_ => {
          this.snackbarService.openWithTranslation('i18n.TravelPlanEffects.copy-travel-plan-success');
          return new GetTravelPlanBySiteIdAndFormId(action.siteId, action.formId);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.TravelPlanEffects.copy-travel-plan-failure');
          return of(new CopyTravelPlanFailure());
        })
      );
    })
  );
}
