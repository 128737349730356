import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginCredentials } from '@app/core/model';
import { AuthService } from '@app/core/services';
import { LoginDialogComponent } from '../../components';
import { RegisterDialogContainerComponent } from '../register-dialog-container/register-dialog.container';
import { ForgottenDialogContainerComponent } from '../forgotten-dialog-container/forgotten-dialog.container';

@Component({
  selector: 'app-login-dialog-container',
  templateUrl: './login-dialog.container.html',
  styleUrls: ['./login-dialog.container.scss']
})
export class LoginDialogContainerComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<LoginDialogComponent>, private dialogService: MatDialog, private authService: AuthService) {}

  ngOnInit() {}

  login(credentials: LoginCredentials): void {
    this.authService.login(credentials);
  }

  openRegisterDialog(): void {
    this.dialogRef.close();

    const registerDialogRef: MatDialogRef<RegisterDialogContainerComponent> = this.dialogService.open(RegisterDialogContainerComponent, {
      width: '90%',
      maxWidth: '640px',
      minWidth: '320px'
    });
  }

  openForgottenDialog(): void {
    this.dialogRef.close();

    const forgottenDialogRef: MatDialogRef<ForgottenDialogContainerComponent> = this.dialogService.open(ForgottenDialogContainerComponent, {
      width: '90%',
      maxWidth: '640px',
      minWidth: '320px'
    });
  }
}
