import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiSiteService } from '@app/core/api/api-site.service';
import { SnackbarService } from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthActionTypes, GetAllEstablishmentUnits } from '../actions';
import {
  AddSite,
  AddSiteFailure,
  AddSiteSuccess,
  CopySite,
  CopySiteFailure,
  CopySiteSuccess,
  DeleteSite,
  DeleteSiteFailure,
  DeleteSiteSuccess,
  GetAllSites,
  GetAllSitesFailure,
  GetAllSitesSuccess,
  GetSite,
  GetSiteFailure,
  GetSiteSuccess,
  SiteActionTypes,
  UpdateSite,
  UpdateSiteFailure,
  UpdateSiteSuccess
} from '../actions/site.actions';

@Injectable()
export class SiteEffects {
  constructor(private actions: Actions, private apiSiteService: ApiSiteService, private snackbarService: SnackbarService) {}

  @Effect()
  getAllSites$: Observable<any> = this.actions.pipe(
    ofType<GetAllSites>(SiteActionTypes.GET_ALL_SITES),
    concatMap(() => {
      return this.apiSiteService.getAllSites().pipe(
        map(sites => {
          return new GetAllSitesSuccess(sites);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetAllSitesFailure());
        }),
        takeUntil(this.actions.pipe(ofType(AuthActionTypes.LOGOUT)))
      );
    })
  );

  @Effect()
  getSite$: Observable<any> = this.actions.pipe(
    ofType<GetSite>(SiteActionTypes.GET_SITE),
    map(action => action.siteId),
    concatMap(id => {
      return this.apiSiteService.getSite(id).pipe(
        map(site => {
          return new GetSiteSuccess(site);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetSiteFailure());
        })
      );
    })
  );

  @Effect()
  addSite$: Observable<any> = this.actions.pipe(
    ofType<AddSite>(SiteActionTypes.ADD_SITE),
    concatMap(action => {
      return this.apiSiteService.addSite(action.site, action.establishmentUnits).pipe(
        switchMap(site => {
          this.snackbarService.openWithTranslation('i18n.SiteEffects.add-site-success');
          return [new AddSiteSuccess(site), new GetAllEstablishmentUnits()];
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.SiteEffects.add-site-failure');
          return of(new AddSiteFailure());
        })
      );
    })
  );

  @Effect()
  updateSite$: Observable<any> = this.actions.pipe(
    ofType<UpdateSite>(SiteActionTypes.UPDATE_SITE),
    concatMap(action => {
      return this.apiSiteService.updateSite(action.update, action.establishmentUnits).pipe(
        switchMap(_ => {
          this.snackbarService.openWithTranslation('i18n.SiteEffects.update-site-success');
          return [new UpdateSiteSuccess(action.update), new GetAllEstablishmentUnits()];
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          if (error.status === 400) {
            this.snackbarService.openErrorWithTranslation('i18n.SiteEffects.update-site-failure-incompatible-postal-codes');
          } else {
            this.snackbarService.openErrorWithTranslation('i18n.SiteEffects.update-site-failure');
          }
          return of(new UpdateSiteFailure());
        })
      );
    })
  );

  @Effect()
  deleteSite$: Observable<any> = this.actions.pipe(
    ofType<DeleteSite>(SiteActionTypes.DELETE_SITE),
    concatMap(action => {
      return this.apiSiteService.deleteSite(action.siteId).pipe(
        map(_ => {
          this.snackbarService.openWithTranslation('i18n.SiteEffects.delete-site-success');
          return new DeleteSiteSuccess(action.siteId);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.SiteEffects.delete-site-failure');
          return of(new DeleteSiteFailure());
        })
      );
    })
  );

  @Effect()
  copySite$: Observable<any> = this.actions.pipe(
    ofType<CopySite>(SiteActionTypes.COPY_SITE),
    concatMap(action => {
      return this.apiSiteService.copySite(action.siteId).pipe(
        map(_ => {
          this.snackbarService.openWithTranslation('i18n.SiteEffects.copy-site-success');
          return new CopySiteSuccess();
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.SiteEffects.copy-site-failure');
          return of(new CopySiteFailure());
        })
      );
    })
  );
}
