import { Role, RoleStatus } from '@app/core/model/role.model';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { roleAdapter, RoleState } from '../reducers';
import { selectAuthCurrentUserId } from './auth.selectors';

export const selectRoleState = createFeatureSelector<RoleState>('roles');

const { selectIds, selectEntities, selectAll, selectTotal } = roleAdapter.getSelectors();

export const selectRoleIds = createSelector(
  selectRoleState,
  selectIds
);

export const selectRoleEntities = createSelector(
  selectRoleState,
  selectEntities
);

export const selectAllRoles = createSelector(
  selectRoleState,
  selectAll
);

export const selectRoleTotal = createSelector(
  selectRoleState,
  selectTotal
);

export const selectRoleById = () =>
  createSelector(
    selectRoleEntities,
    (entities: Dictionary<Role>, props: { roleId: number }) => entities[props.roleId]
  );

export const selectRolesByUserId = () =>
  createSelector(
    selectAllRoles,
    (roles: Role[], props: { userId: number }) => roles.filter(role => role.userId === props.userId)
  );

export const selectRolesByCompanyId = () =>
  createSelector(
    selectAllRoles,
    (roles: Role[], props: { companyId: number }) => roles.filter(role => role.companyId === props.companyId)
  );

export const selectRoleByUserIdAndCompanyId = () =>
  createSelector(
    selectAllRoles,
    (roles: Role[], props: { userId: number; companyId: number }) =>
      roles.find(role => role.companyId === props.companyId && role.userId === props.userId)
  );

export const selectCurrentUserRoles = createSelector(
  selectAllRoles,
  selectAuthCurrentUserId,
  (roles: Role[], currentUserId) => roles.filter(role => role.userId === currentUserId)
);

export const selectAllRolesFromCurrentUserCompanies = createSelector(
  selectAllRoles,
  selectCurrentUserRoles,
  (roles: Role[], currentUserRoles) =>
    roles.filter(role => currentUserRoles.find(currentUserRole => currentUserRole.companyId === role.companyId))
);

export const selectCurrentUserCompaniesId = createSelector(
  selectCurrentUserRoles,
  (currentUserRoles: Role[]) =>
    currentUserRoles.map(role => {
      return role.companyId;
    })
);

export const selectPendingRolesFromCompaniesWhereCurrentUserIsAdmin = createSelector(
  selectCurrentUserRoles,
  selectAllRolesFromCurrentUserCompanies,
  selectAuthCurrentUserId,
  (currentUserRoles: Role[], allRolesFromCurrentUserCompanies: Role[], currentUserId: number) => {
    const companyIdsWhereCurrentUserIsAdmin: number[] = currentUserRoles
      .filter(role => role.status === RoleStatus.Admin)
      .map(role => role.companyId);

    const allRolesFromCompaniesWhereCurrentUserIsAdmin = allRolesFromCurrentUserCompanies
      .filter(role => role.userId !== currentUserId)
      .filter(role => companyIdsWhereCurrentUserIsAdmin.includes(role.companyId));

    return allRolesFromCompaniesWhereCurrentUserIsAdmin.filter(role => role.status === RoleStatus.Pending);
  }
);
