import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DeleteAccount,
  LoadCurrentUser,
  Login,
  Logout,
  Register,
  RegisterOldUser,
  ResetPassword,
  SetNewPassword
} from '@app/store/actions';
import { AppState } from '@app/store/app.states';
import {
  selectAuthCurrentUserId,
  selectAuthCurrentUserType,
  selectAuthIsAuthenticated,
  selectAuthIsLoadingFinished
} from '@app/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoginCredentials, RegistrationData, UserType } from '../model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private store: Store<AppState>, private router: Router) {}

  login(credentials: LoginCredentials): void {
    this.store.dispatch(new Login(credentials));
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  loadCurrentUser(): void {
    this.store.dispatch(new LoadCurrentUser());
  }

  register(registrationData: RegistrationData): void {
    this.store.dispatch(new Register(registrationData));
  }

  registerOldUser(registrationData: RegistrationData, token: string): void {
    this.store.dispatch(new RegisterOldUser(registrationData, token));
  }

  forgottenPassword(emailAddress: string): void {
    this.store.dispatch(new ResetPassword(emailAddress));
  }

  setNewPassword(password: string, token: string): void {
    this.store.dispatch(new SetNewPassword(password, token));
  }

  deleteAccount(): void {
    this.store.dispatch(new DeleteAccount());
  }

  isAuthenticated(): Observable<boolean> {
    return this.store.select(selectAuthIsAuthenticated);
  }

  isLoadingFinished(): Observable<boolean> {
    return this.store.select(selectAuthIsLoadingFinished);
  }

  getCurrentUserId(): Observable<number> {
    return this.store.select(selectAuthCurrentUserId);
  }

  getCurrentUserType(): Observable<UserType> {
    return this.store.select(selectAuthCurrentUserType);
  }

  navigateToMyAccount(): void {
    this.router.navigate(['/account']);
  }
}
