import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@app/core/model';
import { convertToPartial } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiAddress, ApiAddressService } from './api-address.service';

export interface ApiCompany {
  id?: number;
  BCE_Number?: string;
  nameFR?: string;
  nameNL?: string;
  address?: ApiAddress;
}

@Injectable({
  providedIn: 'root'
})
export class ApiCompanyService {
  constructor(private http: HttpClient, private apiAddressService: ApiAddressService) {}

  getAllCompanies(): Observable<Company[]> {
    return this.http.get<ApiCompany[]>(`${environment.apiUrl}/companies`).pipe(
      map(apiCompanies => {
        return apiCompanies.map(apiCompany => this.companyFromApiToModel(apiCompany));
      })
    );
  }

  getCompany(id: number): Observable<Company> {
    return this.http.get<ApiCompany>(`${environment.apiUrl}/companies/${id}`).pipe(
      map(apiCompany => {
        return this.companyFromApiToModel(apiCompany);
      })
    );
  }

  companyFromApiToModel(apiCompany: ApiCompany): Company {
    const address = apiCompany.address ? this.apiAddressService.addressFromApiToModel(apiCompany.address) : undefined;

    return {
      id: apiCompany.id,
      bceNumber: apiCompany.BCE_Number,
      nameFR: apiCompany.nameFR,
      nameNL: apiCompany.nameNL,
      address
    };
  }

  companyFromModelToApi(company: Partial<Company>): Partial<ApiCompany> {
    const address = company.address ? this.apiAddressService.addressFromModelToApi(company.address) : undefined;

    return convertToPartial({
      id: company.id,
      BCE_Number: company.bceNumber,
      nameFR: company.nameFR,
      nameNL: company.nameNL,
      address
    });
  }

  createCompany(company: any) {
    return this.http.post<ApiCompany>(`${environment.apiUrl}/companies`, company).pipe(
      map(apiCreateCompany => {
        return convertToPartial({
          bceNumber: apiCreateCompany.BCE_Number
        });
      })
    );
  }

  createFidusCompany(company: any) {
    return this.http.post<any>(`${environment.apiUrl}/companies`, company).pipe(
      map(apiCreateCompany => {
        return this.companyFromApiToModel(apiCreateCompany.company);
      })
    );
  }
}
