<div class="layout" fxLayout="column" fxFill>
  <app-header
    [currentLanguage]="currentLanguage"
    [currentUser]="currentUser$ | async"
    (changeLanguage)="changeLanguage($event)"
    (loginButtonPressed)="openLoginDialog()"
    (homeButtonPressed)="navigateToHome()"
    (myFormsButtonPressed)="navigateToSites()"
    (mySurveysButtonPressed)="navigateToGismob()"
    (myAccountButtonPressed)="navigateToMyAccount()"
  ></app-header>

  <div class="content" fxFlex>
    <router-outlet></router-outlet>
  </div>

  <app-footer
    (legalNoticesButtonPressed)="openLegalNoticesDialog()"
    (helpButtonPressed)="openHelpContactDetailsDialog()"
    (faqButtonPressed)="navigateToFaq()"
  ></app-footer>
</div>
