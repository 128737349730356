<div class="content-container" *ngIf="currentUser$ | async; else loading">
  <div fxLayout="column" fxLayoutGap="25px">
    <app-affiliation-pending-message
      *ngIf="isPendingRolesFromCompaniesWhereCurrentUserIsAdmin$ | async"
      [numberOfPendingApplications]="numberOfPendingRolesFromCompaniesWhereCurrentUserIsAdmin$ | async"
    ></app-affiliation-pending-message>

    <div fxLayout="column" fxLayoutGap="100px">
      <app-my-account-details
        [currentUser]="currentUser$ | async"
        [isDeleteAccountButtonDisabled]="isCurrentUserCompanyAdmin$ | async"
        (saved)="saveAccountDetails($event)"
        (logout)="logout()"
        (deleteAccount)="deleteAccount()"
        (changePassword)="onChangePasswordPressed()"
      ></app-my-account-details>

      <app-my-affiliations
        [currentUser]="currentUser$ | async"
        [currentUserRoles]="currentUserRoles$ | async"
        [currentUserCompanies]="currentUserCompanies$ | async"
        [allRolesFromCurrentUserCompanies]="allRolesFromCurrentUserCompanies$ | async"
        [allUsersFromCurrentUserCompanies]="allUsersFromCurrentUserCompanies$ | async"
        [pendingRolesFromCompaniesWhereCurrentUserIsAdmin]="pendingRolesFromCompaniesWhereCurrentUserIsAdmin$ | async"
        (userNameClicked)="openUserContactDetailsDialog($event)"
        (userPromoted)="promoteUser($event)"
        (newAffiliationApplicationButtonPressed)="openAffiliationApplicationDialog()"
        (affiliationRemoved)="deleteAffiliation($event)"
        (pendingApplicationAccepted)="acceptPendingApplication($event)"
        (pendingApplicationDeclined)="declinePendingApplication($event)"
      ></app-my-affiliations>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
