import { Address } from './address.model';
import { Company } from './company.model';
import { EstablishmentUnit } from './establishment-unit.model';
import { User } from './user.model';

export class Site {
  constructor();
  constructor(
    id: number,
    nameFR: string,
    nameNL: string,
    nameEN: string,
    code: string,
    status: SiteStatus,
    addresses: Address[],
    contactUserId: number
  );
  constructor(
    public id?: number,
    public nameFR?: string,
    public nameNL?: string,
    public nameEN?: string,
    public code?: string,
    public status?: SiteStatus,
    public addresses?: Address[],
    public contactUserId?: number
  ) {}
}

export enum SiteStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export class SiteSnapshot extends Site {
  constructor();
  constructor(
    id: number,
    nameFR: string,
    nameNL: string,
    nameEN: string,
    code: string,
    status: SiteStatus,
    addresses: Address[],
    contactUserId: number,
    contactUser: User,
    establishmentUnits: EstablishmentUnit[],
    companies: Company[]
  );
  constructor(
    public id?: number,
    public nameFR?: string,
    public nameNL?: string,
    public nameEN?: string,
    public code?: string,
    public status?: SiteStatus,
    public addresses?: Address[],
    public contactUserId?: number,
    public contactUser?: User,
    public establishmentUnits?: EstablishmentUnit[],
    public companies?: Company[]
  ) {
    super(id, nameFR, nameNL, nameEN, code, status, addresses, contactUserId);
  }
}
