import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Language, User, UserType } from '@app/core/model';
import { CustomFormFieldSelectOption, CustomFormFieldSettings, CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';

@Component({
  selector: 'app-my-account-details',
  templateUrl: './my-account-details.component.html',
  styleUrls: ['./my-account-details.component.scss']
})
export class MyAccountDetailsComponent implements OnInit {
  @Input() currentUser: User;
  @Input() isDeleteAccountButtonDisabled = true;
  @Output() logout = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<User>();
  @Output() deleteAccount = new EventEmitter();
  @Output() changePassword = new EventEmitter();

  form: FormGroup = new FormGroup({});

  lastNameFieldSettings: CustomFormFieldSettings;
  firstNameFieldSettings: CustomFormFieldSettings;
  emailAddressFieldSettings: CustomFormFieldSettings;
  phoneNumberFieldSettings: CustomFormFieldSettings;
  professionFieldSettings: CustomFormFieldSettings;
  mobileNumberFieldSettings: CustomFormFieldSettings;
  languageFieldSettings: CustomFormFieldSettings;
  mobilityContactCommunicationFieldSettings: CustomFormFieldSettings;
  enewsFieldSettings: CustomFormFieldSettings;
  distributionLetterFieldSettings: CustomFormFieldSettings;

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      lastName: new FormControl(this.currentUser.lastName, [Validators.required]),
      firstName: new FormControl(this.currentUser.firstName, [Validators.required]),
      emailAddress: new FormControl(this.currentUser.emailAddress, [Validators.required, Validators.email]),
      phoneNumber: new FormControl(this.currentUser.phoneNumber, [Validators.required]),
      profession: new FormControl(this.currentUser.profession),
      mobileNumber: new FormControl(this.currentUser.mobileNumber),
      language: new FormControl(this.currentUser.language, [Validators.required]),
      mobilityContactCommunication: new FormControl(this._convertBooleanToYesNo(this.currentUser.mobilityContactCommunication), [
        Validators.required
      ]),
      enews: new FormControl(this._convertBooleanToYesNo(this.currentUser.enews), [Validators.required]),
      distributionLetter: new FormControl(this._convertBooleanToYesNo(this.currentUser.distributionLetter), [Validators.required])
    });

    const languageOptions: CustomFormFieldSelectOption[] = [
      { value: Language.French, text: 'i18n.Language.French' },
      { value: Language.Dutch, text: 'i18n.Language.Dutch' }
    ];

    this.lastNameFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.TextInput,
      label: 'i18n.User.lastName',
      required: true,
      control: this.form.get('lastName') as FormControl
    };

    this.firstNameFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.TextInput,
      label: 'i18n.User.firstName',
      required: true,
      control: this.form.get('firstName') as FormControl
    };

    this.emailAddressFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.EmailInput,
      label: 'i18n.User.emailAddress',
      required: true,
      control: this.form.get('emailAddress') as FormControl
    };

    this.phoneNumberFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.TextInput,
      label: 'i18n.User.phoneNumber',
      required: true,
      control: this.form.get('phoneNumber') as FormControl
    };

    this.professionFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.TextInput,
      label: 'i18n.User.profession',
      required: false,
      control: this.form.get('profession') as FormControl
    };

    this.mobileNumberFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.TextInput,
      label: 'i18n.User.mobileNumber',
      required: false,
      control: this.form.get('mobileNumber') as FormControl
    };

    this.languageFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.Select,
      label: 'i18n.User.language',
      required: true,
      control: this.form.get('language') as FormControl,
      options: languageOptions
    };

    this.mobilityContactCommunicationFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.YesNo,
      label: 'i18n.User.mobilityContactCommunication',
      isInline: true,
      required: true,
      hasBoldLabel: false,
      control: this.form.get('mobilityContactCommunication') as FormControl
    };

    this.enewsFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.YesNo,
      label: 'i18n.User.enews',
      isInline: true,
      required: true,
      hasBoldLabel: false,
      control: this.form.get('enews') as FormControl
    };

    this.distributionLetterFieldSettings = {
      ...new CustomFormFieldSettings(),
      type: CUSTOM_FORM_FIELD_TYPE.YesNo,
      label: 'i18n.User.distributionLetter',
      isInline: true,
      required: true,
      hasBoldLabel: false,
      control: this.form.get('distributionLetter') as FormControl
    };
  }

  isAdminBE(): boolean {
    return this.currentUser.type === UserType.AdminBE;
  }

  isSaveButtonDisabled(): boolean {
    return this.form.invalid;
  }

  saveChanges(): void {
    this.saved.emit(
      new User(
        this.currentUser.id,
        this.form.get('lastName').value,
        this.form.get('firstName').value,
        this.form.get('emailAddress').value,
        this.form.get('phoneNumber').value,
        this.form.get('mobileNumber').value,
        this.form.get('profession').value,
        this.form.get('language').value,
        this._convertYesNoToBoolean(this.form.get('mobilityContactCommunication').value),
        this._convertYesNoToBoolean(this.form.get('enews').value),
        this._convertYesNoToBoolean(this.form.get('distributionLetter').value),
        this.currentUser.type
      )
    );
  }

  onLogout(): void {
    this.logout.emit(true);
  }

  onDeleteAccount(): void {
    this.deleteAccount.emit();
  }

  onChangePassword(): void {
    this.changePassword.emit();
  }

  private _convertBooleanToYesNo(booleanValue: boolean): 'yes' | 'no' {
    if (booleanValue === null || booleanValue === undefined) {
      return null;
    }

    return booleanValue ? 'yes' : 'no';
  }

  private _convertYesNoToBoolean(yesNoValue: 'yes' | 'no'): boolean {
    if (yesNoValue === null || yesNoValue === undefined) {
      return null;
    }

    return yesNoValue === 'yes';
  }
}
