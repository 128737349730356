import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomizedGlobalErrorHandler implements ErrorHandler {
  static expChangedAfterItHasBeenCheckedErrorOcccured: boolean = false;
  static ERROR_MESSAGE_INVALID_FORM: string = 'ExpressionChangedAfterItHasBeenCheckedError:';

  constructor() {}

  handleError(error: any) {
    const sanitised = this.sanitiseError(error);
  }

  private sanitiseError(error: Error | HttpErrorResponse): ErrorInformation {
    const sanitisedError: ErrorInformation = {
      message: error.message,
      details: []
    };

    if (error instanceof Error) {
      if (error.message.includes(CustomizedGlobalErrorHandler.ERROR_MESSAGE_INVALID_FORM)) {
        console.log('handling change value after validation.');
        CustomizedGlobalErrorHandler.expChangedAfterItHasBeenCheckedErrorOcccured = true;
      } else {
        CustomizedGlobalErrorHandler.expChangedAfterItHasBeenCheckedErrorOcccured = false;
      }
      sanitisedError.details.push(error.stack);
    } else if (error instanceof HttpErrorResponse) {
      sanitisedError.details = Object.keys(error).map((key: string) => `${key}: ${error[key]}`);
    } else {
      sanitisedError.details.push(JSON.stringify(error));
    }
    console.log('Global error handler in action ...', error);
    return sanitisedError;
  }
}

export interface ErrorInformation {
  message: string;
  details: string[];
}
