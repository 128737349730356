import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, first, map, tap } from 'rxjs/operators';
import { GetAllPermission, GetAllPermissionFailure, SetPermission, PermissionActionTypes } from '../actions';
import { ApiPermissionService } from '@app/core/api/api-permission.service';

@Injectable()
export class PermissionEffect {
  constructor(private actions: Actions, private permissionService: ApiPermissionService) {}

  @Effect()
  getAllPermission$: Observable<any> = this.actions.pipe(
    ofType<GetAllPermission>(PermissionActionTypes.GET_ALL_PERMISSION),
    concatMap(() => {
      return this.permissionService.getAllPermission().pipe(
        map(response => {
          return new SetPermission(response);
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return of(new GetAllPermissionFailure());
        })
      );
    })
  );
}
