<h3 mat-dialog-title>{{ 'i18n.LoginDialogComponent.title' | translate }}</h3>

<div mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div *ngFor="let settings of fieldsSettings" fxFlex.gt-xs="0 0 100%">
        <app-custom-form-field [settings]="settings"></app-custom-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="10px">
        <button mat-flat-button color="primary" (click)="onLoginButtonPressed()" [disabled]="form.invalid">
          {{ 'i18n.LoginDialogComponent.login' | translate }}
        </button>

        <button mat-stroked-button color="primary" (click)="onRegisterButtonPressed()">
          {{ 'i18n.LoginDialogComponent.register' | translate }}
        </button>

        <button mat-button fxFlexAlign="center" (click)="onForgottenButtonPressed()">
          {{ 'i18n.LoginDialogComponent.forgotten-password' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
