import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Language, User } from '@app/core/model';
import { RegistrationData } from '@app/core/model/registration-data.model';
import { CustomFormFieldSelectOption, CustomFormFieldSettings, CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { environment } from '@env/environment';
import { SubSink } from 'subsink';

function matchingPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.parent || !control.parent.get('password')) {
      return null;
    }
    const passwordControl = control.parent.get('password');
    const passwordConfirmationControl = control;

    const passwordNotMatching =
      passwordControl &&
      passwordConfirmationControl &&
      passwordConfirmationControl.value &&
      passwordControl.value !== passwordConfirmationControl.value;

    return passwordNotMatching ? { passwordNotMatching: { value: true } } : null;
  };
}

@Component({
  selector: 'app-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss']
})
export class RegisterDialogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prefilledUser: User;
  @Output() registerButtonPressed = new EventEmitter<RegistrationData>();

  form: FormGroup;
  fieldsSettings: CustomFormFieldSettings[];

  subSink = new SubSink();

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      lastName: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      emailAddress: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl(''),
      profession: new FormControl(''),
      language: new FormControl('', [Validators.required]),
      mobilityContactCommunication: new FormControl(this._convertBooleanToYesNo(null), [Validators.required]),
      enews: new FormControl(this._convertBooleanToYesNo(null), [Validators.required]),
      distributionLetter: new FormControl(this._convertBooleanToYesNo(null), [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern(environment.passwordPattern)]),
      passwordConfirmation: new FormControl('', [Validators.required, matchingPasswordValidator()]),
      licenseAgreement: new FormControl(false, [Validators.requiredTrue])
    });

    this.subSink.sink = this.form.get('password').valueChanges.subscribe(changes => {
      this.form.get('passwordConfirmation').updateValueAndValidity();
    });

    const languageOptions: CustomFormFieldSelectOption[] = [
      { value: Language.French, text: 'i18n.Language.French' },
      { value: Language.Dutch, text: 'i18n.Language.Dutch' }
    ];

    this.fieldsSettings = [
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.TextInput,
        label: 'i18n.User.lastName',
        required: true,
        control: this.form.get('lastName') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.TextInput,
        label: 'i18n.User.firstName',
        required: true,
        control: this.form.get('firstName') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.EmailInput,
        label: 'i18n.User.emailAddress',
        required: true,
        control: this.form.get('emailAddress') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.TextInput,
        label: 'i18n.User.phoneNumber',
        required: true,
        control: this.form.get('phoneNumber') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.TextInput,
        label: 'i18n.User.mobileNumber',
        required: false,
        control: this.form.get('mobileNumber') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.TextInput,
        label: 'i18n.User.profession',
        required: false,
        control: this.form.get('profession') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.Select,
        label: 'i18n.User.language',
        required: true,
        control: this.form.get('language') as FormControl,
        options: languageOptions
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.YesNo,
        label: 'i18n.User.mobilityContactCommunication',
        isInline: true,
        required: true,
        hasBoldLabel: false,
        control: this.form.get('mobilityContactCommunication') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.YesNo,
        label: 'i18n.User.enews',
        isInline: true,
        required: true,
        hasBoldLabel: false,
        control: this.form.get('enews') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.YesNo,
        label: 'i18n.User.distributionLetter',
        isInline: true,
        required: true,
        hasBoldLabel: false,
        control: this.form.get('distributionLetter') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.PasswordInput,
        label: 'i18n.User.password',
        required: true,
        control: this.form.get('password') as FormControl,
        customError: { errorName: 'pattern', message: 'i18n.RegisterDialogComponent.password-not-following-pattern' }
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.PasswordInput,
        label: 'i18n.RegisterDialogComponent.password-confirmation',
        required: true,
        control: this.form.get('passwordConfirmation') as FormControl,
        customError: { errorName: 'passwordNotMatching', message: 'i18n.RegisterDialogComponent.password-not-matching-error' }
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.Checkbox,
        label: 'i18n.RegisterDialogComponent.licenseAgreement',
        required: true,
        control: this.form.get('licenseAgreement') as FormControl
      }
    ];

    if (isNotNullNorUndefined(this.prefilledUser)) {
      this._updateFormWithPrefilledUser(this.prefilledUser);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prefilledUser) {
      this._updateFormWithPrefilledUser(this.prefilledUser);
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  isRegisterButtonDisabled(): boolean {
    return this.form.invalid;
  }

  onRegisterButtonPressed(): void {
    this.registerButtonPressed.emit({
      lastName: this.form.get('lastName').value,
      firstName: this.form.get('firstName').value,
      emailAddress: this.form.get('emailAddress').value,
      phoneNumber: this.form.get('phoneNumber').value,
      mobileNumber: this.form.get('mobileNumber').value,
      profession: this.form.get('profession').value,
      language: this.form.get('language').value,
      mobilityContactCommunication: this._convertYesNoToBoolean(this.form.get('mobilityContactCommunication').value),
      enews: this._convertYesNoToBoolean(this.form.get('enews').value),
      distributionLetter: this._convertYesNoToBoolean(this.form.get('distributionLetter').value),
      password: this.form.get('password').value
    });
  }

  private _updateFormWithPrefilledUser(prefilledUser: User): void {
    if (isNotNullNorUndefined(this.form) && isNotNullNorUndefined(prefilledUser)) {
      this.form.patchValue({
        lastName: prefilledUser.lastName,
        firstName: prefilledUser.firstName,
        emailAddress: prefilledUser.emailAddress,
        phoneNumber: prefilledUser.phoneNumber,
        mobileNumber: prefilledUser.mobileNumber,
        profession: prefilledUser.profession,
        language: prefilledUser.language
      });

      this.form.get('lastName').disable();
      this.form.get('firstName').disable();
      this.form.get('emailAddress').disable();
    }
  }

  private _convertBooleanToYesNo(booleanValue: boolean): 'yes' | 'no' {
    if (booleanValue === null || booleanValue === undefined) {
      return null;
    }

    return booleanValue ? 'yes' : 'no';
  }

  private _convertYesNoToBoolean(yesNoValue: 'yes' | 'no'): boolean {
    if (yesNoValue === null || yesNoValue === undefined) {
      return null;
    }

    return yesNoValue === 'yes';
  }
}
