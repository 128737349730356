import { Company } from '@app/core/model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CompanyActions, CompanyActionTypes } from '../actions/company.actions';

export interface CompanyState extends EntityState<Company> {
  // additional entity state properties
}

export const companyAdapter: EntityAdapter<Company> = createEntityAdapter<Company>();

const initialState: CompanyState = companyAdapter.getInitialState({
  // additional entity state properties
});

export function companyReducer(state = initialState, action: CompanyActions): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.GET_ALL_COMPANIES_SUCCESS: {
      return companyAdapter.addAll(action.payload, state);
    }
    case CompanyActionTypes.GET_COMPANY_SUCCESS: {
      return companyAdapter.addOne(action.payload, state);
    }
    case CompanyActionTypes.CREATE_COMPANY_SUCCESS: {
      return companyAdapter.addOne(action.payload, state);
    }
    case CompanyActionTypes.FIDUS_COMPANY_SUCCESS: {
      return companyAdapter.addOne(action.payload, state);
    }
    default: {
      return state;
    }
  }
}
