import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  text: string;
  color: 'primary' | 'accent' | 'warn';
}

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  defaultText = 'i18n.ConfirmationDialogComponent.default-text';
  defaultColor = 'primary';

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}

  ngOnInit() {}

  getText(): string {
    return this.data && this.data.text ? this.data.text : this.defaultText;
  }

  getColor(): string {
    return this.data && this.data.color ? this.data.color : this.defaultColor;
  }
}
