import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiAuthService, ApiUserService } from '@app/core/api';
import { currentTokenCookieName } from '@app/core/interceptors';
import { AuthService, SiteService, SnackbarService } from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, first, map, tap } from 'rxjs/operators';
import {
  AuthActionTypes,
  DeleteAccount,
  DeleteAccountFailure,
  DeleteAccountSuccess,
  GetUserSuccess,
  LoadCurrentUser,
  LoadCurrentUserFailure,
  LoadCurrentUserSuccess,
  Login,
  LoginFailure,
  LoginSuccess,
  Logout,
  Register,
  RegisterFailure,
  RegisterOldUser,
  RegisterSuccess,
  ResetPassword,
  ResetPasswordFailure,
  ResetPasswordSuccess,
  SetNewPassword
} from '../actions';

const currentUserIdCookieName = 'currentUserId';
const numberOfDaysExpirationCookies = 30;
const cookiesPath = '/';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private apiAuthService: ApiAuthService,
    private apiUserService: ApiUserService,
    private router: Router,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialog,
    private cookieService: CookieService,
    private siteService: SiteService,
    private authService: AuthService
  ) {}

  @Effect()
  login$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN),
    map((action: Login) => action.credentials),
    concatMap(loginCredentials => {
      return this.apiAuthService.login(loginCredentials).pipe(
        map(loginResponse => {
          return new LoginSuccess(loginResponse);
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return of(new LoginFailure(error));
        })
      );
    })
  );

  @Effect()
  loginSuccess$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((action: LoginSuccess) => {
      this.cookieService.set(
        currentTokenCookieName,
        JSON.stringify(action.loginResponse.token),
        numberOfDaysExpirationCookies,
        cookiesPath,
        null,
        false,
        'Strict'
      );
      this.cookieService.set(
        currentUserIdCookieName,
        JSON.stringify(action.loginResponse.user.id),
        numberOfDaysExpirationCookies,
        cookiesPath,
        null,
        false,
        'Strict'
      );
      this.dialogRef.closeAll();
      this.snackbarService.openWithTranslation('i18n.AuthEffects.login-success');
      this.authService
        .getCurrentUserType()
        .pipe(first())
        .subscribe(currentUserType => {
          this.siteService.navigateToSitesAccordingToCurrentUserType(currentUserType);
        });
    }),
    concatMap(action => {
      return of(new GetUserSuccess(action.loginResponse.user));
    })
  );

  @Effect({ dispatch: false })
  loginFailure$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE),
    tap((action: LoginFailure) => {
      let errorTranslationKey = 'i18n.AuthEffects.login-failure';

      switch (action.error.status) {
        case 401:
          errorTranslationKey = 'i18n.AuthEffects.login-failure-wrong-credentials';
          break;
      }

      this.snackbarService.openErrorWithTranslation(errorTranslationKey);
    })
  );

  @Effect({ dispatch: false })
  logout$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGOUT),
    tap((action: Logout) => {
      this.cookieService.deleteAll(cookiesPath);
      this.dialogRef.closeAll();
      this.router.navigate(['/home']);
      this.snackbarService.openWithTranslation('i18n.Common.logout');
    })
  );

  @Effect()
  loadCurrentUser$: Observable<any> = this.actions.pipe(
    ofType<LoadCurrentUser>(AuthActionTypes.LOAD_CURRENT_USER),
    concatMap(action => {
      if (this.cookieService.check(currentUserIdCookieName) && this.cookieService.check(currentTokenCookieName)) {
        const currentUserId: number = parseInt(this.cookieService.get(currentUserIdCookieName), 10);
        let token: string = this.cookieService.get(currentTokenCookieName);
        token = token.replace(/^"(.*)"$/, '$1'); // Remove quotes if they are the first and last characters of the string

        return this.apiUserService.getUser(currentUserId).pipe(
          map(user => {
            return new LoadCurrentUserSuccess({ currentUser: user, token });
          }),
          catchError(error => {
            console.log(error);
            return of(new LoadCurrentUserFailure());
          })
        );
      }
      return of(new LoadCurrentUserFailure());
    })
  );

  @Effect()
  loadCurrentUserSuccess$: Observable<any> = this.actions.pipe(
    ofType<LoadCurrentUserSuccess>(AuthActionTypes.LOAD_CURRENT_USER_SUCCESS),
    map(action => action.loadCurrentUserResponse),
    concatMap(loadCurrentUserResponse => {
      return of(new GetUserSuccess(loadCurrentUserResponse.currentUser));
    })
  );

  @Effect({ dispatch: false })
  loadCurrentUserFailure$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOAD_CURRENT_USER_FAILURE),
    tap((action: LoadCurrentUserFailure) => {
      this.cookieService.deleteAll(cookiesPath);
      this.dialogRef.closeAll();

      if (
        !window.location.href.includes('/password') &&
        !window.location.href.includes('/auth/register-old') &&
        !window.location.href.includes('/home')
      ) {
        this.router.navigate(['/home']);
      }
    })
  );

  @Effect()
  register$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.REGISTER),
    map((action: Register) => action.registrationData),
    concatMap(registrationData => {
      return this.apiAuthService.register(registrationData).pipe(
        map(loginResponse => {
          return new RegisterSuccess(loginResponse);
        }),
        catchError(error => {
          console.log(error);
          return of(new RegisterFailure());
        })
      );
    })
  );

  @Effect()
  registerOldUser$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.REGISTER_OLD_USER),
    concatMap((action: RegisterOldUser) => {
      return this.apiAuthService.registerOldUser(action.registrationData, action.token).pipe(
        map(loginResponse => {
          return new RegisterSuccess(loginResponse);
        }),
        catchError(error => {
          console.log(error);
          return of(new RegisterFailure());
        })
      );
    })
  );

  @Effect({ dispatch: false })
  registerSuccess$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.REGISTER_SUCCESS),
    tap((action: RegisterSuccess) => {
      if (action.loginResponse.token !== undefined) {
        this.snackbarService.openWithTranslation('i18n.AuthEffects.register-confirm-success');
        this.dialogRef.closeAll();
        this.router.navigate(['/home']);
      } else {
        this.snackbarService.openWithTranslation('i18n.AuthEffects.register-success');
        this.dialogRef.closeAll();
      }
    })
  );

  @Effect({ dispatch: false })
  registerFailure$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.REGISTER_FAILURE),
    tap((action: RegisterFailure) => {
      this.snackbarService.openErrorWithTranslation('i18n.AuthEffects.register-failure');
    })
  );

  @Effect()
  resetPassword$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.RESET_PASSWORD),
    map((action: ResetPassword) => action.emailAddress),
    concatMap(emailAddress => {
      return this.apiAuthService.resetPassword(emailAddress).pipe(
        map(loginResponse => {
          this.snackbarService.openErrorWithTranslation('i18n.AuthEffects.reset-password-success');
          this.dialogRef.closeAll();
          return new ResetPasswordSuccess(loginResponse);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.AuthEffects.reset-password-failure');
          return of(new ResetPasswordFailure());
        })
      );
    })
  );

  @Effect()
  newPassword$: Observable<any> = this.actions.pipe(
    ofType<SetNewPassword>(AuthActionTypes.SET_NEW_PASSWORD),
    concatMap(action => {
      return this.apiAuthService.setNewPassword(action.password, action.token).pipe(
        map(loginResponse => {
          this.snackbarService.openWithTranslation('i18n.AuthEffects.new-password-success');
          this.router.navigate(['/home']);
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.AuthEffects.new-password-failure'); // TODO: Add to WTI
          return of(new LoginFailure(error));
        })
      );
    })
  );

  @Effect()
  deleteAccount$: Observable<any> = this.actions.pipe(
    ofType<DeleteAccount>(AuthActionTypes.DELETE_ACCOUNT),
    concatMap(action => {
      return this.apiAuthService.deleteAccount().pipe(
        map(response => {
          return new DeleteAccountSuccess();
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return of(new DeleteAccountFailure(error));
        })
      );
    })
  );

  @Effect()
  deleteAccountSuccess$: Observable<any> = this.actions.pipe(
    ofType<DeleteAccountSuccess>(AuthActionTypes.DELETE_ACCOUNT_SUCCESS),
    tap(action => {
      this.snackbarService.openWithTranslation('i18n.AuthEffects.delete-account-success');
    }),
    concatMap(action => {
      return of(new Logout());
    })
  );

  @Effect({ dispatch: false })
  deleteAccountFailure$: Observable<any> = this.actions.pipe(
    ofType<DeleteAccountFailure>(AuthActionTypes.DELETE_ACCOUNT_FAILURE),
    tap(action => {
      this.snackbarService.openErrorWithTranslation('i18n.AuthEffects.delete-account-failure');
    })
  );
}
