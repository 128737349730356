import { Injectable } from '@angular/core';
import { ApiEstablishmentUnitService } from '@app/core/api/api-establishment-unit.service';
import { SnackbarService } from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  EstablishmentUnitActionTypes,
  GetAllEstablishmentUnits,
  GetAllEstablishmentUnitsFailure,
  GetAllEstablishmentUnitsSuccess,
  GetEstablishmentUnit,
  GetEstablishmentUnitFailure,
  GetEstablishmentUnitSuccess,
  UpdateEstablishmentUnit,
  UpdateEstablishmentUnitFailure,
  UpdateEstablishmentUnitSuccess,
  DeleteEstablishmentUnit,
  DeleteEstablishmentUnitFailure,
  DeleteEstablishmentUnitSuccess
} from '../actions/establishment-unit.actions';

@Injectable()
export class EstablishmentUnitEffects {
  constructor(
    private actions: Actions,
    private apiEstablishmentUnitService: ApiEstablishmentUnitService,
    private snackbarService: SnackbarService
  ) {}

  @Effect()
  getAllEstablishmentUnits$: Observable<any> = this.actions.pipe(
    ofType<GetAllEstablishmentUnits>(EstablishmentUnitActionTypes.GET_ALL_ESTABLISHMENT_UNITS),
    concatMap(() => {
      return this.apiEstablishmentUnitService.getAllEstablishmentUnits().pipe(
        map(establishmentUnits => {
          return new GetAllEstablishmentUnitsSuccess(establishmentUnits);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetAllEstablishmentUnitsFailure());
        })
      );
    })
  );

  @Effect()
  getEstablishmentUnit$: Observable<any> = this.actions.pipe(
    ofType<GetEstablishmentUnit>(EstablishmentUnitActionTypes.GET_ESTABLISHMENT_UNIT),
    map(action => action.establishmentUnitId),
    concatMap(id => {
      return this.apiEstablishmentUnitService.getEstablishmentUnit(id).pipe(
        map(establishmentUnit => {
          return new GetEstablishmentUnitSuccess(establishmentUnit);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetEstablishmentUnitFailure());
        })
      );
    })
  );

  @Effect()
  updateEstablishmentUnit$: Observable<any> = this.actions.pipe(
    ofType<UpdateEstablishmentUnit>(EstablishmentUnitActionTypes.UPDATE_ESTABLISHMENT_UNIT),
    concatMap(action => {
      return this.apiEstablishmentUnitService.updateEstablishmentUnit(action.update).pipe(
        map(_ => {
          this.snackbarService.openWithTranslation('i18n.EstablishmentUnitEffects.update-establishment-unit-success');
          return new UpdateEstablishmentUnitSuccess(action.update);
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.EstablishmentUnitEffects.update-establishment-unit-failure');
          return of(new UpdateEstablishmentUnitFailure());
        })
      );
    })
  );

  @Effect()
  deleteBUnits$: Observable<any> = this.actions.pipe(
    ofType<DeleteEstablishmentUnit>(EstablishmentUnitActionTypes.DELETE_ESTABLISHMENT_UNIT),
    concatMap(action => {
      return this.apiEstablishmentUnitService.deleteEstablishmentUnit(action.unitID).pipe(
        map(_ => {
          // TODO: define the translation text
          this.snackbarService.openWithTranslation('i18n.EstablishmentEffects.delete-site-success');
          return new DeleteEstablishmentUnitSuccess(action.unitID);
        }),
        catchError(error => {
          this.snackbarService.openErrorWithTranslation('i18n.EstablishmentEffects.delete-unit-failure');
          return of(new DeleteEstablishmentUnitFailure());
        })
      );
    })
  );
}
