import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Address, Company, User } from '@app/core/model';
import { Role, RoleStatus } from '@app/core/model/role.model';
import { AddressService } from '@app/core/services/address.service';
import {
  CustomExpandableTableSettings,
  CustomTableActionEvent,
  CustomTableExpandableDataType
} from '@app/shared/components/custom-table/custom-table.component';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from 'util';

class AffiliationsTableDataType extends CustomTableExpandableDataType {
  roleId: number;
  companyId: number;
  companyName: string;
  bceNumber: string;
  address: Address;
  formattedAddress: string;
  user: User;
  userName: string;
  status: RoleStatus;
  statusTranslation: string;

  constructor(id: number) {
    super(id);
  }
}

@Component({
  selector: 'app-my-affiliations',
  templateUrl: './my-affiliations.component.html',
  styleUrls: ['./my-affiliations.component.scss']
})
export class MyAffiliationsComponent implements OnInit, OnChanges {
  @Input() currentUser: User;
  @Input() currentUserRoles: Role[];
  @Input() currentUserCompanies: Company[];
  @Input() allRolesFromCurrentUserCompanies: Role[];
  @Input() allUsersFromCurrentUserCompanies: User[];
  @Input() pendingRolesFromCompaniesWhereCurrentUserIsAdmin: Role[];
  @Output() newAffiliationApplicationButtonPressed = new EventEmitter();
  @Output() userNameClicked = new EventEmitter<User>();
  @Output() userPromoted = new EventEmitter<Partial<Role>>();
  @Output() affiliationRemoved = new EventEmitter<number>();
  @Output() pendingApplicationAccepted = new EventEmitter<number>();
  @Output() pendingApplicationDeclined = new EventEmitter<number>();

  affiliationsTableSettings: CustomExpandableTableSettings;
  hasPendingRolesFromCompaniesWhereCurrentUserIsAdmin = false;
  companiesWhereCurrentUserHasPendingRolesToHandle: Company[] = [];

  constructor(private addressService: AddressService, private translateService: TranslateService) {}

  ngOnInit() {
    this.initAffiliationsTableSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDataSource();

    if (changes.pendingRolesFromCompaniesWhereCurrentUserIsAdmin || changes.currentUserCompanies) {
      this.hasPendingRolesFromCompaniesWhereCurrentUserIsAdmin =
        isNotNullNorUndefined(this.pendingRolesFromCompaniesWhereCurrentUserIsAdmin) &&
        this.pendingRolesFromCompaniesWhereCurrentUserIsAdmin.length !== 0;

      this.companiesWhereCurrentUserHasPendingRolesToHandle = isNotNullNorUndefined(this.pendingRolesFromCompaniesWhereCurrentUserIsAdmin)
        ? this.pendingRolesFromCompaniesWhereCurrentUserIsAdmin
            .map(role => this.currentUserCompanies.find(company => company.id === role.companyId))
            .filter((company, index, companies) => companies.indexOf(company) === index) // Filter to remove duplicates
            .filter(company => isNotNullNorUndefined(company))
        : [];
    }
  }

  initAffiliationsTableSettings(): void {
    this.affiliationsTableSettings = {
      ...new CustomExpandableTableSettings(),
      emptyDataSourceText: 'i18n.MyAffiliationsComponent.empty-affiliations-table',
      displayedColumns: ['companyName', 'bceNumber', 'userName', 'statusTranslation'],
      expandedRowDisplayedColumns: ['userName', 'statusTranslation'],
      isExpandIndicatorHidden: this.isExpandIndicatorHidden,
      columnSettings: [
        { key: 'bceNumber', headerText: 'i18n.Company.bceNumber' },
        { key: 'companyName', headerText: this.isLanguageNL() ? 'i18n.Company.nameNL' : 'i18n.Company.nameFR' },
        { key: 'formattedAddress', headerText: 'i18n.Company.address', conditionalColor: this.getAddressColor },
        { key: 'userName', headerText: 'i18n.MyAffiliationsComponent.user', isAction: true },
        { key: 'statusTranslation', headerText: 'i18n.Role.status', conditionalColor: this.getStatusColor, isBold: true }
      ],
      actions: [
        {
          name: 'is_admin',
          iconName: 'star',
          color: '#096077',
          tooltip: 'i18n.MyAffiliationsComponent.is-admin-tooltip',
          isHidden: this.isAdminActionHidden,
          isDisabled: () => true
        },
        {
          name: 'delete',
          iconName: 'delete',
          tooltip: 'i18n.MyAffiliationsComponent.delete-tooltip',
          tooltipWhenDisabled: 'i18n.MyAffiliationsComponent.delete-tooltip-when-disabled',
          isHidden: this.isDeleteActionHidden,
          isDisabled: this.isDeleteActionDisabled
        }
      ],
      expandedTableActions: [
        {
          name: 'acceptPendingApplication',
          iconName: 'check_circle_outline',
          color: 'green',
          tooltip: 'i18n.MyAffiliationsComponent.acceptPendingApplication-tooltip',
          isHidden: this.isAcceptPendingApplicationActionHidden,
          isDisabled: this.isAcceptPendingApplicationActionDisabled
        },
        {
          name: 'declinePendingApplication',
          iconName: 'highlight_off',
          color: 'red',
          tooltip: 'i18n.MyAffiliationsComponent.declinePendingApplication-tooltip',
          isHidden: this.isDeclinePendingApplicationActionHidden,
          isDisabled: this.isDeclinePendingApplicationActionDisabled
        },
        {
          name: 'promote',
          iconName: 'star_border',
          iconNameWhenHover: 'star',
          tooltip: 'i18n.MyAffiliationsComponent.promote-tooltip',
          isHidden: this.isPromoteActionHidden,
          isDisabled: this.isPromoteActionDisabled
        },
        {
          name: 'delete',
          iconName: 'delete',
          tooltip: 'i18n.MyAffiliationsComponent.delete-tooltip',
          isHidden: this.isDeleteActionHidden,
          isDisabled: this.isDeleteActionDisabled
        }
      ]
    };

    this.updateDataSource();
  }

  updateDataSource(): void {
    const allColleagues = this.allUsersFromCurrentUserCompanies.filter(u => u.id !== this.currentUser.id);
    const allRolesFromColleagues = this.allRolesFromCurrentUserCompanies.filter(r => r.userId !== this.currentUser.id);

    const dataSource = this.currentUserRoles.map(role => {
      const company = this.getCompany(role.companyId);
      const allRolesFromColleaguesFromCurrentCompany = allRolesFromColleagues.filter(r => r.companyId === role.companyId);

      const expandableDataSource: AffiliationsTableDataType[] = allRolesFromColleaguesFromCurrentCompany
        .map(colleagueRole => {
          const colleague = allColleagues.find(c => c.id === colleagueRole.userId);

          if (isNullOrUndefined(colleague)) {
            //console.error(`Couldn't link role with user_id=${colleagueRole.id} with user in colleagues list`, allColleagues);
            return null;
          }

          return {
            ...new AffiliationsTableDataType(colleagueRole.id),
            roleId: colleagueRole.id,
            companyId: isNullOrUndefined(company) ? undefined : company.id,
            companyName: isNullOrUndefined(company) ? undefined : this.isLanguageNL() ? company.nameNL : company.nameFR,
            bceNumber: isNullOrUndefined(company) ? undefined : company.bceNumber,
            address: isNullOrUndefined(company) ? undefined : company.address,
            formattedAddress: isNullOrUndefined(company)
              ? undefined
              : this.addressService.toString(company.address)
              ? this.addressService.toString(company.address)
              : 'Inconnu', // TODO: Use translation
            user: colleague,
            userName: colleague.firstName + ' ' + colleague.lastName,
            status: colleagueRole.status,
            statusTranslation: this._getStatusTranslationKey(colleagueRole.status),
            expandableDataSource: null
          } as AffiliationsTableDataType;
        })
        .filter(value => isNotNullNorUndefined(value));

      return {
        ...new AffiliationsTableDataType(role.id),
        id: role.id,
        roleId: role.id,
        companyId: isNullOrUndefined(company) ? undefined : company.id,
        companyName: isNullOrUndefined(company) ? undefined : this.isLanguageNL() ? company.nameNL : company.nameFR,
        bceNumber: isNullOrUndefined(company) ? undefined : company.bceNumber,
        address: isNullOrUndefined(company) ? undefined : company.address,
        formattedAddress: isNullOrUndefined(company)
          ? undefined
          : this.addressService.toString(company.address)
          ? this.addressService.toString(company.address)
          : 'Inconnu', // TODO: Use translation
        user: this.currentUser,
        userName: this.currentUser.firstName + ' ' + this.currentUser.lastName,
        status: role.status,
        statusTranslation: this._getStatusTranslationKey(role.status),
        expandableDataSource
      } as AffiliationsTableDataType;
    });

    this.affiliationsTableSettings = {
      ...this.affiliationsTableSettings,
      dataSource
    };
  }

  getCompany(companyId: number): Company {
    return this.currentUserCompanies.find(company => company.id === companyId);
  }

  addressToString(address: Address): string {
    return this.addressService.toString(address);
  }

  onNewAffiliationApplicationButtonPressed(): void {
    this.newAffiliationApplicationButtonPressed.emit();
  }

  onActionTriggered(actionEvent: CustomTableActionEvent): void {
    const roleId = (actionEvent.row as AffiliationsTableDataType).roleId;
    const user = (actionEvent.row as AffiliationsTableDataType).user;
    const companyId = (actionEvent.row as AffiliationsTableDataType).companyId;
    switch (actionEvent.actionName) {
      case 'userName':
        this.userNameClicked.emit(user);
        break;
      case 'delete':
        this.affiliationRemoved.emit(roleId);
        break;
      case 'promote':
        this.userPromoted.emit({ id: roleId, userId: user.id, companyId });
        break;
      case 'acceptPendingApplication':
        this.pendingApplicationAccepted.emit(roleId);
        break;
      case 'declinePendingApplication':
        this.pendingApplicationDeclined.emit(roleId);
        break;
      default:
        console.log('event not recognized:', actionEvent.actionName);
        break;
    }
  }

  isLanguageNL(): boolean {
    return this.translateService.currentLang === 'nl';
  }

  private _getStatusTranslationKey(status: RoleStatus): string {
    switch (status) {
      case RoleStatus.Admin:
        return 'i18n.RoleStatus.Admin';
      case RoleStatus.Affiliated:
        return 'i18n.RoleStatus.Affiliated';
      case RoleStatus.Pending:
        return 'i18n.RoleStatus.Pending';
      case RoleStatus.Refused:
        return 'i18n.RoleStatus.Refused';
      default:
        return 'i18n.RoleStatus.Unknown';
    }
  }

  private isExpandIndicatorHidden(element: AffiliationsTableDataType): boolean {
    return element.status === RoleStatus.Pending || element.status === RoleStatus.Refused;
  }

  private getStatusColor(element: AffiliationsTableDataType): string {
    switch (element.status) {
      case RoleStatus.Admin:
        return 'primary';
      case RoleStatus.Affiliated:
        return 'success';
      case RoleStatus.Pending:
        return 'incomplete';
      case RoleStatus.Refused:
        return '#870000';
      default:
        break;
    }
  }

  private getAddressColor = (element: AffiliationsTableDataType): string => {
    const formattedAddress = this.addressService.toString(element.address);
    if (!isNotNullNorUndefined(formattedAddress)) {
      return 'incomplete';
    }
  };

  private isAcceptPendingApplicationActionHidden = (element: AffiliationsTableDataType) => {
    const role = this.allRolesFromCurrentUserCompanies.find(r => r.id === element.roleId);

    const isNotCurrentUser = role && role.userId !== this.currentUser.id;
    const isPendingColleague = isNotCurrentUser && role.status === RoleStatus.Pending;
    const isCurrentUserAdmin = this.currentUserRoles.find(r => r.companyId === role.companyId && r.status === RoleStatus.Admin)
      ? true
      : false;

    return !isCurrentUserAdmin || !isPendingColleague;
  };

  private isAcceptPendingApplicationActionDisabled = (element: AffiliationsTableDataType) => {
    return false;
  };

  private isDeclinePendingApplicationActionHidden = (element: AffiliationsTableDataType) => {
    const role = this.allRolesFromCurrentUserCompanies.find(r => r.id === element.roleId);

    const isNotCurrentUser = role && role.userId !== this.currentUser.id;
    const isPendingColleague = isNotCurrentUser && role.status === RoleStatus.Pending;
    const isCurrentUserAdmin = this.currentUserRoles.find(r => r.companyId === role.companyId && r.status === RoleStatus.Admin)
      ? true
      : false;

    return !isCurrentUserAdmin || !isPendingColleague;
  };

  private isDeclinePendingApplicationActionDisabled = (element: AffiliationsTableDataType) => {
    return false;
  };

  private isPromoteActionHidden = (element: AffiliationsTableDataType) => {
    const role = this.allRolesFromCurrentUserCompanies.find(r => r.id === element.roleId);

    const isNotCurrentUser = role && role.userId !== this.currentUser.id;
    const isAffiliatedColleague = isNotCurrentUser && role.status === RoleStatus.Affiliated;
    const isCurrentUserAdmin = this.currentUserRoles.find(r => r.companyId === role.companyId && r.status === RoleStatus.Admin)
      ? true
      : false;

    return !isCurrentUserAdmin || !isAffiliatedColleague;
  };

  private isPromoteActionDisabled = (element: AffiliationsTableDataType) => {
    return false;
  };

  private isAdminActionHidden = (element: AffiliationsTableDataType) => {
    return element.status !== RoleStatus.Admin;
  };

  private isDeleteActionHidden = (element: AffiliationsTableDataType) => {
    const role = this.allRolesFromCurrentUserCompanies.find(r => r.id === element.roleId);

    const isNotCurrentUser = role && role.userId !== this.currentUser.id;
    const isPendingColleague = isNotCurrentUser && role.status === RoleStatus.Pending;
    const isCurrentUserAdmin = this.currentUserRoles.find(r => r.companyId === role.companyId && r.status === RoleStatus.Admin)
      ? true
      : false;

    return (!isCurrentUserAdmin && isNotCurrentUser) || isPendingColleague;
  };

  private isDeleteActionDisabled = (element: AffiliationsTableDataType) => {
    const role = this.currentUserRoles.find(r => r.id === element.roleId);

    const isAdmin = role && role.status === RoleStatus.Admin;

    return isAdmin;
  };
}
