import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RegistrationData } from '@app/core/model/registration-data.model';
import { AuthService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { RegisterDialogComponent } from '../../components';

@Component({
  selector: 'app-register-dialog-container',
  templateUrl: './register-dialog.container.html',
  styleUrls: ['./register-dialog.container.scss']
})
export class RegisterDialogContainerComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<RegisterDialogComponent>,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  register(registrationData: RegistrationData): void {
    this.authService.register(registrationData);
  }

  isCurrentLanguageDutch(): boolean {
    return this.translateService.currentLang === 'nl';
  }
}
