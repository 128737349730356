import { Injectable } from '@angular/core';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { Address } from '../model';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(private translateService: TranslateService) {}

  toString(address: Address): string {
    if (!isNotNullNorUndefined(address)) {
      return null;
    }

    let formattedAddress = '';

    if ((this._isLanguageNL() && isNotNullNorUndefined(address.streetNL)) || isNotNullNorUndefined(address.streetFR)) {
      formattedAddress = this._isLanguageNL() ? address.streetNL : address.streetFR;
      if (isNotNullNorUndefined(address.streetNumber)) {
        formattedAddress += ` ${address.streetNumber}`;
      }
    }

    if (isNotNullNorUndefined(address.postalCode)) {
      formattedAddress += formattedAddress.length !== 0 ? `, ${address.postalCode}` : address.postalCode;
      if ((this._isLanguageNL() && isNotNullNorUndefined(address.localityNL)) || isNotNullNorUndefined(address.localityFR)) {
        formattedAddress += this._isLanguageNL() ? ` ${address.localityNL}` : ` ${address.localityFR}`;
      }
    } else if ((this._isLanguageNL() && isNotNullNorUndefined(address.localityNL)) || isNotNullNorUndefined(address.localityFR)) {
      formattedAddress += this._isLanguageNL()
        ? formattedAddress.length !== 0
          ? `, ${address.localityNL}`
          : address.localityNL
        : formattedAddress.length !== 0
        ? `, ${address.localityFR}`
        : address.localityFR;
    }

    return formattedAddress.length !== 0 ? formattedAddress : null;
  }

  addressesHaveCompatiblePostalCodes(addresses: Address[]): boolean {
    if (addresses.length < 2) {
      return true;
    }

    const postalCodes: number[] = addresses.map(address => address.postalCode);

    return postalCodes.every(referencePostalCode => {
      const compatiblePostalCodes: number[] = this.getCompatiblePostalCodes(referencePostalCode);
      return postalCodes.every(postalCode => compatiblePostalCodes.includes(postalCode));
    });
  }

  getCompatiblePostalCodes(referencePostalCode: number): number[] {
    switch (referencePostalCode) {
      case 1000:
        return [
          1000,
          1005,
          1006,
          1007,
          1008,
          1009,
          1011,
          1012,
          1020,
          1030,
          1035,
          1040,
          1041,
          1046,
          1047,
          1048,
          1049,
          1050,
          1060,
          1070,
          1080,
          1100,
          1105,
          1170,
          1180,
          1200,
          1210,
          1212
        ];

      case 1005:
        return [1000, 1005, 1006, 1007];

      case 1006:
        return [1000, 1005, 1006, 1007];

      case 1007:
        return [1000, 1005, 1006, 1007];

      case 1008:
        return [1000, 1008, 1009, 1012];

      case 1009:
        return [1000, 1008, 1009, 1012];

      case 1011:
        return [1000, 1011];

      case 1012:
        return [1000, 1008, 1009, 1012];

      case 1020:
        return [1000, 1020, 1080, 1090, 1120];

      case 1030:
        return [1000, 1030, 1031, 1033, 1035, 1043, 1044, 1200, 1210, 1212];

      case 1031:
        return [1030, 1031];

      case 1033:
        return [1030, 1033, 1140];

      case 1035:
        return [1000, 1030, 1035, 1210, 1212];

      case 1040:
        return [1000, 1040, 1041, 1046, 1047, 1048, 1049, 1050, 1150, 1160, 1200];

      case 1041:
        return [1000, 1040, 1041, 1046, 1047, 1048, 1049, 1050];

      case 1043:
        return [1030, 1043, 1044, 1140];

      case 1044:
        return [1030, 1043, 1044, 1140];

      case 1046:
        return [1000, 1040, 1041, 1046, 1047, 1048, 1049, 1050];

      case 1047:
        return [1000, 1040, 1041, 1046, 1047, 1048, 1049, 1050];

      case 1048:
        return [1000, 1040, 1041, 1046, 1047, 1048, 1049, 1050];

      case 1049:
        return [1000, 1040, 1041, 1046, 1047, 1048, 1049, 1050];

      case 1050:
        return [1000, 1040, 1041, 1046, 1047, 1048, 1049, 1050, 1060, 1160, 1170, 1180, 1190];

      case 1060:
        return [1000, 1050, 1060, 1070, 1190];

      case 1070:
        return [1000, 1060, 1070, 1080, 1190];

      case 1080:
        return [1000, 1020, 1070, 1080, 1081, 1082, 1083, 1090];

      case 1081:
        return [1080, 1081, 1082, 1083, 1090];

      case 1082:
        return [1080, 1081, 1082, 1083];

      case 1083:
        return [1080, 1081, 1082, 1083, 1090];

      case 1090:
        return [1020, 1080, 1081, 1083, 1090];

      case 1099:
        return [1099, 1120];

      case 1100:
        return [1000, 1100, 1210];

      case 1101:
        return [1101];

      case 1105:
        return [1000, 1105];

      case 1110:
        return [1110, 1130, 1140];

      case 1120:
        return [1020, 1099, 1120];

      case 1130:
        return [1110, 1130, 1140];

      case 1140:
        return [1033, 1043, 1044, 1110, 1130, 1140];

      case 1150:
        return [1040, 1150, 1160, 1200];

      case 1160:
        return [1040, 1050, 1150, 1160, 1170];

      case 1170:
        return [1000, 1050, 1160, 1170, 1180];

      case 1180:
        return [1000, 1050, 1170, 1180, 1190];

      case 1190:
        return [1050, 1060, 1070, 1180, 1190];

      case 1200:
        return [1000, 1030, 1040, 1150, 1200];

      case 1210:
        return [1000, 1030, 1035, 1100, 1210, 1212];

      case 1212:
        return [1000, 1030, 1035, 1210, 1212];

      default:
        return [referencePostalCode];
    }
  }

  private _isLanguageNL(): boolean {
    return this.translateService.currentLang === 'nl';
  }

  private _isLanguageFR(): boolean {
    return this.translateService.currentLang === 'fr';
  }
}
