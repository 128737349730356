export class User {
  constructor();
  constructor(
    id: number,
    lastName: string,
    firstName: string,
    emailAddress: string,
    phoneNumber: string,
    mobileNumber: string,
    profession: string,
    language: Language,
    mobilityContactCommunication: boolean,
    enews: boolean,
    distributionLetter: boolean,
    type: UserType
  );
  constructor(
    public id?: number,
    public lastName?: string,
    public firstName?: string,
    public emailAddress?: string,
    public phoneNumber?: string,
    public mobileNumber?: string,
    public profession?: string,
    public language?: Language,
    public mobilityContactCommunication?: boolean,
    public enews?: boolean,
    public distributionLetter?: boolean,
    public type?: UserType
  ) {}
}

export enum Language {
  French = 'French',
  Dutch = 'Dutch'
}

export enum UserType {
  Basic = 'Basic',
  AdminBE = 'AdminBE'
}
