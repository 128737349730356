import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { GetAllSurveys, ImportClearState, ImportExcelFile, ImportExcelFileSuccess, ImportSurvey } from '@app/store/actions';
import { AppState } from '@app/store/app.states';
import {
  selectAllSurveys,
  selectExcelImportBinary,
  selectExcelImportFileName,
  selectExcelImportPlanId,
  selectSurveyById,
  selectSurveyExcelImportError,
  selectSurveyExcelImportErrorMsg,
  selectSurveyExcelImportInvalidPostcode,
  selectSurveyNumWorker
} from '@app/store/selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable, Observer } from 'rxjs';
import { distinctUntilChanged, filter, first, tap } from 'rxjs/operators';
import { Survey, TravelPlan } from '../model';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Update } from '@ngrx/entity';
import { InvalidPostalCode } from '@app/store/reducers';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  constructor(private store: Store<AppState>, private http: HttpClient, private translateService: TranslateService) {}

  loadAllSurveys(): void {
    this.store.dispatch(new GetAllSurveys());
  }

  importSurvey(planId: number, exportURL: string, surveyID: number): void {
    this.store.dispatch(new ImportSurvey(planId, exportURL, surveyID));
  }

  importClearState(): void {
    this.store.dispatch(new ImportClearState());
  }

  isImportError(): Observable<boolean> {
    return this.store.select(selectSurveyExcelImportError);
  }

  getImportInvalidPostcode(): Observable<InvalidPostalCode[]> {
    return this.store.select(selectSurveyExcelImportInvalidPostcode);
  }

  getImportValidationResult(): Observable<object> {
    return this.store.select(selectSurveyNumWorker);
  }

  getImportErrorMessage(): Observable<string> {
    return this.store.select(selectSurveyExcelImportErrorMsg);
  }

  getImportPlanId(): Observable<number> {
    return this.store.select(selectExcelImportPlanId);
  }

  getImportFileName(): Observable<string> {
    return this.store.select(selectExcelImportFileName);
  }

  getImportFileBinary(): Observable<string> {
    return this.store.select(selectExcelImportBinary);
  }

  importExcelFile(planId: number, excelFile: File, ignoreValidation: boolean): void {
    this._convertFileToBase64(excelFile).subscribe(excelFileAsBase64 => {
      this.store.dispatch(new ImportExcelFile(planId, excelFile.name, excelFileAsBase64, ignoreValidation));
    });
  }

  importExcelFileSucces(travelPlan: Update<TravelPlan>): void {
    this.store.dispatch(new ImportExcelFileSuccess(travelPlan));
  }

  downloadExcelImportFile(planId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/gismob/${planId}/files`, { responseType: 'blob' }).pipe(
      tap(responseBlob => {
        saveAs(responseBlob, `PDE-import-${planId}`);
      })
    );
  }

  getAllSurveys(): Observable<Survey[]> {
    return this.store.select(selectAllSurveys).pipe(
      filter(surveys => isNotNullNorUndefined(surveys)),
      distinctUntilChanged()
    );
  }

  getSurveyById(surveyId: number): Observable<Survey> {
    return this.store.select(selectSurveyById(), { surveyId }).pipe(
      filter(survey => isNotNullNorUndefined(survey)),
      distinctUntilChanged()
    );
  }

  navigateToGismob(token: string): void {
    const form = this.createForm('_blank', token);

    if (token && token !== '') {
      form.method = 'POST';
      form.action = this._getGismobLink(environment.gismobEnv);
      this.addInputToForm(form, 'auth_token', token);
    } else {
      form.method = 'GET';
      form.action = this._getUnauthorizedGismobLink(environment.gismobEnv);
    }

    this.appendFormAndSubmit(form);
  }

  private createForm(target: string, token: string): HTMLFormElement {
    const form = document.createElement('form');
    form.style.visibility = 'hidden';
    form.target = target;
    return form;
  }

  private addInputToForm(form: HTMLFormElement, name: string, value: string): void {
    const input = document.createElement('input');
    input.name = name;
    input.value = value;
    form.appendChild(input);
  }

  private appendFormAndSubmit(form: HTMLFormElement): void {
    document.body.appendChild(form);
    form.submit();
  }

  private _convertFileToBase64(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const fileAsBase64 = reader.result.toString();
        observer.next(fileAsBase64);
        observer.complete();
      };
    }).pipe(first());
  }

  private _currentLang() {
    return this.translateService.currentLang === 'fr' ? 'fr' : 'nl';
  }

  private _getUnauthorizedGismobLink(env: string): string {
    const currentLang = this._currentLang();
    if (env !== 'UAT') {
      if (currentLang === 'fr') {
        return 'https://enquetemobilite.brussels/accounts/login/';
      } else {
        return 'https://mobiliteitsenquete.brussels/accounts/login/';
      }
    } else {
      return 'https://gismob-uat-' + currentLang + '.environnement.brussels/accounts/login/';
    }
  }

  private _getGismobLink(env: string): string {
    const currentLang = this._currentLang();
    if (env !== 'UAT') {
      if (currentLang === 'fr') {
        return 'https://enquetemobilite.brussels/accounts/direct-sign-in/?next=/survey/manage';
      } else {
        return 'https://mobiliteitsenquete.brussels/accounts/direct-sign-in/?next=/survey/manage';
      }
    } else {
      return 'https://gismob-uat-' + currentLang + '.environnement.brussels/accounts/direct-sign-in/?next=/survey/manage';
    }
  }
}
