import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginDialogComponent, ForgottenDialogComponent } from './components';
import { RegisterDialogComponent } from './components/register-dialog/register-dialog.component';
import { LoginDialogContainerComponent, ForgottenDialogContainerComponent } from './containers';
import { RegisterDialogContainerComponent } from './containers/register-dialog-container/register-dialog.container';
import { RegisterOldUserContainerComponent } from './containers/register-old-user-container/register-old-user-container.component';

@NgModule({
  declarations: [
    LoginDialogComponent,
    LoginDialogContainerComponent,
    RegisterDialogContainerComponent,
    RegisterDialogComponent,
    ForgottenDialogContainerComponent,
    ForgottenDialogComponent,
    RegisterOldUserContainerComponent
  ],
  imports: [CommonModule, AuthRoutingModule, SharedModule],
  entryComponents: [LoginDialogContainerComponent, RegisterDialogContainerComponent, ForgottenDialogContainerComponent]
})
export class AuthModule {}
