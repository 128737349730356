import { TravelPlan } from '@app/core/model';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { travelPlanAdapter, TravelPlanState } from '../reducers';

export const selectTravelPlanState = createFeatureSelector<TravelPlanState>('travelPlans');

const { selectIds, selectEntities, selectAll, selectTotal } = travelPlanAdapter.getSelectors();

export const selectTravelPlanIds = createSelector(
  selectTravelPlanState,
  selectIds
);

export const selectTravelPlanEntities = createSelector(
  selectTravelPlanState,
  selectEntities
);

export const selectAllTravelPlans = createSelector(
  selectTravelPlanState,
  selectAll
);

export const selectTravelPlanTotal = createSelector(
  selectTravelPlanState,
  selectTotal
);

export const selectTravelPlanById = () =>
  createSelector(
    selectTravelPlanEntities,
    (entities: Dictionary<TravelPlan>, props: { travelPlanId: number }) => entities[props.travelPlanId]
  );

export const selectTravelPlanBySiteIdAndFormId = () =>
  createSelector(
    selectAllTravelPlans,
    (allTravelPlans: TravelPlan[], props: { siteId: number; formId: number }) => {
      return allTravelPlans.find(plan => plan.siteId === props.siteId && plan.formId === props.formId);
    }
  );

export const selectTravelPlansBySiteId = () =>
  createSelector(
    selectAllTravelPlans,
    (allTravelPlans: TravelPlan[], props: { siteId: number }) => {
      return allTravelPlans.filter(plan => plan.siteId === props.siteId);
    }
  );

export const selectAnswerByQuestionId = () =>
  createSelector(
    selectTravelPlanEntities,
    (entities: Dictionary<TravelPlan>, props: { travelPlanId: number; questionId: number }) => {
      const travelPlan: TravelPlan = entities[props.travelPlanId];
      return isNotNullNorUndefined(travelPlan.answers) ? travelPlan.answers.find(answer => answer.questionId === props.questionId) : null;
    }
  );

export const selectIsLoading = createSelector(
  selectTravelPlanState,
  (state: TravelPlanState) => state.isLoading
);

export const selectImportTrav = createSelector(
  selectTravelPlanState,
  (state: TravelPlanState) => state.isLoading
);

export const selectImportWorker = createSelector(
  selectTravelPlanState,
  (state: TravelPlanState) => state.isLoading
);

export const selectIsGetTravelPlansLoading = createSelector(
  selectTravelPlanState,
  (state: TravelPlanState) => state.isGetTravelPlansLoading
);
