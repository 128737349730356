import { Injectable } from '@angular/core';
import { Address } from '@app/core/model';
import { convertToPartial } from '@app/shared/utils';

export interface ApiAddress {
  id?: number;
  streetFR?: string;
  streetNL?: string;
  number?: string;
  box?: string;
  postal_code?: number;
  localityFR?: string;
  localityNL?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiAddressService {
  constructor() {}

  addressFromApiToModel(apiAddress: ApiAddress): Address {
    return {
      id: apiAddress.id,
      streetFR: apiAddress.streetFR,
      streetNL: apiAddress.streetNL,
      streetNumber: apiAddress.number,
      box: apiAddress.box,
      postalCode: apiAddress.postal_code,
      localityFR: apiAddress.localityFR,
      localityNL: apiAddress.localityNL
    };
  }

  addressFromModelToApi(address: Partial<Address>): Partial<ApiAddress> {
    return convertToPartial({
      id: address.id,
      streetFR: address.streetFR,
      streetNL: address.streetNL,
      number: address.streetNumber,
      box: address.box,
      postal_code: address.postalCode,
      localityFR: address.localityFR,
      localityNL: address.localityNL
    });
  }
}
