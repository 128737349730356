import { Company } from '@app/core/model';
import { Role, RoleStatus } from '@app/core/model/role.model';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { companyAdapter, CompanyState } from '../reducers/company.reducers';
import { selectCurrentUserRoles } from './role.selectors';

export const selectCompanyState = createFeatureSelector<CompanyState>('companies');

const { selectIds, selectEntities, selectAll, selectTotal } = companyAdapter.getSelectors();

export const selectCompanyIds = createSelector(
  selectCompanyState,
  selectIds
);

export const selectCompanyEntities = createSelector(
  selectCompanyState,
  selectEntities
);

export const selectAllCompanies = createSelector(
  selectCompanyState,
  selectAll
);

export const selectCompanyTotal = createSelector(
  selectCompanyState,
  selectTotal
);

export const selectCompanyById = () =>
  createSelector(
    selectCompanyEntities,
    (entities: Dictionary<Company>, props: { companyId: number }) => entities[props.companyId]
  );

export const selectCompanyByBceNumber = () =>
  createSelector(
    selectAllCompanies,
    (companies: Company[], props: { bceNumber: string }) => companies.find(company => company.bceNumber === props.bceNumber)
  );

export const selectCurrentUserCompanies = createSelector(
  selectAllCompanies,
  selectCurrentUserRoles,
  (companies: Company[], currentUserRoles: Role[]) =>
    currentUserRoles
      .map(role => {
        return companies.find(company => company.id === role.companyId);
      })
      .filter(company => isNotNullNorUndefined(company))
);

export const selectCurrentUserPendingCompanies = createSelector(
  selectAllCompanies,
  selectCurrentUserRoles,
  (companies: Company[], currentUserRoles: Role[]) =>
    currentUserRoles
      .filter(role => role.status === RoleStatus.Pending)
      .map(pendingRole => {
        return companies.find(company => company.id === pendingRole.companyId);
      })
      .filter(company => isNotNullNorUndefined(company))
);

export const selectCurrentUserAcceptedCompanies = createSelector(
  selectAllCompanies,
  selectCurrentUserRoles,
  (companies: Company[], currentUserRoles: Role[]) =>
    currentUserRoles
      .filter(role => role.status === RoleStatus.Admin || role.status === RoleStatus.Affiliated)
      .map(pendingRole => {
        return companies.find(company => company.id === pendingRole.companyId);
      })
      .filter(company => isNotNullNorUndefined(company))
);
