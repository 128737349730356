import { Injectable } from '@angular/core';
import {
  AddRole,
  DeleteRole,
  GetAllRoles,
  GetRole,
  GetRolesByCompanyId,
  GetRolesByUserId,
  UpdateRole,
  PromoteUser
} from '@app/store/actions';
import { AppState } from '@app/store/app.states';
import {
  selectAllRolesFromCurrentUserCompanies,
  selectCurrentUserRoles,
  selectPendingRolesFromCompaniesWhereCurrentUserIsAdmin,
  selectRoleById,
  selectRoleByUserIdAndCompanyId,
  selectRolesByCompanyId,
  selectRolesByUserId
} from '@app/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Role } from '../model/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private store: Store<AppState>) {}

  loadAllRoles(): void {
    this.store.dispatch(new GetAllRoles());
  }

  loadRole(roleId: number): void {
    this.store.dispatch(new GetRole(roleId));
  }

  loadRolesByUserId(userId: number): void {
    this.store.dispatch(new GetRolesByUserId(userId));
  }

  loadRolesByCompanyId(companyId: number): void {
    this.store.dispatch(new GetRolesByCompanyId(companyId));
  }

  addRole(role: Role): void {
    this.store.dispatch(new AddRole(role));
  }

  deleteRole(roleId: number): void {
    this.store.dispatch(new DeleteRole(roleId));
  }

  patchRole(roleId: number, changes: Partial<Role>): void {
    this.store.dispatch(new UpdateRole({ id: roleId, changes }));
  }

  promoteUser(roleId: number, changes: Partial<Role>): void {
    this.store.dispatch(new PromoteUser({ id: roleId, changes }));
  }

  getRoleById(roleId: number): Observable<Role> {
    return this.store.select(selectRoleById(), { roleId });
  }

  getRolesByUserId(userId: number): Observable<Role[]> {
    return this.store.select(selectRolesByUserId(), { userId });
  }

  getRolesByCompanyId(companyId: number): Observable<Role[]> {
    return this.store.select(selectRolesByCompanyId(), { companyId });
  }

  getRoleByUserIdAndCompanyId(userId: number, companyId: number): Observable<Role> {
    return this.store.select(selectRoleByUserIdAndCompanyId(), { userId, companyId });
  }

  getCurrentUserRoles(): Observable<Role[]> {
    return this.store.select(selectCurrentUserRoles);
  }

  getAllRolesFromCurrentUserCompanies(): Observable<Role[]> {
    return this.store.select(selectAllRolesFromCurrentUserCompanies);
  }

  getPendingRolesFromCompaniesWhereCurrentUserIsAdmin(): Observable<Role[]> {
    return this.store.select(selectPendingRolesFromCompaniesWhereCurrentUserIsAdmin);
  }
}
