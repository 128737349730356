import { EstablishmentUnit } from '@app/core/model/establishment-unit.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EstablishmentUnitActions, EstablishmentUnitActionTypes } from '../actions/establishment-unit.actions';

export interface EstablishmentUnitState extends EntityState<EstablishmentUnit> {
  // additional entity state properties
}

export const establishmentUnitAdapter: EntityAdapter<EstablishmentUnit> = createEntityAdapter<EstablishmentUnit>();

const initialState: EstablishmentUnitState = establishmentUnitAdapter.getInitialState({
  // additional entity state properties
});

export function establishmentUnitReducer(state = initialState, action: EstablishmentUnitActions): EstablishmentUnitState {
  switch (action.type) {
    case EstablishmentUnitActionTypes.GET_ALL_ESTABLISHMENT_UNITS_SUCCESS: {
      return establishmentUnitAdapter.addAll(action.establishmentUnits, state);
    }
    case EstablishmentUnitActionTypes.GET_ESTABLISHMENT_UNIT_SUCCESS: {
      return establishmentUnitAdapter.addOne(action.establishmentUnit, state);
    }
    case EstablishmentUnitActionTypes.UPDATE_ESTABLISHMENT_UNIT_SUCCESS: {
      return establishmentUnitAdapter.updateOne(action.update, state);
    }
    case EstablishmentUnitActionTypes.DELETE_ESTABLISHMENT_UNIT_SUCCESS: {
      return establishmentUnitAdapter.removeOne(action.unitID, state);
    }
    default: {
      return state;
    }
  }
}
