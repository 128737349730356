import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/core/services';

@Component({
  templateUrl: './change-password-dialog.container.html',
  styleUrls: ['./change-password-dialog.container.scss']
})
export class ChangePasswordDialogContainerComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ChangePasswordDialogContainerComponent>, private userService: UserService) {}

  ngOnInit() {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  resetPassword(data): void {
    this.userService.changePassword(data);
    this.dialogRef.close();
  }
}
