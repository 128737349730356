import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { convertToPartial } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstablishmentUnit } from '../model/establishment-unit.model';
import { ApiAddress, ApiAddressService } from './api-address.service';
import { ApiCompany } from './api-company.service';
import { ApiSite } from './api-site.service';

export interface ApiEstablishmentUnit {
  id?: number;
  number?: number;
  nameFR?: string;
  nameNL?: string;
  principal?: boolean;
  actif?: boolean;
  company_id?: number;
  company?: ApiCompany;
  site_id?: number;
  site?: ApiSite;
  address_id?: number;
  address?: ApiAddress;
}

@Injectable({
  providedIn: 'root'
})
export class ApiEstablishmentUnitService {
  constructor(private http: HttpClient, private apiAddressService: ApiAddressService) {}

  getAllEstablishmentUnits(): Observable<EstablishmentUnit[]> {
    return this.http.get<ApiEstablishmentUnit[]>(`${environment.apiUrl}/businessunits`).pipe(
      map(apiEstablishmentUnits => {
        return apiEstablishmentUnits.map(apiEstablishmentUnit => this.establishmentUnitFromApiToModel(apiEstablishmentUnit));
      })
    );
  }

  getEstablishmentUnit(id: number): Observable<EstablishmentUnit> {
    return this.http.get<ApiEstablishmentUnit>(`${environment.apiUrl}/businessunits/${id}`).pipe(
      map(apiEstablishmentUnit => {
        return this.establishmentUnitFromApiToModel(apiEstablishmentUnit);
      })
    );
  }

  updateEstablishmentUnit(update: Update<EstablishmentUnit>): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/businessunits/${update.id}`, this.establishmentUnitFromModelToApi(update.changes));
  }

  deleteEstablishmentUnit(unitID: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/businessunits/${unitID}`);
  }

  establishmentUnitFromApiToModel(apiEstablishmentUnit: ApiEstablishmentUnit): EstablishmentUnit {
    const address = this.apiAddressService.addressFromApiToModel(apiEstablishmentUnit.address);

    return {
      id: apiEstablishmentUnit.id,
      establishmentNumber: apiEstablishmentUnit.number,
      nameFR: apiEstablishmentUnit.nameFR,
      nameNL: apiEstablishmentUnit.nameNL,
      isMainEstablishmentUnit: apiEstablishmentUnit.principal,
      isActive: apiEstablishmentUnit.actif,
      companyId: apiEstablishmentUnit.company ? apiEstablishmentUnit.company.id : apiEstablishmentUnit.company_id,
      siteId: apiEstablishmentUnit.site ? apiEstablishmentUnit.site.id : apiEstablishmentUnit.site_id,
      address
    };
  }

  establishmentUnitFromModelToApi(establishmentUnit: Partial<EstablishmentUnit>): Partial<ApiEstablishmentUnit> {
    const address = establishmentUnit.address ? this.apiAddressService.addressFromModelToApi(establishmentUnit.address) : undefined;

    return convertToPartial({
      id: establishmentUnit.id,
      number: establishmentUnit.establishmentNumber,
      name_FR: establishmentUnit.nameFR,
      name_NL: establishmentUnit.nameNL,
      principal: establishmentUnit.isMainEstablishmentUnit,
      actif: establishmentUnit.isActive,
      company_id: establishmentUnit.companyId,
      site_id: establishmentUnit.siteId,
      address
    } as ApiEstablishmentUnit);
  }
}
