export class Role {
  constructor(public id: number, public userId: number, public companyId: number, public status: RoleStatus) {}
}

export enum RoleStatus {
  Admin = 'Admin',
  Affiliated = 'Affiliated',
  Pending = 'Pending',
  Refused = 'Refused'
}
