import { CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';
import { AnswerOption } from './answer-option.model';

export class Question {
  constructor();
  constructor(
    id: number,
    nameTranslationKey: string,
    description: string,
    unitTranslationKey: string,
    order: number,
    fieldType: CUSTOM_FORM_FIELD_TYPE | SPECIFIC_FIELD_TYPE,
    hasSmallInputField: boolean,
    isInline: boolean,
    required: boolean,
    answerOptions?: AnswerOption[],
    parentQuestionId?: number,
    settings?: QuestionSettings
  );
  constructor(
    public id?: number,
    public nameTranslationKey?: string,
    public description?: string,
    public unitTranslationKey?: string,
    public order?: number,
    public fieldType?: CUSTOM_FORM_FIELD_TYPE | SPECIFIC_FIELD_TYPE,
    public hasSmallInputField?: boolean,
    public isInline?: boolean,
    public required?: boolean,
    public answerOptions?: AnswerOption[],
    public parentQuestionId?: number,
    public settings?: QuestionSettings
  ) {}
}

export enum SPECIFIC_FIELD_TYPE {
  Table = 'table',
  Array = 'array'
}

export class QuestionSettings {
  columns?: string[];
  columnsTypes?: CUSTOM_FORM_FIELD_TYPE[];
  lines?: string[];
  visibleIf?: QuestionSettingsCondition[];
  calculatedValue?: QuestionSettingsCalculation;
  min?: number;
  max?: number;
  optionalColumns?: string[];
  columnsSettings?: TableQuestionColumnSettings[];
  cellsSettings?: TableQuestionCellSettings[];
  disabledColumns?: string[];
  lineColors?: string[]; // TODO: Use this attribute
  errorIf?: QuestionSettingsError[];
  warningIf?: QuestionSettingsWarning[];
  isTotalHidden?: boolean;
}

export interface QuestionSettingsCondition {
  questionName: string;
  column?: string;
  line?: string;
  expectedValue: string;
  expectedValueQuestion?: string;
  expectedCalculatedValue?: QuestionSettingsCalculation;
  operationType: QuestionSettingsConditionOperationType;
  mandatory: boolean;
}

export enum QuestionSettingsConditionOperationType {
  GREATER_THAN = 'GREATER_THAN',
  LOWER_THAN = 'LOWER_THAN',
  GREATER_THAN_OR_EQUALS_TO = 'GREATER_THAN_OR_EQUALS_TO',
  LOWER_THAN_OR_EQUALS_TO = 'LOWER_THAN_OR_EQUALS_TO',
  EQUALS_TO = 'EQUALS_TO',
  NOT_EQUALS_TO = 'NOT_EQUALS_TO',
  IS_NULL = 'IS_NULL',
  IS_NOT_NULL = 'IS_NOT_NULL'
}

export interface QuestionSettingsCalculation {
  questionsNames: string[];
  questionCells: TableQuestionCell[];
  operationType: QuestionSettingsCalculationOperationType;
  operationSpecificNumber?: number;
}

export enum QuestionSettingsCalculationOperationType {
  SUM = 'SUM',
  SUM_PLUS_MARGIN = 'SUM_PLUS_MARGIN',
  SUM_DIVIDED_BY_SPECIFIC_NUMBER = 'SUM_DIVIDED_BY_SPECIFIC_NUMBER',
  SUM_MULTIPLED_BY_SPECIFIC_NUMBER = 'SUM_MULTIPLED_BY_SPECIFIC_NUMBER'
}

export interface QuestionSettingsError {
  errorTranslationKey: string;
  conditions: QuestionSettingsCondition[];
}

export interface QuestionSettingsWarning {
  warningTranslationKey: string;
  conditions: QuestionSettingsCondition[];
}

export interface TableQuestionColumnSettings {
  column: string;
  answerOptions?: AnswerOption[];
}

export interface TableQuestionCellSettings {
  column: string;
  line: string;
  visibleIf?: QuestionSettingsCondition[];
  answerOptions?: AnswerOption[];
}

export interface TableQuestionCell {
  questionName: string;
  column: string;
  line: string;
}
