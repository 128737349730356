import { User } from '@app/core/model';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userAdapter, UserState } from '../reducers';
import { selectAuthCurrentUserId } from './auth.selectors';
import { selectAllRolesFromCurrentUserCompanies } from './role.selectors';

export const selectUserState = createFeatureSelector<UserState>('users');

const { selectIds, selectEntities, selectAll, selectTotal } = userAdapter.getSelectors();

export const selectUserIds = createSelector(
  selectUserState,
  selectIds
);

export const selectUserEntities = createSelector(
  selectUserState,
  selectEntities
);

export const selectAllUsers = createSelector(
  selectUserState,
  selectAll
);

export const selectUserTotal = createSelector(
  selectUserState,
  selectTotal
);

export const selectUserById = () =>
  createSelector(
    selectUserEntities,
    (userEntities: Dictionary<User>, props: { userId: number }) => userEntities[props.userId]
  );

export const selectCurrentUser = createSelector(
  selectUserEntities,
  selectAuthCurrentUserId,
  (userEntities, userId) => userEntities[userId]
);

export const selectAllUsersFromCurrentUserCompanies = createSelector(
  selectAllUsers,
  selectAllRolesFromCurrentUserCompanies,
  (users, allRolesFromCurrentUserCompanies) => users.filter(user => allRolesFromCurrentUserCompanies.find(role => role.userId === user.id))
);
