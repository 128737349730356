import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AddSite, CopySite, DeleteSite, GetAllSites, GetSite, UpdateSite } from '@app/store/actions';
import { AppState } from '@app/store/app.states';
import { selectAllSites, selectCurrentUserSites, selectSiteById } from '@app/store/selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EstablishmentUnit, UserType } from '../model';
import { Site } from '../model/site.model';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class SiteService {
  constructor(private store: Store<AppState>, private router: Router, private http: HttpClient) {}

  loadAllSites(): void {
    this.store.dispatch(new GetAllSites());
  }

  loadSite(siteId: number): void {
    this.store.dispatch(new GetSite(siteId));
  }

  addSite(site: Site, establishmentUnits: Partial<EstablishmentUnit>[]): void {
    this.store.dispatch(new AddSite(site, establishmentUnits));
  }

  deleteSite(siteId: number): void {
    this.store.dispatch(new DeleteSite(siteId));
  }

  copySite(siteId: number): void {
    this.store.dispatch(new CopySite(siteId));
  }

  patchSite(siteId: number, changes: Partial<Site>, establishmentUnits: Partial<EstablishmentUnit>[]): void {
    this.store.dispatch(new UpdateSite({ id: siteId, changes }, establishmentUnits));
  }

  getAllSites(): Observable<Site[]> {
    return this.store.select(selectAllSites);
  }

  getSiteById(siteId: number): Observable<Site> {
    return this.store.select(selectSiteById(), { siteId });
  }

  getCurrentUserSites(): Observable<Site[]> {
    return this.store.select(selectCurrentUserSites);
  }

  downloadPDF(siteId: number, language: 'FR' | 'NL'): Observable<any> {
    return this.http.post(`${environment.apiUrl}/report/${siteId}/${language}`, null, { responseType: 'blob' }).pipe(
      tap(responseBlob => {
        saveAs(responseBlob, `PDE-report-site-${siteId}.pdf`);
      })
    );
  }

  navigateToSitesAccordingToCurrentUserType(currentUserType: UserType): void {
    switch (currentUserType) {
      case UserType.Basic:
        this.navigateToSites();
        break;
      case UserType.AdminBE:
        this.navigateToSitesAdminBE();
        break;
      default:
        this.navigateToSites();
        break;
    }
  }

  navigateToSites(): void {
    this.router.navigate([`/sites`]);
  }

  navigateToSitesAdminBE(): void {
    this.router.navigate([`/sites/admin`]);
  }

  navigateToNewSite(): void {
    this.router.navigate([`/sites/new-site`]);
  }

  navigateToSiteDetails(siteId: number): void {
    this.router.navigate([`/sites/${siteId}`]);
  }
}
