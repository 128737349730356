<div fxLayout="column" fxLayoutGap="25px">
  <div fxLayout="row" fxLayoutGap="20px">
    <img src="assets/images/user-name-icon.svg" class="user-name-icon" />

    <h4 class="user-name-text primary-color">{{ userToString(user) }}</h4>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <img src="assets/images/mail-icon.svg" class="mail-icon" />

    <span>{{ user.emailAddress }}</span>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <img src="assets/images/phone-icon.svg" class="phone-icon" />

    <span>{{ user.phoneNumber }}</span>
  </div>
</div>
