import { Language } from './user.model';

export class RegistrationData {
  constructor(
    public lastName: string,
    public firstName: string,
    public emailAddress: string,
    public phoneNumber: string,
    public mobileNumber: string,
    public profession: string,
    public language: Language,
    public mobilityContactCommunication: boolean,
    public enews: boolean,
    public distributionLetter: boolean,
    public password: string
  ) {}
}
