import { Form } from '@app/core/model';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { formAdapter, FormState } from '../reducers';

export const selectFormState = createFeatureSelector<FormState>('forms');

const { selectIds, selectEntities, selectAll, selectTotal } = formAdapter.getSelectors();

export const selectFormIds = createSelector(
  selectFormState,
  selectIds
);

export const selectFormEntities = createSelector(
  selectFormState,
  selectEntities
);

export const selectAllForms = createSelector(
  selectFormState,
  selectAll
);

export const selectFormTotal = createSelector(
  selectFormState,
  selectTotal
);

export const selectFormById = () =>
  createSelector(
    selectFormEntities,
    (entities: Dictionary<Form>, props: { formId: number }) => entities[props.formId]
  );
