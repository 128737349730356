import { EstablishmentUnit } from '@app/core/model';
import { Site } from '@app/core/model/site.model';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

export enum SiteActionTypes {
  GET_ALL_SITES = '[Site] Get All Sites',
  GET_ALL_SITES_SUCCESS = '[Site] Get All Sites Success',
  GET_ALL_SITES_FAILURE = '[Site] Get All Sites Failure',
  GET_SITE = '[Site] Get Site',
  GET_SITE_SUCCESS = '[Site] Get Site Success',
  GET_SITE_FAILURE = '[Site] Get Site Failure',
  ADD_SITE = '[Site] Add Site',
  ADD_SITE_SUCCESS = '[Site] Add Site Success',
  ADD_SITE_FAILURE = '[Site] Add Site Failure',
  UPDATE_SITE = '[Site] Update Site',
  UPDATE_SITE_SUCCESS = '[Site] Update Site Success',
  UPDATE_SITE_FAILURE = '[Site] Update Site Failure',
  DELETE_SITE = '[Site] Delete Site',
  DELETE_SITE_SUCCESS = '[Site] Delete Site Success',
  DELETE_SITE_FAILURE = '[Site] Delete Site Failure',
  COPY_SITE = '[Site] COPY Site',
  COPY_SITE_SUCCESS = '[Site] COPY Site Success',
  COPY_SITE_FAILURE = '[Site] COPY Site Failure'
}

export class GetAllSites implements Action {
  readonly type = SiteActionTypes.GET_ALL_SITES;
}

export class GetAllSitesSuccess implements Action {
  readonly type = SiteActionTypes.GET_ALL_SITES_SUCCESS;
  constructor(public sites: Site[]) {}
}

export class GetAllSitesFailure implements Action {
  readonly type = SiteActionTypes.GET_ALL_SITES_FAILURE;
}

export class GetSite implements Action {
  readonly type = SiteActionTypes.GET_SITE;
  constructor(public siteId: number) {}
}

export class GetSiteSuccess implements Action {
  readonly type = SiteActionTypes.GET_SITE_SUCCESS;
  constructor(public site: Site) {}
}

export class GetSiteFailure implements Action {
  readonly type = SiteActionTypes.GET_SITE_FAILURE;
}

export class AddSite implements Action {
  readonly type = SiteActionTypes.ADD_SITE;
  constructor(public site: Site, public establishmentUnits: Partial<EstablishmentUnit>[]) {}
}

export class AddSiteSuccess implements Action {
  readonly type = SiteActionTypes.ADD_SITE_SUCCESS;
  constructor(public site: Site) {}
}

export class AddSiteFailure implements Action {
  readonly type = SiteActionTypes.ADD_SITE_FAILURE;
}

export class UpdateSite implements Action {
  readonly type = SiteActionTypes.UPDATE_SITE;
  constructor(public update: Update<Site>, public establishmentUnits: Partial<EstablishmentUnit>[]) {}
}

export class UpdateSiteSuccess implements Action {
  readonly type = SiteActionTypes.UPDATE_SITE_SUCCESS;
  constructor(public update: Update<Site>) {}
}

export class UpdateSiteFailure implements Action {
  readonly type = SiteActionTypes.UPDATE_SITE_FAILURE;
}

export class DeleteSite implements Action {
  readonly type = SiteActionTypes.DELETE_SITE;
  constructor(public siteId: number) {}
}

export class DeleteSiteSuccess implements Action {
  readonly type = SiteActionTypes.DELETE_SITE_SUCCESS;
  constructor(public siteId: number) {}
}

export class DeleteSiteFailure implements Action {
  readonly type = SiteActionTypes.DELETE_SITE_FAILURE;
}

export class CopySite implements Action {
  readonly type = SiteActionTypes.COPY_SITE;
  constructor(public siteId: number) {}
}

export class CopySiteSuccess implements Action {
  readonly type = SiteActionTypes.COPY_SITE_SUCCESS;
}

export class CopySiteFailure implements Action {
  readonly type = SiteActionTypes.COPY_SITE_FAILURE;
}

export type SiteActions =
  | GetAllSites
  | GetAllSitesSuccess
  | GetAllSitesFailure
  | GetSite
  | GetSiteSuccess
  | GetSiteFailure
  | AddSite
  | AddSiteSuccess
  | AddSiteFailure
  | UpdateSite
  | UpdateSiteSuccess
  | UpdateSiteFailure
  | DeleteSite
  | DeleteSiteSuccess
  | DeleteSiteFailure
  | CopySite
  | CopySiteFailure
  | CopySiteSuccess;
