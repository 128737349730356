<mat-toolbar class="footer-toolbar" fxLayout="row" fxLayoutAlign="center stretch">
  <div class="responsive-width" fxLayout="row" fxLayoutAlign="space-between center">
    <img
      [src]="isLanguageNL ? 'assets/images/logo-bruxelles-mobilite-NL.svg' : 'assets/images/logo-bruxelles-mobilite.svg'"
      class="logo-bruxelles-mobilite"
    />

    <!-- TODO: Remove logo-bruxelles-NL class when the SVG file will be of better quality -->
    <img
      [src]="isLanguageNL ? 'assets/images/logo-bruxelles-NL.svg' : 'assets/images/logo-bruxelles.svg'"
      class="logo-bruxelles"
      [ngClass]="{ 'logo-bruxelles-NL': isLanguageNL }"
    />

    <button mat-button color="primary" (click)="onLegalNoticesButtonPressed()">
      <mat-icon>policy</mat-icon>
      {{ 'i18n.FooterComponent.legal-notices' | translate }}
    </button>

    <button mat-button color="primary" (click)="onFaqButtonPressed()">
      <mat-icon>help_outline</mat-icon>
      {{ 'i18n.FooterComponent.faq' | translate }}
    </button>

    <button mat-button color="primary" (click)="onHelpButtonPressed()">
      <mat-icon>mail_outline</mat-icon>
      {{ 'i18n.FooterComponent.contact' | translate }}
    </button>
  </div>
</mat-toolbar>
