import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    loadChildren: () => import('@app/modules/homepage/homepage.module').then(mod => mod.HomepageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('@app/modules/auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: 'password',
    loadChildren: () => import('@app/modules/password/password.module').then(mod => mod.PasswordModule)
  },
  {
    path: 'account',
    canLoad: [AuthGuard],
    loadChildren: () => import('@app/modules/my-account/my-account.module').then(mod => mod.MyAccountModule)
  },
  {
    path: 'sites',
    canLoad: [AuthGuard],
    loadChildren: () => import('@app/modules/my-forms/my-forms.module').then(mod => mod.MyFormsModule)
  },
  {
    path: 'companies',
    canLoad: [AuthGuard],
    loadChildren: () => import('@app/modules/companies/companies.module').then(mod => mod.CompaniesModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('@app/modules/faq/faq.module').then(mod => mod.FaqModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
