<div
  [ngClass]="{ 'custom-form-field-without-hint-padding': settings.removeHintPadding }"
  class="custom-form-field-container"
  [ngSwitch]="settings.type"
  fxLayout="row wrap"
  fxLayoutGap="10px grid"
  fxFlexFill
>
  <ng-container *ngSwitchCase="TYPE_ENUM.TextInput">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <input
        [ngClass]="{ 'readonly-input': isReadOnly }"
        matInput
        type="text"
        [placeholder]="settings.placeholder | translate"
        [autocomplete]="settings.autocomplete"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
        [readonly]="isReadOnly"
      />
      <span *ngIf="settings.unit" matSuffix>{{ settings.unit | translate }}</span>
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.EmailInput">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <input
        [ngClass]="{ 'readonly-input': isReadOnly }"
        matInput
        type="email"
        [placeholder]="settings.placeholder | translate"
        [autocomplete]="settings.autocomplete"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
        [readonly]="isReadOnly"
      />
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.PasswordInput">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <input
        [ngClass]="{ 'readonly-input': isReadOnly }"
        matInput
        type="password"
        [placeholder]="settings.placeholder | translate"
        [autocomplete]="settings.autocomplete"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
        [readonly]="isReadOnly"
      />
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.Integer">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>
    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <input
        [ngClass]="{ 'readonly-input': isReadOnly }"
        matInput
        type="number"
        [placeholder]="settings.placeholder | translate"
        [autocomplete]="settings.autocomplete"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
        [min]="numberInputMin"
        [max]="numberInputMax"
        [readonly]="isReadOnly"
      />
      <span *ngIf="settings.unit" matSuffix>{{ settings.unit | translate }}</span>
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.Double">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>
    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <input
        [ngClass]="{ 'readonly-input': isReadOnly }"
        matInput
        type="number"
        [placeholder]="settings.placeholder | translate"
        [autocomplete]="settings.autocomplete"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
        [min]="numberInputMin"
        [max]="numberInputMax"
        [readonly]="isReadOnly"
      />
      <span *ngIf="settings.unit" matSuffix>{{ settings.unit | translate }}</span>
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.Percentage">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>
    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <input
        [ngClass]="{ 'readonly-input': isReadOnly }"
        matInput
        type="number"
        [placeholder]="settings.placeholder | translate"
        [autocomplete]="settings.autocomplete"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
        [min]="numberInputMin"
        [max]="numberInputMax"
        [readonly]="isReadOnly"
      />
      <span matSuffix>%</span>
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.TextArea">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <textarea
        matInput
        matTextareaAutosize
        [matAutosizeMinRows]="textAreaAutosizeMinRows"
        [matAutosizeMaxRows]="textAreaAutosizeMaxRows"
        [placeholder]="settings.placeholder | translate"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
      ></textarea>
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.Select">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <mat-select
        [placeholder]="settings.placeholder | translate"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
      >
        <mat-option *ngFor="let option of settings.options" [value]="option.value">
          <span innerHtml="{{ option.text | translate }}"></span>
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.Radio">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-radio-group
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input custom-radio-group"
      aria-label="Select an option"
      [formControl]="settings.control"
      [required]="settings.required"
      fxLayout="column"
      fxLayoutGap="15px"
    >
      <mat-radio-button *ngFor="let option of settings.options" [value]="option.value">
        <span innerHtml="{{ option.text | translate }}"></span>
      </mat-radio-button>
    </mat-radio-group>

    <!-- TODO: Show error message -->
    <!-- TODO: Show hint message -->
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.Checkbox">
    <div class="custom-checkbox-container" fxLayout="row" fxLayoutAlign="start center" fxFlexAlign="end">
      <mat-checkbox [ngClass]="{ 'mat-body-strong': settings.hasBoldLabel }" class="custom-checkbox" [formControl]="settings.control">
        <span innerHtml="{{ settings.label | translate }}"></span>
      </mat-checkbox>

      <mat-icon
        *ngIf="settings.description"
        class="description-icon"
        aria-hidden="false"
        aria-label="Description icon"
        [matTooltip]="settings.description | translate"
      >
        help_outline
      </mat-icon>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.MultiCheckbox">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <section
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input custom-radio-group"
      aria-label="Select an option"
      [formGroup]="multiCheckboxFormGroup"
      fxLayout="column"
      fxLayoutGap="15px"
    >
      <mat-checkbox *ngFor="let option of settings.options" [formControlName]="option.value">
        <span innerHtml="{{ option.text | translate }}"></span>
      </mat-checkbox>
    </section>

    <!-- TODO: Show error message -->
    <!-- TODO: Show hint message -->
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.DateInput">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-form-field
      [ngClass]="{ 'inline-input': settings.isInline }"
      class="custom-input"
      [appearance]="appearance"
      [floatLabel]="floatLabel"
      hideRequiredMarker
    >
      <input
        [ngClass]="{ 'readonly-input': isReadOnly }"
        matInput
        [matDatepicker]="datepicker"
        [max]="maxDate"
        [placeholder]="settings.placeholder | translate"
        [required]="settings.required"
        [formControl]="settings.control"
        [errorStateMatcher]="errorStateMatcher"
        [readonly]="isReadOnly"
      />
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
      <mat-hint *ngIf="getHintMessage()">{{ getHintMessage() | translate }}</mat-hint>
      <mat-error *ngIf="settings.control?.invalid">{{ getErrorMessage() | translate }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.Slider">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-slider
      [ngClass]="{ 'inline-input': settings.isInline, 'slider-with-null-value': isFormControlValueEmpty() }"
      class="custom-input custom-slider"
      [formControl]="settings.control"
      [min]="settings.min"
      [max]="settings.max"
      (click)="sliderOnClick(settings.control)"
      step="1"
      thumbLabel
      tickInterval="1"
    ></mat-slider>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.File">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <div [ngClass]="{ 'inline-input': settings.isInline }" class="custom-input">
      <input #fileInput hidden type="file" [required]="settings.required" (change)="uploadFile($event)" [accept]="getAcceptedFileTypes()" />

      <div *ngIf="isImportFileButtonVisible">
        <button
          class="file-input-button"
          mat-flat-button
          color="primary"
          (click)="fileInput.click()"
          [disabled]="settings.control?.disabled"
          fxFlex
        >
          <mat-icon>cloud_upload</mat-icon>
          <span *ngIf="!settings.isSmallFileInputButton">
            {{ 'i18n.CustomFormFieldComponent.file-input-button-text' | translate }}
          </span>
        </button>
      </div>

      <mat-chip-list *ngIf="isFileNamesListNotEmpty()">
        <mat-chip
          *ngFor="let fileName of fileNamesList"
          class="uploaded-file-chip"
          [matTooltip]="fileName"
          (click)="downloadFile(fileName)"
          (removed)="deleteFile(fileName)"
        >
          <span class="uploaded-file-chip-text">{{ fileName }}</span>
          <mat-icon *ngIf="!settings.control?.disabled" matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>

      <!-- TODO: Show if required or not -->
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="TYPE_ENUM.YesNo">
    <span
      *ngIf="settings.label"
      [ngClass]="{ 'inline-label': settings.isInline, 'mat-body-strong': settings.hasBoldLabel }"
      class="custom-label"
      innerHtml="{{ settings.label | translate }}"
    >
    </span>

    <mat-icon
      *ngIf="settings.description"
      class="description-icon"
      aria-hidden="false"
      aria-label="Description icon"
      [matTooltip]="settings.description | translate"
    >
      help_outline
    </mat-icon>

    <mat-radio-group
      class="custom-input custom-radio-group"
      aria-label="Select an option"
      [formControl]="settings.control"
      [required]="settings.required"
      fxLayout="row"
      fxLayoutGap="15px"
      fxFlex="1 0 auto"
    >
      <mat-radio-button value="yes">
        <span innerHtml="{{ 'i18n.AnswerOption.nameTranslationKey.yes' | translate }}"></span>
      </mat-radio-button>
      <mat-radio-button value="no">
        <span innerHtml="{{ 'i18n.AnswerOption.nameTranslationKey.no' | translate }}"></span>
      </mat-radio-button>
    </mat-radio-group>

    <!-- TODO: Show error message -->
    <!-- TODO: Show hint message -->
  </ng-container>
</div>
