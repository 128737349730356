import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role, RoleStatus } from '@app/core/model/role.model';
import { convertToPartial } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCompany } from './api-company.service';
import { ApiUser } from './api-user.service';

export interface ApiRole {
  id?: number;
  user_id?: number;
  user?: ApiUser;
  company_id?: number;
  company?: ApiCompany;
  request_link?: ApiRoleStatus;
}

export enum ApiRoleStatus {
  AdminE = 'AdminE',
  Affiliated = 'Affiliated',
  Waiting = 'Waiting',
  Refused = 'Refused'
}

@Injectable({
  providedIn: 'root'
})
export class ApiRoleService {
  constructor(private http: HttpClient) {}

  getAllRoles(): Observable<Role[]> {
    return this.http.get<ApiRole[]>(`${environment.apiUrl}/roles`).pipe(
      map(apiRoles => {
        return apiRoles.map(apiRole => this.roleFromApiToModel(apiRole));
      })
    );
  }

  getRole(id: number): Observable<Role> {
    return this.http.get<ApiRole>(`${environment.apiUrl}/roles/${id}`).pipe(
      map(apiRole => {
        return this.roleFromApiToModel(apiRole);
      })
    );
  }

  getRolesByUserId(userId: number): Observable<Role[]> {
    const params = new HttpParams().set('userId', userId.toString());

    return this.http.get<ApiRole[]>(`${environment.apiUrl}/roles`, { params }).pipe(
      map(apiRoles => {
        return apiRoles.map(apiRole => this.roleFromApiToModel(apiRole));
      })
    );
  }

  getRolesByCompanyId(companyId: number): Observable<Role[]> {
    const params = new HttpParams().set('companyId', companyId.toString());

    return this.http.get<ApiRole[]>(`${environment.apiUrl}/roles`, { params }).pipe(
      map(apiRoles => {
        return apiRoles.map(apiRole => this.roleFromApiToModel(apiRole));
      })
    );
  }

  addRole(role: Role): Observable<Role> {
    return this.http.post<ApiRole>(`${environment.apiUrl}/roles`, this.roleFromModelToApi(role)).pipe(
      map(apiAddedRole => {
        return this.roleFromApiToModel(apiAddedRole);
      })
    );
  }

  promoteUser(data: any): Observable<Role> {
    return this.http.post<ApiRole>(`${environment.apiUrl}/roles/promoteUser`, data).pipe(
      map(response => {
        return this.roleFromApiToModel(data);
      })
    );
  }

  updateRole(update: Update<Role>): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/roles/${update.id}`, this.roleFromModelToApi(update.changes));
  }

  deleteRole(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/roles/${id}`);
  }

  roleFromApiToModel(apiRole: ApiRole): Role {
    const status = this.roleStatusFromApitoModel(apiRole.request_link);

    return {
      id: apiRole.id,
      userId: apiRole.user ? apiRole.user.id : apiRole.user_id,
      companyId: apiRole.company ? apiRole.company.id : apiRole.company_id,
      status
    };
  }

  roleFromModelToApi(role: Partial<Role>): Partial<ApiRole> {
    const status = role.status ? this.roleStatusFromModelToApi(role.status) : undefined;

    return convertToPartial({
      id: role.id,
      user_id: role.userId,
      company_id: role.companyId,
      request_link: status
    });
  }

  roleStatusFromApitoModel(apiStatus: ApiRoleStatus): RoleStatus {
    switch (apiStatus) {
      case ApiRoleStatus.AdminE:
        return RoleStatus.Admin;
      case ApiRoleStatus.Affiliated:
        return RoleStatus.Affiliated;
      case ApiRoleStatus.Refused:
        return RoleStatus.Refused;
      case ApiRoleStatus.Waiting:
        return RoleStatus.Pending;
      default:
        console.log(`apiStatus was not recognized: ${apiStatus}`);
        return null;
    }
  }

  roleStatusFromModelToApi(status: RoleStatus): ApiRoleStatus {
    switch (status) {
      case RoleStatus.Admin:
        return ApiRoleStatus.AdminE;
      case RoleStatus.Affiliated:
        return ApiRoleStatus.Affiliated;
      case RoleStatus.Pending:
        return ApiRoleStatus.Waiting;
      case RoleStatus.Refused:
        return ApiRoleStatus.Refused;
      default:
        console.log(`status was not recognized: ${status}`);
        return null;
    }
  }
}
