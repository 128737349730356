import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFormFieldComponent, LoadingComponent } from './components';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { MaterialComponentsModule } from './material-components.module';
import { EmailPipe } from './pipes/email.pipe';

@NgModule({
  declarations: [CustomFormFieldComponent, LoadingComponent, CustomTableComponent, ConfirmationDialogComponent, EmailPipe],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, MaterialComponentsModule, TranslateModule],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialComponentsModule,
    TranslateModule,
    CustomFormFieldComponent,
    LoadingComponent,
    CustomTableComponent,
    ConfirmationDialogComponent,
    EmailPipe
  ],
  entryComponents: [ConfirmationDialogComponent]
})
export class SharedModule {}
