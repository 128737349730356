<h3 mat-dialog-title>{{ 'i18n.AffiliationApplicationDialogComponent.title' | translate }}</h3>

<div mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="25px">
    <div>
      <form>
        <div fxLayout="row" fxLayoutGap="10px">
          <app-custom-form-field class="bce-search-form-field" [settings]="bceNumberFieldSettings" fxFlex></app-custom-form-field>

          <ng-container *ngIf="isApplicationLoading; else isNotLoadingTemplate">
            <mat-spinner [diameter]="45"></mat-spinner>
          </ng-container>
          <ng-template #isNotLoadingTemplate>
            <button mat-flat-button color="primary" type="submit" (click)="onAddButtonPressed()" [disabled]="isAddButtonDisabled()">
              <mat-icon>add_circle_outline</mat-icon>
              {{ 'i18n.Common.add' | translate }}
            </button>
          </ng-template>
        </div>
      </form>
    </div>

    <div>
      <h4>{{ 'i18n.AffiliationApplicationDialogComponent.applications-list-title' | translate }}</h4>

      <app-custom-table [settings]="applicationsTableSettings" (actionTriggered)="onActionTriggered($event)"></app-custom-table>
    </div>

    <div fxLayout="row" fxLayoutGap="10px">
      <button mat-stroked-button color="primary" (click)="onCancelButtonPressed()" fxFlex>
        {{ 'i18n.Common.cancel' | translate }}
      </button>

      <button mat-flat-button color="primary" (click)="onSendButtonPressed()" [disabled]="isSendButtonDisabled()" fxFlex>
        {{ 'i18n.AffiliationApplicationDialogComponent.send-applications' | translate }}
      </button>
    </div>
  </div>
</div>
