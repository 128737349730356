import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUserService } from '@app/core/api';
import { RegistrationData, User } from '@app/core/model';
import { AuthService, SnackbarService } from '@app/core/services';
import { SubSink } from 'subsink';

@Component({
  templateUrl: './register-old-user-container.component.html',
  styleUrls: ['./register-old-user-container.component.scss']
})
export class RegisterOldUserContainerComponent implements OnInit {
  token: string;
  user: User;
  isLoading = true;

  subSink = new SubSink();

  constructor(
    private route: ActivatedRoute,
    private apiUserService: ApiUserService,
    private authService: AuthService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');

    this.subSink.sink = this.apiUserService.getUserByToken(this.token).subscribe(
      user => (this.user = user),
      error => {
        this.snackbarService.openErrorWithTranslation('i18n.RegisterOldUserContainerComponent.get-user-by-token-error');
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  register(registrationData: RegistrationData): void {
    this.authService.registerOldUser(registrationData, this.token);
  }
}
