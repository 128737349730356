import { User } from '@app/core/model';
import { Action } from '@ngrx/store';

export enum UserActionTypes {
  GET_ALL_USERS = '[User] Get All Users',
  GET_ALL_ADMIN_USERS = '[User] Get All Admin Users',
  GET_ALL_USERS_SUCCESS = '[User] Get All Users Success',
  GET_ALL_USERS_FAILURE = '[User] Get All Users Failure',
  GET_USER = '[User] Get User',
  GET_USER_SUCCESS = '[User] Get User Success',
  GET_USER_FAILURE = '[User] Get User Failure',
  UPDATE_USER = '[User] Update User',
  UPDATE_USER_SUCCESS = '[User] Update User Success',
  UPDATE_USER_FAILURE = '[User] Update User Failure',
  CHANGE_USER_PASSWORD = '[User] change User password',
  CHANGE_USER_PASSWORD_SUCCESS = '[User] change User password Success',
  CHANGE_USER_PASSWORD_FAILURE = '[User] change User password Failure'
}

export class GetAllUsers implements Action {
  readonly type = UserActionTypes.GET_ALL_USERS;
}

export class GetAllAdminUsers implements Action {
  readonly type = UserActionTypes.GET_ALL_ADMIN_USERS;
}

export class GetAllUsersSuccess implements Action {
  readonly type = UserActionTypes.GET_ALL_USERS_SUCCESS;
  constructor(public payload: User[]) {}
}

export class GetAllUsersFailure implements Action {
  readonly type = UserActionTypes.GET_ALL_USERS_FAILURE;
}

export class GetUser implements Action {
  readonly type = UserActionTypes.GET_USER;
  constructor(public payload: number) {}
}

export class GetUserSuccess implements Action {
  readonly type = UserActionTypes.GET_USER_SUCCESS;
  constructor(public payload: User) {}
}

export class GetUserFailure implements Action {
  readonly type = UserActionTypes.GET_USER_FAILURE;
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UPDATE_USER;
  constructor(public payload: User) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UserActionTypes.UPDATE_USER_SUCCESS;
  constructor(public payload: User) {}
}

export class UpdateUserFailure implements Action {
  readonly type = UserActionTypes.UPDATE_USER_FAILURE;
}

export class ChangeUserPassword implements Action {
  readonly type = UserActionTypes.CHANGE_USER_PASSWORD;
  constructor(public payload: any) {}
}

export class ChangeUserPasswordSuccess implements Action {
  readonly type = UserActionTypes.CHANGE_USER_PASSWORD_SUCCESS;
}

export class ChangeUserPasswordFailure implements Action {
  readonly type = UserActionTypes.CHANGE_USER_PASSWORD_FAILURE;
}

export type UserActions =
  | GetAllUsers
  | GetAllAdminUsers
  | GetAllUsersSuccess
  | GetAllUsersFailure
  | GetUser
  | GetUserSuccess
  | GetUserFailure
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFailure
  | ChangeUserPassword
  | ChangeUserPasswordSuccess
  | ChangeUserPasswordFailure;
