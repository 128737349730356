<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
      <img src="assets/images/link-solid.svg" class="link-solid-icon" />
      <h4 class="my-affiliations-title primary-color">
        {{ 'i18n.MyAffiliationsComponent.title' | translate }}
      </h4>
    </div>

    <button mat-flat-button color="primary" (click)="onNewAffiliationApplicationButtonPressed()">
      {{ 'i18n.MyAffiliationsComponent.new-application' | translate }}
    </button>
  </div>

  <div
    *ngIf="hasPendingRolesFromCompaniesWhereCurrentUserIsAdmin"
    class="pending-message-container"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
      <mat-icon>error_outline</mat-icon>
      <span>
        {{ 'i18n.MyAffiliationsComponent.companies-with-pending-roles' | translate }}
      </span>
    </div>

    <ul>
      <li *ngFor="let company of companiesWhereCurrentUserHasPendingRolesToHandle">
        {{ isLanguageNL() ? company.nameNL : company.nameFR }}
      </li>
    </ul>
  </div>

  <app-custom-table [settings]="affiliationsTableSettings" (actionTriggered)="onActionTriggered($event)"></app-custom-table>
</div>
