import { createFeatureSelector, createSelector } from '@ngrx/store';
import { permissionAdapter, PermissionState } from '../reducers';
import { Permission } from '@app/core/model/permission.model';
import { AppState } from '@app/store/app.states';

// export const selectPermissionState = createFeatureSelector<PermissionState>('permission');

const selectPermissionState = (state: AppState) => state.permissions;

export const selectAllPermission = createSelector(
  selectPermissionState,
  (state: PermissionState) => state.permissions
);
