export class Conformity {
  constructor();
  constructor(
    id: number,
    type: ConformityType,
    status: ConformityStatus,
    BEComment: string,
    description: string,
    deadline: Date,
    proof: string
  );
  constructor(
    public id?: number,
    public type?: ConformityType,
    public status?: ConformityStatus,
    public BEComment?: string,
    public description?: string,
    public deadline?: Date,
    public proof?: string
  ) {}
}

export enum ConformityType {
  Contact = 'Contact',
  PDE_Info = 'Info_PDE',
  Awareness_Actions = 'Action_sensi',
  Multimodal_Access_Map = 'Plan_Access',
  Bicycle_Parking = 'Parking vélo',
  Ecoscore = 'Ecoscore',
  PicPol_And_Emergency_Plan = 'Plan_Picpol_Urgence',
  Public_Transport = 'TC'
}

export enum ConformityStatus {
  Planned = 'Planned',
  Compliant = 'Compliant',
  Non_Compliant = 'Non_Compliant',
  Not_Relevant = 'Not_Relevant',
  Derogation_Request = 'Derogation_Request'
}
