import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { FooterComponent, HeaderComponent } from './components';
import { LegalNoticesDialogComponent } from './components/legal-notices-dialog/legal-notices-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, LegalNoticesDialogComponent],
  imports: [SharedModule, PdfViewerModule],
  exports: [HeaderComponent, FooterComponent],
  entryComponents: [LegalNoticesDialogComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    this.throwIfAlreadyLoaded(parentModule);
  }

  private throwIfAlreadyLoaded(parentModule: any): void {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
    }
  }
}
