import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(public dialog: MatDialog) {}

  openConfirmationDialog(text?: string, color?: 'primary' | 'accent' | 'warn'): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '240px',
      data: { text, color } as ConfirmationDialogData
    });
  }
}
