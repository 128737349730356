export class Answer {
  constructor();
  constructor(
    public id?: number,
    public questionId?: number,
    public planId?: number,
    public value?: string,
    public userId?: number,
    public comment?: string,
    public status?: AnswerStatus,
    public version?: number
  ) {}
}

export enum AnswerStatus {
  Completed = 'Completed',
  ModifiedByAdminBE = 'ModifiedByAdminBE',
  ToCorrect = 'ToCorrect',
  Corrected = 'Corrected'
}
