<h3 mat-dialog-title>
  {{ 'i18n.ChangePasswordDialogComponent.title' | translate }}
</h3>

<div mat-dialog-content>
  <div *ngFor="let settings of fieldsSettings">
    <app-custom-form-field [settings]="settings"></app-custom-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="onCancelButtonPressed()" cdkFocusInitial fxFlex>
    {{ 'i18n.Common.cancel' | translate }}
  </button>

  <button mat-flat-button color="primary" (click)="onSubmitButtonPressed()" [disabled]="isSubmitButtonDisabled()" fxFlex>
    {{ 'i18n.Common.confirm' | translate }}
  </button>
</div>
