import { Answer, TravelPlan, TravelPlanStatus } from '@app/core/model';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { TravelPlanActions, TravelPlanActionTypes } from '../actions';

export interface TravelPlanState extends EntityState<TravelPlan> {
  // additional entity state properties
  isLoading: boolean;
  isGetTravelPlansLoading: boolean;
}

export const travelPlanAdapter: EntityAdapter<TravelPlan> = createEntityAdapter<TravelPlan>();

const initialState: TravelPlanState = travelPlanAdapter.getInitialState({
  // additional entity state properties
  isLoading: false,
  isGetTravelPlansLoading: false
});

export function travelPlanReducer(state = initialState, action: TravelPlanActions): TravelPlanState {
  switch (action.type) {
    case TravelPlanActionTypes.GET_ALL_TRAVEL_PLANS:
    case TravelPlanActionTypes.GET_TRAVEL_PLAN:
    case TravelPlanActionTypes.GET_TRAVEL_PLAN_BY_SITE_ID_AND_FORM_ID:
    case TravelPlanActionTypes.GET_TRAVEL_PLANS_BY_SITE_ID: {
      return { ...state, isGetTravelPlansLoading: true };
    }
    case TravelPlanActionTypes.GET_ALL_TRAVEL_PLANS_SUCCESS: {
      return travelPlanAdapter.addAll(action.travelPlans, { ...state, isGetTravelPlansLoading: false });
    }
    case TravelPlanActionTypes.GET_TRAVEL_PLAN_SUCCESS: {
      return travelPlanAdapter.upsertOne(action.travelPlan, { ...state, isGetTravelPlansLoading: false });
    }
    case TravelPlanActionTypes.GET_TRAVEL_PLANS_BY_SITE_ID_SUCCESS: {
      return travelPlanAdapter.upsertMany(action.travelPlans, { ...state, isGetTravelPlansLoading: false });
    }
    case TravelPlanActionTypes.GET_ALL_TRAVEL_PLANS_FAILURE:
    case TravelPlanActionTypes.GET_TRAVEL_PLAN_FAILURE:
    case TravelPlanActionTypes.GET_TRAVEL_PLANS_BY_SITE_ID_FAILURE: {
      return { ...state, isGetTravelPlansLoading: false };
    }

    case TravelPlanActionTypes.ADD_TRAVEL_PLAN: {
      return { ...state, isLoading: true };
    }
    case TravelPlanActionTypes.ADD_TRAVEL_PLAN_SUCCESS: {
      const travel: TravelPlan = action.travelPlan;
      return travelPlanAdapter.addOne(travel, { ...state, isLoading: false });
    }
    case TravelPlanActionTypes.ADD_TRAVEL_PLAN_FAILURE: {
      return { ...state, isLoading: false };
    }
    case TravelPlanActionTypes.UPDATE_TRAVEL_PLAN: {
      return { ...state, isLoading: true };
    }
    case TravelPlanActionTypes.UPDATE_TRAVEL_PLAN_SUCCESS: {
      const updatedAnswers: Answer[] = isNotNullNorUndefined(action.update.changes.answers)
        ? state.entities[action.update.id].answers.map(answer => {
            const updatedAnswer: Partial<Answer> = action.update.changes.answers.find(a => a.questionId === answer.questionId);
            return isNotNullNorUndefined(updatedAnswer) ? { ...answer, ...updatedAnswer } : answer;
          })
        : state.entities[action.update.id].answers;

      const newAnswers: Answer[] = isNotNullNorUndefined(action.update.changes.answers)
        ? action.update.changes.answers.filter(answer => {
            const existingAnswer: Answer = state.entities[action.update.id].answers.find(a => answer.questionId === a.questionId);
            return !isNotNullNorUndefined(existingAnswer);
          })
        : [];

      const update: Update<TravelPlan> = {
        id: action.update.id.toString(),
        changes: { ...action.update.changes, answers: [...updatedAnswers, ...newAnswers] } as Partial<TravelPlan>
      };

      return travelPlanAdapter.updateOne(update, { ...state, isLoading: false });
    }
    case TravelPlanActionTypes.UPDATE_TRAVEL_PLAN_FAILURE: {
      return { ...state, isLoading: false };
    }
    case TravelPlanActionTypes.ACCEPT_TRAVEL_PLAN: {
      return { ...state, isLoading: true };
    }
    case TravelPlanActionTypes.ACCEPT_TRAVEL_PLAN_SUCCESS: {
      const update: Update<TravelPlan> = {
        id: action.travelPlanId.toString(),
        changes: { status: TravelPlanStatus.Accepted } as Partial<TravelPlan>
      };

      return travelPlanAdapter.updateOne(update, { ...state, isLoading: false });
    }
    case TravelPlanActionTypes.ACCEPT_TRAVEL_PLAN_FAILURE: {
      return { ...state, isLoading: false };
    }
    case TravelPlanActionTypes.DELETE_TRAVEL_PLAN_SUCCESS: {
      return travelPlanAdapter.removeOne(action.id, state);
    }
    case TravelPlanActionTypes.UPDATE_TRAVEL_PLAN_IMPORT_WORKER_RATIO: {
      const update: Update<TravelPlan> = {
        id: action.travelPlanId.toString(),
        changes: { travRatio: action.ratio, travCount: action.trav } as Partial<TravelPlan>
      };

      return travelPlanAdapter.updateOne(update, { ...state, isLoading: false });
    }
    default: {
      return state;
    }
  }
}
