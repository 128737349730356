import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { convertToPartial, isNotNullNorUndefined } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstablishmentUnit } from '../model';
import { Site, SiteSnapshot, SiteStatus } from '../model/site.model';
import { ApiAddress, ApiAddressService } from './api-address.service';
import { ApiCompany, ApiCompanyService } from './api-company.service';
import { ApiEstablishmentUnit, ApiEstablishmentUnitService } from './api-establishment-unit.service';
import { ApiUser, ApiUserService } from './api-user.service';

export interface ApiSite {
  id: number;
  nameFR?: string;
  nameNL?: string;
  nameEN?: string;
  code?: string;
  status?: ApiSiteStatus;
  user_id?: number;
  user?: ApiUser;
  business_units?: Partial<ApiEstablishmentUnit>[];
  addresses?: ApiAddress[];
  person_contact?: ApiUser;
  companies?: ApiCompany[];
}

export enum ApiSiteStatus {
  Active = 'active',
  Inactive = 'inactive'
}

@Injectable({
  providedIn: 'root'
})
export class ApiSiteService {
  constructor(
    private http: HttpClient,
    private apiEstablishmentUnitService: ApiEstablishmentUnitService,
    private apiCompanyService: ApiCompanyService,
    private apiUserService: ApiUserService,
    private apiAddressService: ApiAddressService
  ) {}

  getAllSites(): Observable<Site[]> {
    return this.http.get<ApiSite[]>(`${environment.apiUrl}/sites`).pipe(
      map(apiSites => {
        return apiSites.map(apiSite => this.siteFromApiToModel(apiSite));
      })
    );
  }

  getSite(id: number): Observable<Site> {
    return this.http.get<ApiSite>(`${environment.apiUrl}/sites/${id}`).pipe(
      map(apiSite => {
        return this.siteFromApiToModel(apiSite);
      })
    );
  }

  getSiteSnapshot(siteId: number, formId: number): Observable<SiteSnapshot> {
    return this.http.get<ApiSite>(`${environment.apiUrl}/sites/${siteId}/snapshot/${formId}`).pipe(
      map(apiSite => {
        return {
          ...this.siteFromApiToModel(apiSite),
          contactUser: isNotNullNorUndefined(apiSite.person_contact)
            ? this.apiUserService.userFromApiToModel(apiSite.person_contact)
            : null,
          establishmentUnits: isNotNullNorUndefined(apiSite.business_units)
            ? apiSite.business_units.map(apiEstablishmentUnit =>
                this.apiEstablishmentUnitService.establishmentUnitFromApiToModel(apiEstablishmentUnit)
              )
            : [],
          companies: isNotNullNorUndefined(apiSite.companies)
            ? apiSite.companies.map(apiCompany => this.apiCompanyService.companyFromApiToModel(apiCompany))
            : []
        } as SiteSnapshot;
      })
    );
  }

  addSite(site: Site, establishmentUnits: Partial<EstablishmentUnit>[]): Observable<Site> {
    return this.http.post<ApiSite>(`${environment.apiUrl}/sites`, this.siteFromModelToApi(site, establishmentUnits)).pipe(
      map(apiAddedSite => {
        return this.siteFromApiToModel(apiAddedSite);
      })
    );
  }

  updateSite(update: Update<Site>, establishmentUnits: Partial<EstablishmentUnit>[]): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/sites/${update.id}`, this.siteFromModelToApi(update.changes, establishmentUnits));
  }

  deleteSite(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/sites/${id}`);
  }

  copySite(id: number): Observable<any> {
    return this.http.put(`${environment.apiUrl}/sites/${id}/versionize`, {});
  }

  siteFromApiToModel(apiSite: ApiSite): Site {
    const status = this.siteStatusFromApitoModel(apiSite.status);
    const addresses = isNotNullNorUndefined(apiSite.addresses)
      ? apiSite.addresses.map(apiAddress => this.apiAddressService.addressFromApiToModel(apiAddress))
      : [];

    return {
      id: apiSite.id,
      nameFR: apiSite.nameFR,
      nameNL: apiSite.nameNL,
      nameEN: apiSite.nameEN,
      code: apiSite.code,
      contactUserId: apiSite.user ? apiSite.user.id : apiSite.user_id,
      status,
      addresses
    };
  }

  siteFromModelToApi(site: Partial<Site>, establishmentUnits?: Partial<EstablishmentUnit>[]): Partial<ApiSite> {
    const status = site.status ? this.siteStatusFromModelToApi(site.status) : undefined;

    const businessUnits: Partial<ApiEstablishmentUnit>[] = isNotNullNorUndefined(establishmentUnits)
      ? establishmentUnits.map(establishmentUnit =>
          this.apiEstablishmentUnitService.establishmentUnitFromModelToApi({
            id: establishmentUnit.id,
            isMainEstablishmentUnit: establishmentUnit.isMainEstablishmentUnit ? establishmentUnit.isMainEstablishmentUnit : false
          })
        )
      : null;

    return convertToPartial({
      id: site.id,
      nameFR: site.nameFR,
      nameNL: site.nameNL,
      nameEN: site.nameEN,
      code: site.code,
      user_id: site.contactUserId,
      status,
      business_units: businessUnits,
      addresses: site.addresses
    });
  }

  siteStatusFromApitoModel(apiSiteStatus: ApiSiteStatus): SiteStatus {
    switch (apiSiteStatus) {
      case ApiSiteStatus.Active:
        return SiteStatus.Active;
      case ApiSiteStatus.Inactive:
        return SiteStatus.Inactive;
      default:
        console.log(`apiSiteStatus was not recognized: ${apiSiteStatus}`);
        return null;
    }
  }

  siteStatusFromModelToApi(siteStatus: SiteStatus): ApiSiteStatus {
    switch (siteStatus) {
      case SiteStatus.Active:
        return ApiSiteStatus.Active;
      case SiteStatus.Inactive:
        return ApiSiteStatus.Inactive;
      default:
        console.log(`siteStatus was not recognized: ${siteStatus}`);
        return null;
    }
  }
}
