import {
  authReducer,
  AuthState,
  companyReducer,
  CompanyState,
  establishmentUnitReducer,
  EstablishmentUnitState,
  formReducer,
  FormState,
  permissionReducer,
  PermissionState,
  roleReducer,
  RoleState,
  siteReducer,
  SiteState,
  surveyReducer,
  SurveyState,
  travelPlanReducer,
  TravelPlanState,
  userReducer,
  UserState
} from './reducers';

export interface AppState {
  auth: AuthState;
  users: UserState;
  companies: CompanyState;
  roles: RoleState;
  sites: SiteState;
  establishmentUnits: EstablishmentUnitState;
  forms: FormState;
  travelPlans: TravelPlanState;
  surveys: SurveyState;
  permissions: PermissionState;
}

export const reducers = {
  auth: authReducer,
  users: userReducer,
  companies: companyReducer,
  roles: roleReducer,
  sites: siteReducer,
  establishmentUnits: establishmentUnitReducer,
  forms: formReducer,
  travelPlans: travelPlanReducer,
  surveys: surveyReducer,
  permissions: permissionReducer
};
