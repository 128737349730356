import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '@app/core/model';
import { RoleService } from '@app/core/services';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() currentLanguage: string;
  @Input() currentUser: User;
  @Output() changeLanguage = new EventEmitter<string>();
  @Output() homeButtonPressed = new EventEmitter();
  @Output() myFormsButtonPressed = new EventEmitter();
  @Output() mySurveysButtonPressed = new EventEmitter();
  @Output() myAccountButtonPressed = new EventEmitter();
  @Output() loginButtonPressed = new EventEmitter();
  isPendingRolesFromCompaniesWhereCurrentUserIsAdmin$: Observable<boolean>;
  numberOfPendingRolesFromCompaniesWhereCurrentUserIsAdmin$: Observable<number>;

  subSink = new SubSink();
  isCurrentPathHome: boolean;
  isCurrentPathMyForms: boolean;
  isCurrentPathMyAccount: boolean;

  constructor(private roleService: RoleService, private router: Router) {
    this.isPendingRolesFromCompaniesWhereCurrentUserIsAdmin$ = this.roleService
      .getPendingRolesFromCompaniesWhereCurrentUserIsAdmin()
      .pipe(map(pendingRoles => pendingRoles.length !== 0));

    this.numberOfPendingRolesFromCompaniesWhereCurrentUserIsAdmin$ = this.roleService
      .getPendingRolesFromCompaniesWhereCurrentUserIsAdmin()
      .pipe(map(pendingRoles => pendingRoles.length));
  }

  ngOnInit() {
    this.subSink.sink = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this._updateCurrentPathBooleans(event.url);
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  changeLanguageToFrench(): void {
    this.changeLanguage.emit('fr');
  }

  changeLanguageToDutch(): void {
    this.changeLanguage.emit('nl');
  }

  isCurrentLanguageFrench(): boolean {
    return this.currentLanguage === 'fr';
  }

  isCurrentLanguageDutch(): boolean {
    return this.currentLanguage === 'nl';
  }

  onHomeButtonPressed(): void {
    this.homeButtonPressed.emit();
  }

  onMyFormsButtonPressed(): void {
    this.myFormsButtonPressed.emit();
  }

  onMySurveysButtonPressed(): void {
    this.mySurveysButtonPressed.emit();
  }

  onMyAccountButtonPressed(): void {
    this.myAccountButtonPressed.emit();
  }

  onLoginButtonPressed(): void {
    this.loginButtonPressed.emit();
  }

  private _updateCurrentPathBooleans(currentUrl: string): void {
    this.isCurrentPathHome = currentUrl.startsWith('/home');
    this.isCurrentPathMyForms = currentUrl.startsWith('/sites');
    this.isCurrentPathMyAccount = currentUrl.startsWith('/account');
  }
}
