import { Address } from './address.model';

export class EstablishmentUnit {
  constructor();
  constructor(
    id: number,
    establishmentNumber: number,
    nameFR: string,
    nameNL: string,
    isMainEstablishmentUnit: boolean,
    isActive: boolean,
    companyId: number,
    siteId: number,
    address: Address
  );
  constructor(
    public id?: number,
    public establishmentNumber?: number,
    public nameFR?: string,
    public nameNL?: string,
    public isMainEstablishmentUnit?: boolean,
    public isActive?: boolean,
    public companyId?: number,
    public siteId?: number,
    public address?: Address
  ) {}
}
