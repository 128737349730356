import { Injectable } from '@angular/core';
import { ApiUserService } from '@app/core/api';
import { SnackbarService } from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { AuthActionTypes } from '../actions/auth.actions';
import {
  ChangeUserPassword,
  ChangeUserPasswordFailure,
  ChangeUserPasswordSuccess,
  GetAllAdminUsers,
  GetAllUsers,
  GetAllUsersFailure,
  GetAllUsersSuccess,
  GetUser,
  GetUserFailure,
  GetUserSuccess,
  UpdateUser,
  UpdateUserFailure,
  UpdateUserSuccess,
  UserActionTypes
} from '../actions/user.actions';

@Injectable()
export class UserEffects {
  constructor(private actions: Actions, private apiUserService: ApiUserService, private snackbarService: SnackbarService) {}

  @Effect()
  getAllUsers$: Observable<any> = this.actions.pipe(
    ofType<GetAllUsers>(UserActionTypes.GET_ALL_USERS),
    concatMap(() => {
      return this.apiUserService.getAllUsers().pipe(
        map(users => {
          return new GetAllUsersSuccess(users);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetAllUsersFailure());
        })
      );
    })
  );

  @Effect()
  getAllAdminUsers$: Observable<any> = this.actions.pipe(
    ofType<GetAllAdminUsers>(UserActionTypes.GET_ALL_ADMIN_USERS),
    concatMap(() => {
      return this.apiUserService.getAllAdminUsers().pipe(
        map(adminUsers => {
          return new GetAllUsersSuccess(adminUsers);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetAllUsersFailure());
        }),
        takeUntil(this.actions.pipe(ofType(AuthActionTypes.LOGOUT)))
      );
    })
  );

  @Effect()
  getUser$: Observable<any> = this.actions.pipe(
    ofType<GetUser>(UserActionTypes.GET_USER),
    map(action => action.payload),
    concatMap(id => {
      return this.apiUserService.getUser(id).pipe(
        map(user => {
          return new GetUserSuccess(user);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetUserFailure());
        }),
        takeUntil(this.actions.pipe(ofType(AuthActionTypes.LOGOUT)))
      );
    })
  );

  @Effect()
  updateUser$: Observable<any> = this.actions.pipe(
    ofType<UpdateUser>(UserActionTypes.UPDATE_USER),
    map(action => action.payload),
    concatMap(user => {
      return this.apiUserService.updateUser(user).pipe(
        map(updatedUser => {
          return new UpdateUserSuccess(updatedUser);
        }),
        catchError(error => {
          console.log(error);
          return of(new UpdateUserFailure());
        })
      );
    })
  );

  @Effect({ dispatch: false })
  updateUserSuccess$: Observable<any> = this.actions.pipe(
    ofType(UserActionTypes.UPDATE_USER_SUCCESS),
    tap((action: UpdateUserSuccess) => {
      this.snackbarService.openWithTranslation('i18n.UserEffects.update-user-success');
    })
  );

  @Effect({ dispatch: false })
  updateUserFailure$: Observable<any> = this.actions.pipe(
    ofType(UserActionTypes.UPDATE_USER_FAILURE),
    tap((action: UpdateUserFailure) => {
      this.snackbarService.openWithTranslation('i18n.UserEffects.update-user-failure');
    })
  );

  @Effect()
  changePassword$: Observable<any> = this.actions.pipe(
    ofType<ChangeUserPassword>(UserActionTypes.CHANGE_USER_PASSWORD),
    concatMap(action => {
      return this.apiUserService.changePassword(action.payload).pipe(
        map(_ => {
          this.snackbarService.openWithTranslation('i18n.UserEffects.update-user-success');
          return new ChangeUserPasswordSuccess();
        }),
        catchError(error => {
          console.log(error);
          this.snackbarService.openErrorWithTranslation('i18n.UserEffects.update-user-failure');
          return of(new ChangeUserPasswordFailure());
        })
      );
    })
  );
}
