<div class="content-container">
  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="50px">
    <ng-container *ngIf="isLoading; else isNotLoadingTemplate">
      <mat-spinner fxFlexAlign="center" [diameter]="45"></mat-spinner>
    </ng-container>
    <ng-template #isNotLoadingTemplate>
      <ng-container *ngIf="user; else UserIsNullTemplate">
        <app-register-dialog [prefilledUser]="user" (registerButtonPressed)="register($event)"></app-register-dialog>
      </ng-container>
      <ng-template #UserIsNullTemplate>
        <div class="error-message" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
          <mat-icon>error</mat-icon>
          <span>{{ 'i18n.RegisterOldUserContainerComponent.get-user-by-token-error' | translate }}</span>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
