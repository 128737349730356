import { Section } from './section.model';

export class Form {
  constructor();
  constructor(id: number, nameTranslationKey: string, reference: Date, siteId: number, sections: Section[]);
  constructor(
    public id?: number,
    public nameTranslationKey?: string,
    public reference?: Date,
    public siteId?: number,
    public sections?: Section[]
  ) {}
}
