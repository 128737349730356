import { Injectable } from '@angular/core';
import { ApiFormService } from '@app/core/api/api-form.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  FormActionTypes,
  GetAllForms,
  GetAllFormsFailure,
  GetAllFormsSuccess,
  GetForm,
  GetFormFailure,
  GetFormSuccess
} from '../actions/form.actions';

@Injectable()
export class FormEffects {
  constructor(private actions: Actions, private apiFormService: ApiFormService) {}

  @Effect()
  getAllForms$: Observable<any> = this.actions.pipe(
    ofType<GetAllForms>(FormActionTypes.GET_ALL_FORMS),
    concatMap(() => {
      return this.apiFormService.getAllForms().pipe(
        map(forms => {
          return new GetAllFormsSuccess(forms);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetAllFormsFailure());
        })
      );
    })
  );

  @Effect()
  getForm$: Observable<any> = this.actions.pipe(
    ofType<GetForm>(FormActionTypes.GET_FORM),
    map(action => action.formId),
    concatMap(id => {
      return this.apiFormService.getForm(id).pipe(
        map(form => {
          return new GetFormSuccess(form);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetFormFailure());
        })
      );
    })
  );
}
