import { Injectable } from '@angular/core';
import { ApiCompanyService } from '@app/core/api';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import {
  CompanyActionTypes,
  GetAllCompanies,
  GetAllCompaniesFailure,
  GetAllCompaniesSuccess,
  GetCompany,
  GetCompanyFailure,
  GetCompanySuccess,
  CreateCompany,
  CreateCompanyFailure,
  CreateCompanySuccess,
  CreateFidusCompany,
  CreateFidusCompanySuccess,
  CreateFidusCompanyFailure
} from '../actions/company.actions';
import { Router } from '@angular/router';

@Injectable()
export class CompanyEffects {
  constructor(private actions: Actions, private apiCompanyService: ApiCompanyService, private router: Router) {}

  @Effect()
  getAllCompanies$: Observable<any> = this.actions.pipe(
    ofType<GetAllCompanies>(CompanyActionTypes.GET_ALL_COMPANIES),
    concatMap(() => {
      return this.apiCompanyService.getAllCompanies().pipe(
        map(companies => {
          return new GetAllCompaniesSuccess(companies);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetAllCompaniesFailure());
        })
      );
    })
  );

  @Effect()
  getCompany$: Observable<any> = this.actions.pipe(
    ofType<GetCompany>(CompanyActionTypes.GET_COMPANY),
    map(action => action.payload),
    concatMap(id => {
      return this.apiCompanyService.getCompany(id).pipe(
        map(company => {
          return new GetCompanySuccess(company);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetCompanyFailure());
        })
      );
    })
  );

  @Effect()
  createCompany$: Observable<any> = this.actions.pipe(
    ofType<CreateCompany>(CompanyActionTypes.CREATE_COMPANY),
    concatMap(action => {
      return this.apiCompanyService.createCompany(action.payload).pipe(
        map(company => {
          return new CreateCompanySuccess(company);
        }),
        catchError(error => {
          console.log(error);
          return of(new CreateCompanyFailure());
        })
      );
    })
  );

  @Effect({ dispatch: false })
  createCompanySuccess$: Observable<any> = this.actions.pipe(
    ofType(CompanyActionTypes.CREATE_COMPANY_SUCCESS),
    tap((action: CreateCompanySuccess) => {
      this.router.navigate([`/companies`]);
    })
  );

  // @Effect()
  // getFidusCompany$: Observable<any> = this.actions.pipe(
  //   ofType<CreateFidusCompany>(CompanyActionTypes.FIDUS_COMPANY),
  //   concatMap(action => {
  //     return this.apiCompanyService.createFidusCompany(action.payload).pipe(
  //       map(company => {
  //         console.log(company);
  //         return new CreateFidusCompanySuccess(company);
  //       }),
  //       catchError(error => {
  //         console.log(error);
  //         return of(new CreateCompanyFailure());
  //       })
  //     );
  //   })
  // );

  // @Effect()
  // getFidusCompanySuccess$: Observable<any> = this.actions.pipe(
  //   ofType<CreateFidusCompanySuccess>(CompanyActionTypes.FIDUS_COMPANY_SUCCESS),
  //   map( m => {
  //     return new GetAllCompanies();
  //   })
  // );
}
