import { Injectable } from '@angular/core';
import { ApiSurveyService } from '@app/core/api/api-survey.service';
import { TravelPlan } from '@app/core/model';
import { SnackbarService } from '@app/core/services';
import { convertToPartial } from '@app/shared/utils';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ImportExcelFileNotValid, UpdateTravelPlanSuccess } from '../actions';
import {
  GetAllSurveys,
  GetAllSurveysFailure,
  GetAllSurveysSuccess,
  ImportExcelFile,
  ImportExcelFileFailure,
  ImportExcelFileDenied,
  ImportExcelFileSuccess,
  ImportSurvey,
  ImportSurveyFailure,
  ImportSurveySuccess,
  SurveyActionTypes
} from '../actions/survey.actions';
import { TravelPlanService } from '@app/core/services/travel-plan.service';

@Injectable()
export class SurveyEffects {
  constructor(
    private actions: Actions,
    private apiSurveyService: ApiSurveyService,
    private snackbarService: SnackbarService,
    private travelPlanService: TravelPlanService
  ) {}

  @Effect()
  getAllSurveys$: Observable<any> = this.actions.pipe(
    ofType<GetAllSurveys>(SurveyActionTypes.GET_ALL_SURVEYS),
    concatMap(() => {
      return this.apiSurveyService.getAllSurveys().pipe(
        map(surveys => {
          return new GetAllSurveysSuccess(surveys);
        }),
        catchError(error => {
          console.log(error);
          return of(new GetAllSurveysFailure());
        })
      );
    })
  );

  @Effect()
  importSurvey$: Observable<any> = this.actions.pipe(
    ofType<ImportSurvey>(SurveyActionTypes.IMPORT_SURVEY),
    concatMap(action => {
      return this.apiSurveyService.importSurvey(action.planId, action.exportURL, action.surveyID).pipe(
        map(travelPlan => {
          if ('validation_status' in travelPlan) {
            return new ImportExcelFileDenied(travelPlan['validation_status']);
          }
          const update: Update<TravelPlan> = {
            id: travelPlan.id,
            changes: convertToPartial(travelPlan)
          };
          return new ImportSurveySuccess(update);
        }),
        catchError(error => {
          console.log(error);
          return of(new ImportSurveyFailure());
        })
      );
    })
  );

  @Effect()
  importSurveySuccess$: Observable<any> = this.actions.pipe(
    ofType<ImportSurveySuccess>(SurveyActionTypes.IMPORT_SURVEY_SUCCESS),
    tap(action => {
      this.snackbarService.openWithTranslation('i18n.SurveyEffects.import-survey-success');
    }),
    concatMap(action => {
      return of(new UpdateTravelPlanSuccess(action.travelPlan));
    })
  );

  @Effect({ dispatch: false })
  importSurveyFailure$: Observable<any> = this.actions.pipe(
    ofType<ImportSurveyFailure>(SurveyActionTypes.IMPORT_SURVEY_FAILURE),
    tap(action => {
      this.snackbarService.openErrorWithTranslation('i18n.SurveyEffects.import-survey-failure');
    })
  );

  @Effect()
  importExcelFile$: Observable<any> = this.actions.pipe(
    ofType<ImportExcelFile>(SurveyActionTypes.IMPORT_EXCEL_FILE),
    concatMap(action => {
      return this.apiSurveyService
        .importExcelFile(action.planId, action.excelFileName, action.excelFileAsBase64, action.ignoreValidation)
        .pipe(
          map(travelPlan => {
            if ('validation_status' in travelPlan) {
              if (travelPlan['validation_status'] === 'import-plan-error-invalid-postcode') {
                return new ImportExcelFileNotValid(travelPlan);
              } else {
                return new ImportExcelFileDenied(travelPlan['validation_status']);
              }
            }
            const update: Update<TravelPlan> = {
              id: travelPlan.id,
              changes: convertToPartial(travelPlan)
            };
            return new ImportExcelFileSuccess(update);
          }),
          catchError(error => {
            return of(new ImportExcelFileFailure());
          })
        );
    })
  );

  @Effect()
  importExcelFileSuccess$: Observable<any> = this.actions.pipe(
    ofType<ImportExcelFileSuccess>(SurveyActionTypes.IMPORT_EXCEL_FILE_SUCCESS),
    tap(action => {
      this.snackbarService.openWithTranslation('i18n.SurveyEffects.import-excel-file-success');
    }),
    concatMap(action => {
      return of(new UpdateTravelPlanSuccess(action.travelPlan));
    })
  );

  @Effect({ dispatch: false })
  importExcelFileFailure$: Observable<any> = this.actions.pipe(
    ofType<ImportExcelFileFailure>(SurveyActionTypes.IMPORT_EXCEL_FILE_FAILURE),
    tap(action => {
      this.snackbarService.openErrorWithTranslation('i18n.SurveyEffects.import-excel-file-failure');
    })
  );

  @Effect({ dispatch: false })
  importExcelFileDenied$: Observable<any> = this.actions.pipe(
    ofType<ImportExcelFileDenied>(SurveyActionTypes.IMPORT_EXCEL_FILE_DENIED),
    tap(action => {
      this.snackbarService.openErrorWithTranslation(`i18n.SectionDetailsComponent.${action.errorMessage}`);
    })
  );
}
