import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { PDFDocumentProxy, PDFProgressData } from 'pdfjs-dist';

export const LEGAL_NOTICES_FR_PDF_URL = 'https://environnement.brussels/sites/default/files/pde_legal_notices_fr.pdf';
export const LEGAL_NOTICES_NL_PDF_URL = 'https://leefmilieu.brussels/sites/default/files/pde_legal_notices_nl.pdf';

export interface LegalNoticesDialogData {
  currentLanguage: string;
}

@Component({
  templateUrl: './legal-notices-dialog.component.html',
  styleUrls: ['./legal-notices-dialog.component.scss']
})
export class LegalNoticesDialogComponent implements OnInit {
  pdfSrc: string;
  isLoading = true;
  hasLoadingError = false;
  loadingPercentage = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LegalNoticesDialogData,
    private dialogRef: MatDialogRef<LegalNoticesDialogComponent>,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.pdfSrc = this._isCurrentLanguageDutch() ? LEGAL_NOTICES_NL_PDF_URL : LEGAL_NOTICES_FR_PDF_URL;
  }

  onError(error: any): void {
    this.hasLoadingError = true;
    this.isLoading = false;
  }

  onProgress(progressData: PDFProgressData): void {
    this.loadingPercentage = progressData.loaded / progressData.total;
  }

  onLoadingComplete(pdf: PDFDocumentProxy) {
    this.isLoading = false;
  }

  private _isCurrentLanguageDutch(): boolean {
    return isNotNullNorUndefined(this.data) && this.data.currentLanguage === 'nl';
  }
}
