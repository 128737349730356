import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@app/core/model';

@Component({
  templateUrl: './user-contact-details-dialog-container.component.html',
  styleUrls: ['./user-contact-details-dialog-container.component.scss']
})
export class UserContactDetailsDialogContainerComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: User, private dialogRef: MatDialogRef<UserContactDetailsDialogContainerComponent>) {}

  ngOnInit() {}
}
