import { Company } from '@app/core/model';
import { Action } from '@ngrx/store';

export enum CompanyActionTypes {
  GET_ALL_COMPANIES = '[Company] Get All Companies',
  GET_ALL_COMPANIES_SUCCESS = '[Company] Get All Companies Success',
  GET_ALL_COMPANIES_FAILURE = '[Company] Get All Companies Failure',
  GET_COMPANY = '[Company] Get Company',
  GET_COMPANY_SUCCESS = '[Company] Get Company Success',
  GET_COMPANY_FAILURE = '[Company] Get Company Failure',
  CREATE_COMPANY = '[Company] Create a Company',
  CREATE_COMPANY_SUCCESS = '[Company] Create a Company success',
  CREATE_COMPANY_FAIL = '[Company] Create a Company fail',
  FIDUS_COMPANY = '[Company] FIDUS a Company',
  FIDUS_COMPANY_SUCCESS = '[Company] FIDUS a Company success',
  FIDUS_COMPANY_FAIL = '[Company] FIDUS a Company fail'
}

export class GetAllCompanies implements Action {
  readonly type = CompanyActionTypes.GET_ALL_COMPANIES;
}

export class GetAllCompaniesSuccess implements Action {
  readonly type = CompanyActionTypes.GET_ALL_COMPANIES_SUCCESS;
  constructor(public payload: Company[]) {}
}

export class GetAllCompaniesFailure implements Action {
  readonly type = CompanyActionTypes.GET_ALL_COMPANIES_FAILURE;
}

export class GetCompany implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY;
  constructor(public payload: number) {}
}

export class GetCompanySuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_SUCCESS;
  constructor(public payload: Company) {}
}

export class GetCompanyFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_FAILURE;
}

export class CreateCompany implements Action {
  readonly type = CompanyActionTypes.CREATE_COMPANY;
  constructor(public payload: any) {}
}

export class CreateCompanySuccess implements Action {
  readonly type = CompanyActionTypes.CREATE_COMPANY_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateCompanyFailure implements Action {
  readonly type = CompanyActionTypes.CREATE_COMPANY_FAIL;
}

export class CreateFidusCompany implements Action {
  readonly type = CompanyActionTypes.FIDUS_COMPANY;
  constructor(public payload: Company) {}
}

export class CreateFidusCompanySuccess implements Action {
  readonly type = CompanyActionTypes.FIDUS_COMPANY_SUCCESS;
  constructor(public payload: Company) {}
}

export class CreateFidusCompanyFailure implements Action {
  readonly type = CompanyActionTypes.FIDUS_COMPANY_FAIL;
}
export type CompanyActions =
  | GetAllCompanies
  | GetAllCompaniesSuccess
  | GetAllCompaniesFailure
  | GetCompany
  | GetCompanySuccess
  | CreateCompany
  | CreateCompanySuccess
  | CreateCompanyFailure
  | GetCompanyFailure
  | CreateFidusCompany
  | CreateFidusCompanyFailure
  | CreateFidusCompanySuccess;
