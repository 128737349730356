<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
      <img src="assets/images/user-icon.svg" class="user-icon" />
      <h4 class="my-account-details-title primary-color">
        {{ 'i18n.MyAccountDetailsComponent.my-account-details-title' | translate }}
      </h4>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-chip-list selectable="false">
        <mat-chip *ngIf="isAdminBE()" disableRipple>{{ 'i18n.UserType.AdminBE' | translate }}</mat-chip>
      </mat-chip-list>

      <button mat-flat-button color="warn" (click)="onLogout()">
        {{ 'i18n.MyAccountDetailsComponent.logout' | translate }}
      </button>
    </div>
  </div>

  <mat-card class="mat-elevation-z0">
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
        <div fxLayout="column">
          <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px">
            <app-custom-form-field [settings]="lastNameFieldSettings" fxFlex.gt-xs="0 1 calc(50% - 5px)"></app-custom-form-field>
            <app-custom-form-field [settings]="firstNameFieldSettings" fxFlex.gt-xs="0 1 calc(50% - 5px)"></app-custom-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="10px">
            <app-custom-form-field [settings]="emailAddressFieldSettings" fxFlex.gt-xs="0 1 calc(50% - 5px)"></app-custom-form-field>
            <app-custom-form-field [settings]="phoneNumberFieldSettings" fxFlex.gt-xs="0 1 calc(50% - 5px)"></app-custom-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="10px">
            <app-custom-form-field [settings]="professionFieldSettings" fxFlex.gt-xs="0 1 calc(50% - 5px)"></app-custom-form-field>
            <app-custom-form-field [settings]="mobileNumberFieldSettings" fxFlex.gt-xs="0 1 calc(50% - 5px)"></app-custom-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="10px">
            <app-custom-form-field [settings]="languageFieldSettings" fxFlex.gt-xs="0 1 calc(50% - 5px)"></app-custom-form-field>
          </div>
          <div fxLayout="row">
            <app-custom-form-field [settings]="mobilityContactCommunicationFieldSettings" fxFlex="1 1 auto"></app-custom-form-field>
          </div>
          <div fxLayout="row">
            <app-custom-form-field [settings]="enewsFieldSettings" fxFlex="1 1 auto"></app-custom-form-field>
          </div>
          <div fxLayout="row">
            <app-custom-form-field [settings]="distributionLetterFieldSettings" fxFlex="1 1 auto"></app-custom-form-field>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.lt-md="start stretch"
          fxLayoutGap="10px"
        >
          <button mat-flat-button color="primary" (click)="saveChanges()" [disabled]="isSaveButtonDisabled()">
            {{ 'i18n.MyAccountDetailsComponent.modify-my-data' | translate }}
          </button>

          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="space-between center"
            fxLayoutAlign.lt-md="start stretch"
            fxLayoutGap="10px"
          >
            <button mat-button color="primary" (click)="onChangePassword()">
              {{ 'i18n.MyAccountDetailsComponent.modify-my-password' | translate }}
            </button>

            <div
              [matTooltip]="'i18n.MyAccountDetailsComponent.cannot-delete-account-because-admin' | translate"
              [matTooltipDisabled]="!isDeleteAccountButtonDisabled"
            >
              <button mat-button color="warn" (click)="onDeleteAccount()" [disabled]="isDeleteAccountButtonDisabled" fxFlex>
                {{ 'i18n.MyAccountDetailsComponent.delete-my-account' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>
