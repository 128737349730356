import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { User } from '@app/core/model';
import { UserService } from '@app/core/services';

@Component({
  selector: 'app-user-contact-details-dialog',
  templateUrl: './user-contact-details-dialog.component.html',
  styleUrls: ['./user-contact-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserContactDetailsDialogComponent implements OnInit {
  @Input() user: User;

  constructor(private userService: UserService) {}

  ngOnInit() {}

  userToString(user: User): string {
    return this.userService.toString(user);
  }
}
