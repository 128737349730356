import { Action } from '@ngrx/store';

export enum PermissionActionTypes {
  GET_ALL_PERMISSION = '[Permission] Get All Permission',
  GET_ALL_FAILURE = 'FAIL',
  SET_PERMISSION = '[Permission] Set'
}

export class GetAllPermission implements Action {
  readonly type = PermissionActionTypes.GET_ALL_PERMISSION;
}

export class GetAllPermissionFailure implements Action {
  readonly type = PermissionActionTypes.GET_ALL_FAILURE;
}

export class SetPermission implements Action {
  readonly type = PermissionActionTypes.SET_PERMISSION;
  constructor(public permissions: string[]) {}
}

export type PermissionActions = GetAllPermission | SetPermission;
