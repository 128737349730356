import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

export const currentTokenCookieName = 'currentToken';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.cookieService.get(currentTokenCookieName);

    request = request.clone({
      setHeaders: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
    return next.handle(request);
  }
}
