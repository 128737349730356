import { User } from '@app/core/model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserActions, UserActionTypes } from '../actions/user.actions';

export interface UserState extends EntityState<User> {
  // additional entity state properties
}

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>();

const initialState: UserState = userAdapter.getInitialState({
  // additional entity state properties
});

export function userReducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.GET_ALL_USERS_SUCCESS: {
      return userAdapter.upsertMany(action.payload, state);
    }
    case UserActionTypes.GET_USER_SUCCESS: {
      return userAdapter.upsertOne(action.payload, state);
    }
    case UserActionTypes.UPDATE_USER_SUCCESS: {
      return userAdapter.updateOne({ id: action.payload.id, changes: action.payload }, state);
    }
    default: {
      return state;
    }
  }
}
