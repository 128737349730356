import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot } from '@angular/router';
import { UserType } from '@app/core/model';
import { AuthService } from '@app/core/services';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminBEGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.checkIfCurrentUserIsAdminBE();
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    return this.checkIfCurrentUserIsAdminBE();
  }

  private checkIfCurrentUserIsAdminBE(): Observable<boolean> | boolean {
    return this.authService.isLoadingFinished().pipe(
      filter(isLoadingFinished => isLoadingFinished),
      switchMap(_ => {
        return this.authService.getCurrentUserType();
      }),
      first(),
      map(currentUserType => currentUserType === UserType.AdminBE)
    );
  }
}
