import { isNotNullNorUndefined } from './isNotNullNorUndefined';

export function convertToPartial<T>(object: T, nullableAttributes?: string[]): Partial<T> {
  const partialObject: Partial<T> = {};
  Object.keys(object).forEach(key => {
    const attributeValue = object[key];
    const isAttributeNullable = isNotNullNorUndefined(nullableAttributes) && nullableAttributes.includes(key);

    if (isAttributeNullable || isNotNullNorUndefined(attributeValue)) {
      partialObject[key] = attributeValue;
    }
  });
  return partialObject;
}
