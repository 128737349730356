import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/core/services';
import { ForgottenDialogComponent } from '../../components';

@Component({
  selector: 'app-forgotten-dialog-container',
  templateUrl: './forgotten-dialog.container.html',
  styleUrls: ['./forgotten-dialog.container.scss']
})
export class ForgottenDialogContainerComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ForgottenDialogComponent>, private authService: AuthService) {}

  ngOnInit() {}

  forgottenPassword(userData: { emailAddress: string }): void {
    this.authService.forgottenPassword(userData.emailAddress);
  }
}
