import { Permission } from '@app/core/model/permission.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PermissionActions, PermissionActionTypes } from '../actions';

export interface PermissionState extends EntityState<Permission> {
  permissions: string[];
}

export const permissionAdapter: EntityAdapter<Permission> = createEntityAdapter<Permission>();

const initialState: PermissionState = permissionAdapter.getInitialState({
  permissions: []
});

export function permissionReducer(state = initialState, action: PermissionActions): PermissionState {
  switch (action.type) {
    case PermissionActionTypes.SET_PERMISSION: {
      return { ...state, permissions: action.permissions };
    }
    default: {
      return state;
    }
  }
}
