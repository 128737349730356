import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginCredentials } from '@app/core/model';
import { CustomFormFieldSettings, CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  @Output() loginButtonPressed = new EventEmitter<LoginCredentials>();
  @Output() registerButtonPressed = new EventEmitter<boolean>();
  @Output() forgottenButtonPressed = new EventEmitter<boolean>();

  form: FormGroup;
  fieldsSettings: CustomFormFieldSettings[];

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      emailAddress: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    this.fieldsSettings = [
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.EmailInput,
        placeholder: '',
        label: 'i18n.User.emailAddress',
        required: true,
        hint: '',
        control: this.form.get('emailAddress') as FormControl
      },
      {
        ...new CustomFormFieldSettings(),
        type: CUSTOM_FORM_FIELD_TYPE.PasswordInput,
        placeholder: '',
        label: 'i18n.User.password',
        required: true,
        hint: '',
        control: this.form.get('password') as FormControl
      }
    ];
  }

  onLoginButtonPressed(): void {
    this.loginButtonPressed.emit({
      emailAddress: this.form.get('emailAddress').value,
      password: this.form.get('password').value
    });
  }

  onRegisterButtonPressed(): void {
    this.registerButtonPressed.emit(true);
  }

  onForgottenButtonPressed() {
    this.forgottenButtonPressed.emit(true);
  }
}
