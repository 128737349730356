import { Injectable } from '@angular/core';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  open(message: string): void {
    this.snackBar.open(message);
  }

  openWithTranslation(translationKey: string): void {
    this.translateService.get(translationKey).subscribe((translation: string) => {
      this.open(translation);
    });
  }

  openError(message: string): MatSnackBarRef<SimpleSnackBar> {
    const snackBarRef = this.snackBar.open(message, 'x', {
      duration: 0,
      panelClass: 'snackbar-error'
    });

    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });

    return snackBarRef;
  }

  openErrorWithTranslation(translationKey: string): void {
    this.translateService.get(translationKey).subscribe((translation: string) => {
      this.openError(translation);
    });
  }
}
