import { Form } from '@app/core/model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FormActions, FormActionTypes } from '../actions';

export interface FormState extends EntityState<Form> {
  // additional entity state properties
}

export const formAdapter: EntityAdapter<Form> = createEntityAdapter<Form>();

const initialState: FormState = formAdapter.getInitialState({
  // additional entity state properties
});

export function formReducer(state = initialState, action: FormActions): FormState {
  switch (action.type) {
    case FormActionTypes.GET_ALL_FORMS_SUCCESS: {
      return formAdapter.addMany(action.forms, state);
    }
    case FormActionTypes.GET_FORM_SUCCESS: {
      return formAdapter.upsertOne(action.form, state);
    }
    default: {
      return state;
    }
  }
}
