import { Site } from '@app/core/model/site.model';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { siteAdapter, SiteState } from '../reducers/site.reducers';

export const selectSiteState = createFeatureSelector<SiteState>('sites');

const { selectIds, selectEntities, selectAll, selectTotal } = siteAdapter.getSelectors();

export const selectSiteIds = createSelector(
  selectSiteState,
  selectIds
);

export const selectSiteEntities = createSelector(
  selectSiteState,
  selectEntities
);

export const selectAllSites = createSelector(
  selectSiteState,
  selectAll
);

export const selectSiteTotal = createSelector(
  selectSiteState,
  selectTotal
);

export const selectSiteById = () =>
  createSelector(
    selectSiteEntities,
    (entities: Dictionary<Site>, props: { siteId: number }) => entities[props.siteId]
  );
