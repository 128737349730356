import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiCompanyService } from '@app/core/api';
import { Company } from '@app/core/model';
import { Role, RoleStatus } from '@app/core/model/role.model';
import { AuthService, CompanyService, SnackbarService } from '@app/core/services';
import { RoleService } from '@app/core/services/role.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-affiliation-application-dialog-container',
  templateUrl: './affiliation-application-dialog.container.html',
  styleUrls: ['./affiliation-application-dialog.container.scss']
})
export class AffiliationApplicationDialogContainerComponent implements OnInit, OnDestroy {
  companiesApplied: Company[];
  currentUserId: number;
  isApplicationLoading = false;

  subSink = new SubSink();

  constructor(
    private dialogRef: MatDialogRef<AffiliationApplicationDialogContainerComponent>,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private companyService: CompanyService,
    private companyApiService: ApiCompanyService,
    private roleService: RoleService
  ) {}

  ngOnInit() {
    this.companyService.loadAllCompanies();

    this.companiesApplied = [];

    const subscription = this.authService.getCurrentUserId().subscribe(currentUserId => {
      this.currentUserId = currentUserId;
    });
    this.subSink.add(subscription);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  sendAffiliationApplications(): void {
    this.companiesApplied.forEach(company => {
      const addedRole: Role = {
        id: null,
        userId: this.currentUserId,
        companyId: company.id,
        status: RoleStatus.Pending
      };
      this.roleService.addRole(addedRole);
    });

    this.dialogRef.close();
  }

  addAffiliationApplication(bceNumber: string): void {
    this.isApplicationLoading = true;

    if (this.companiesApplied.map(x => x.bceNumber).includes(bceNumber)) {
      this.snackbarService.openErrorWithTranslation('i18n.AffiliationApplicationDialogContainerComponent.company-already-linked');
      this.isApplicationLoading = false;
      return;
    }

    const subscription = this.companyApiService.createFidusCompany({ BCE_Number: bceNumber }).subscribe(
      newCompany => {
        if (!newCompany) {
          this.snackbarService.openErrorWithTranslation('i18n.AffiliationApplicationDialogContainerComponent.company-not-found');
          this.isApplicationLoading = false;
          return;
        }
        this.companiesApplied = [...this.companiesApplied, newCompany];
        this.isApplicationLoading = false;
      },
      _ => {
        this.isApplicationLoading = false;
      }
    );

    this.subSink.add(subscription);
  }

  removeAffiliationApplication(companyId: number): void {
    this.companiesApplied = this.companiesApplied.filter(company => company.id !== companyId);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
