import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { LayoutGapStyleBuilder } from '@angular/flex-layout/flex';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CORSInterceptor, ErrorInterceptor, JwtInterceptor } from './core/interceptors';
import { CustomizedGlobalErrorHandler } from './errorhandler/customizedglobalerrorhandler';
import { CustomLayoutGapStyleBuilder } from './customizedutils/customlayoutgapstylebuilder';
import { AuthModule } from './modules/auth';
import { MyAccountModule } from './modules/my-account';
import { SharedModule } from './shared/shared.module';
import { AppStoreModule } from './store/app-store.module';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    CoreModule,
    AppStoreModule,
    AuthModule,
    MyAccountModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CORSInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomizedGlobalErrorHandler },
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
