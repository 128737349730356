import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CUSTOM_FORM_FIELD_TYPE } from '@app/shared/components';
import { convertToPartial, isNotNullNorUndefined } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Form,
  Question,
  QuestionSettings,
  QuestionSettingsCalculation,
  QuestionSettingsCalculationOperationType,
  QuestionSettingsCondition,
  QuestionSettingsConditionOperationType,
  QuestionSettingsError,
  QuestionSettingsWarning,
  Section,
  SPECIFIC_FIELD_TYPE,
  TableQuestionCell,
  TableQuestionCellSettings,
  TableQuestionColumnSettings
} from '../model';
import { AnswerOption } from '../model/answer-option.model';
import { ApiSite } from './api-site.service';

export interface ApiForm {
  id?: number;
  name: string;
  reference?: Date;
  site_id?: number;
  site?: ApiSite;
  sections?: ApiSection[];
}

export interface ApiSection {
  id?: number;
  name?: string;
  order?: number;
  description?: string;
  questions?: ApiQuestion[];
  sections?: ApiSection[];
}

export interface ApiQuestion {
  id?: number;
  name?: string;
  order?: number;
  short?: boolean;
  label_unit?: string;
  description?: string;
  field_type?: ApiFieldType;
  inline?: boolean;
  required?: boolean;
  propositions?: ApiAnswerOption[];
  parent_id?: number;
  settings?: ApiQuestionSettings;
}

export enum ApiFieldType {
  Integer = 'number',
  Double = 'double',
  Array = 'array',
  Date = 'date',
  Text = 'text',
  Radio = 'radio',
  Checkbox = 'checkbox',
  MultiCheckbox = 'multicheckbox',
  TextArea = 'textarea',
  Table = 'table',
  Slider = 'slider',
  Select = 'select',
  File = 'file',
  Percentage = 'percentage',
  YesNo = 'yesno'
}

export interface ApiAnswerOption {
  id: number;
  name: string;
  type: string;
}

export interface ApiQuestionSettings {
  columns?: string[];
  columnsTypes?: ApiFieldType[];
  lines?: string[];
  visibleIf?: ApiQuestionSettingsCondition[];
  calculatedValue?: ApiQuestionSettingsCalculation;
  min?: number;
  max?: number;
  optionalColumns?: string[];
  columnsSettings?: ApiTableQuestionColumnSettings[];
  cellsSettings?: ApiTableQuestionCellSettings[];
  disabledColumns?: string[];
  lineColors?: string[];
  errorIf?: ApiQuestionSettingsError[];
  warningIf?: ApiQuestionSettingsWarning[];
  isTotalHidden?: boolean;
}

export interface ApiQuestionSettingsCondition {
  questionName?: string;
  column?: string;
  line?: string;
  expectedValue?: string;
  expectedValueQuestion?: string;
  expectedCalculatedValue?: ApiQuestionSettingsCalculation;
  operationType?: ApiQuestionSettingsConditionOperationType;
  mandatory?: boolean;
}

export interface ApiQuestionSettingsCalculation {
  questionsNames?: string[];
  questionCells?: ApiTableQuestionCell[];
  operationType?: ApiQuestionSettingsCalculationOperationType;
  operationSpecificNumber?: number;
}

export interface ApiQuestionSettingsError {
  errorTranslationKey: string;
  conditions: ApiQuestionSettingsCondition[];
}

export interface ApiQuestionSettingsWarning {
  warningTranslationKey: string;
  conditions: ApiQuestionSettingsCondition[];
}

export enum ApiQuestionSettingsConditionOperationType {
  GREATER_THAN = 'GREATER_THAN',
  LOWER_THAN = 'LOWER_THAN',
  GREATER_THAN_OR_EQUALS_TO = 'GREATER_THAN_OR_EQUALS_TO',
  LOWER_THAN_OR_EQUALS_TO = 'LOWER_THAN_OR_EQUALS_TO',
  EQUALS_TO = 'EQUAL_TO',
  NOT_EQUALS_TO = 'NEQUAL_TO',
  IS_NULL = 'IS_NULL',
  IS_NOT_NULL = 'IS_NOT_NULL'
}

export enum ApiQuestionSettingsCalculationOperationType {
  SUM = 'SUM',
  SUM_PLUS_MARGIN = 'SUM_PLUS_MARGIN',
  SUM_DIVIDED_BY_SPECIFIC_NUMBER = 'SUM_DIVIDED_BY_SPECIFIC_NUMBER',
  SUM_MULTIPLED_BY_SPECIFIC_NUMBER = 'SUM_MULTIPLED_BY_SPECIFIC_NUMBER'
}

export interface ApiTableQuestionColumnSettings {
  column: string;
  propositions?: ApiAnswerOption[];
}

export interface ApiTableQuestionCellSettings {
  column: string;
  line: string;
  visibleIf?: ApiQuestionSettingsCondition[];
  propositions?: ApiAnswerOption[];
}

export interface ApiTableQuestionCell {
  questionName: string;
  column: string;
  line: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiFormService {
  constructor(private http: HttpClient) {}

  getAllForms(): Observable<Form[]> {
    return this.http.get<ApiForm[]>(`${environment.apiUrl}/forms`).pipe(
      map(apiForms => {
        return apiForms.map(apiForm => this.formFromApiToModel(apiForm));
      })
    );
  }

  getForm(id: number): Observable<Form> {
    return this.http.get<ApiForm>(`${environment.apiUrl}/forms/${id}`).pipe(
      map(apiForm => {
        return this.formFromApiToModel(apiForm);
      })
    );
  }

  formFromApiToModel(apiForm: ApiForm): Form {
    return {
      id: apiForm.id,
      nameTranslationKey: apiForm.name,
      reference: apiForm.reference,
      siteId: apiForm.site ? apiForm.site.id : apiForm.site_id,
      sections: isNotNullNorUndefined(apiForm.sections) ? apiForm.sections.map(s => this.sectionFromApiToModel(s)) : []
    };
  }

  formFromModelToApi(form: Partial<Form>): Partial<ApiForm> {
    return convertToPartial({
      id: form.id,
      name: form.nameTranslationKey,
      reference: form.reference,
      site_id: form.siteId,
      sections: form.sections.map(s => this.sectionFromModelToApi(s))
    } as ApiForm);
  }

  sectionFromApiToModel(apiSection: ApiSection): Section {
    return {
      id: apiSection.id,
      nameTranslationKey: apiSection.name,
      order: apiSection.order,
      description: apiSection.description,
      subsections: isNotNullNorUndefined(apiSection.sections) ? apiSection.sections.map(s => this.sectionFromApiToModel(s)) : [],
      questions: isNotNullNorUndefined(apiSection.questions) ? apiSection.questions.map(q => this.questionFromApiToModel(q)) : []
    };
  }

  sectionFromModelToApi(section: Partial<Section>): Partial<ApiSection> {
    return convertToPartial({
      id: section.id,
      name: section.nameTranslationKey,
      order: section.order,
      description: section.description,
      sections: section.subsections.map(s => this.sectionFromModelToApi(s)),
      questions: section.questions.map(q => this.questionFromModelToApi(q))
    } as ApiSection);
  }

  questionFromApiToModel(apiQuestion: ApiQuestion): Question {
    const fieldType: CUSTOM_FORM_FIELD_TYPE | SPECIFIC_FIELD_TYPE = apiQuestion.field_type
      ? this.fieldTypeFromApitoModel(apiQuestion.field_type)
      : CUSTOM_FORM_FIELD_TYPE.TextInput;

    return {
      id: apiQuestion.id,
      nameTranslationKey: apiQuestion.name,
      description: apiQuestion.description,
      unitTranslationKey: apiQuestion.label_unit,
      order: apiQuestion.order,
      fieldType,
      hasSmallInputField: false,
      isInline: apiQuestion.inline,
      required: isNotNullNorUndefined(apiQuestion.required) ? apiQuestion.required : true,
      answerOptions: isNotNullNorUndefined(apiQuestion.propositions)
        ? apiQuestion.propositions.map(apiAnswerOption => this.answerOptionFromApiToModel(apiAnswerOption))
        : null,
      parentQuestionId: apiQuestion.parent_id,
      settings: isNotNullNorUndefined(apiQuestion.settings) ? this.questionSettingsFromApiToModel(apiQuestion.settings) : null
    };
  }

  questionFromModelToApi(question: Partial<Question>): Partial<ApiQuestion> {
    const fieldType: ApiFieldType = this.fieldTypeFromModelToApi(question.fieldType);

    return convertToPartial({
      id: question.id,
      name_referenced: question.nameTranslationKey,
      order: question.order,
      field_type: fieldType,
      description: question.description,
      label_unit: question.unitTranslationKey,
      short: question.hasSmallInputField,
      inline: question.isInline,
      required: question.required,
      answerOptions: isNotNullNorUndefined(question.answerOptions)
        ? question.answerOptions.map(answerOption => this.answerOptionFromModelToApi(answerOption))
        : null,
      parent_id: question.parentQuestionId,
      settings: isNotNullNorUndefined(question.settings) ? this.questionSettingsFromModelToApi(question.settings) : null
    } as ApiQuestion);
  }

  fieldTypeFromApitoModel(apiFieldType: ApiFieldType): CUSTOM_FORM_FIELD_TYPE | SPECIFIC_FIELD_TYPE {
    switch (apiFieldType) {
      case ApiFieldType.Text:
        return CUSTOM_FORM_FIELD_TYPE.TextInput;
      case ApiFieldType.Integer:
        return CUSTOM_FORM_FIELD_TYPE.Integer;
      case ApiFieldType.Double:
        return CUSTOM_FORM_FIELD_TYPE.Double;
      case ApiFieldType.Date:
        return CUSTOM_FORM_FIELD_TYPE.DateInput;
      case ApiFieldType.Checkbox:
        return CUSTOM_FORM_FIELD_TYPE.Checkbox;
      case ApiFieldType.TextArea:
        return CUSTOM_FORM_FIELD_TYPE.TextArea;
      case ApiFieldType.Radio:
        return CUSTOM_FORM_FIELD_TYPE.Radio;
      case ApiFieldType.MultiCheckbox:
        return CUSTOM_FORM_FIELD_TYPE.MultiCheckbox;
      case ApiFieldType.Slider:
        return CUSTOM_FORM_FIELD_TYPE.Slider;
      case ApiFieldType.Select:
        return CUSTOM_FORM_FIELD_TYPE.Select;
      case ApiFieldType.File:
        return CUSTOM_FORM_FIELD_TYPE.File;
      case ApiFieldType.Percentage:
        return CUSTOM_FORM_FIELD_TYPE.Percentage;
      case ApiFieldType.YesNo:
        return CUSTOM_FORM_FIELD_TYPE.YesNo;
      case ApiFieldType.Table:
        return SPECIFIC_FIELD_TYPE.Table;
      case ApiFieldType.Array:
        return SPECIFIC_FIELD_TYPE.Array;
      default:
        console.log(`apiFieldType was not recognized: ${apiFieldType}`);
        return CUSTOM_FORM_FIELD_TYPE.TextInput;
    }
  }

  fieldTypeFromModelToApi(fieldType: CUSTOM_FORM_FIELD_TYPE | SPECIFIC_FIELD_TYPE): ApiFieldType {
    switch (fieldType) {
      case CUSTOM_FORM_FIELD_TYPE.TextInput:
        return ApiFieldType.Text;
      case CUSTOM_FORM_FIELD_TYPE.Integer:
        return ApiFieldType.Integer;
      case CUSTOM_FORM_FIELD_TYPE.Double:
        return ApiFieldType.Double;
      case CUSTOM_FORM_FIELD_TYPE.DateInput:
        return ApiFieldType.Date;
      case CUSTOM_FORM_FIELD_TYPE.Checkbox:
        return ApiFieldType.Checkbox;
      case CUSTOM_FORM_FIELD_TYPE.TextArea:
        return ApiFieldType.TextArea;
      case CUSTOM_FORM_FIELD_TYPE.Radio:
        return ApiFieldType.Radio;
      case CUSTOM_FORM_FIELD_TYPE.MultiCheckbox:
        return ApiFieldType.MultiCheckbox;
      case CUSTOM_FORM_FIELD_TYPE.Slider:
        return ApiFieldType.Slider;
      case CUSTOM_FORM_FIELD_TYPE.Select:
        return ApiFieldType.Select;
      case CUSTOM_FORM_FIELD_TYPE.File:
        return ApiFieldType.File;
      case CUSTOM_FORM_FIELD_TYPE.Percentage:
        return ApiFieldType.Percentage;
      case CUSTOM_FORM_FIELD_TYPE.YesNo:
        return ApiFieldType.YesNo;
      case SPECIFIC_FIELD_TYPE.Table:
        return ApiFieldType.Table;
      case SPECIFIC_FIELD_TYPE.Array:
        return ApiFieldType.Array;
      default:
        console.log(`fieldType was not recognized: ${fieldType}`);
        return null;
    }
  }

  answerOptionFromApiToModel(apiAnswerOption: ApiAnswerOption): AnswerOption {
    return {
      id: apiAnswerOption.id,
      nameTranslationKey: apiAnswerOption.name,
      type: apiAnswerOption.type
    };
  }

  answerOptionFromModelToApi(answerOption: Partial<AnswerOption>): Partial<ApiAnswerOption> {
    return convertToPartial({
      id: answerOption.id,
      name: answerOption.nameTranslationKey,
      type: answerOption.type
    } as ApiAnswerOption);
  }

  questionSettingsFromApiToModel(apiQuestionSettings: Partial<ApiQuestionSettings>): Partial<QuestionSettings> {
    const questionSettings: QuestionSettings = {
      columns: apiQuestionSettings.columns,
      columnsTypes: isNotNullNorUndefined(apiQuestionSettings.columnsTypes)
        ? apiQuestionSettings.columnsTypes.map(apiColumnType => this.fieldTypeFromApitoModel(apiColumnType) as CUSTOM_FORM_FIELD_TYPE)
        : null,
      lines: apiQuestionSettings.lines,
      visibleIf: isNotNullNorUndefined(apiQuestionSettings.visibleIf)
        ? apiQuestionSettings.visibleIf.map(apiQuestionSettingsCondition =>
            this.questionSettingsConditionFromApiToModel(apiQuestionSettingsCondition)
          )
        : null,
      calculatedValue: isNotNullNorUndefined(apiQuestionSettings.calculatedValue)
        ? this.questionSettingsCalculationFromApiToModel(apiQuestionSettings.calculatedValue)
        : null,
      min: apiQuestionSettings.min,
      max: apiQuestionSettings.max,
      optionalColumns: apiQuestionSettings.optionalColumns,
      columnsSettings: isNotNullNorUndefined(apiQuestionSettings.columnsSettings)
        ? apiQuestionSettings.columnsSettings.map(
            apiColumnSettings => this.tableQuestionColumnSettingsFromApitoModel(apiColumnSettings) as TableQuestionColumnSettings
          )
        : null,
      cellsSettings: isNotNullNorUndefined(apiQuestionSettings.cellsSettings)
        ? apiQuestionSettings.cellsSettings.map(
            apiCellSettings => this.tableQuestionCellSettingsFromApitoModel(apiCellSettings) as TableQuestionCellSettings
          )
        : null,
      disabledColumns: apiQuestionSettings.disabledColumns,
      lineColors: apiQuestionSettings.lineColors,
      errorIf: isNotNullNorUndefined(apiQuestionSettings.errorIf)
        ? apiQuestionSettings.errorIf.map(apiQuestionSettingsError => this.questionSettingsErrorFromApiToModel(apiQuestionSettingsError))
        : null,
      warningIf: isNotNullNorUndefined(apiQuestionSettings.warningIf)
        ? apiQuestionSettings.warningIf.map(apiQuestionSettingsWarning =>
            this.questionSettingsWarningFromApiToModel(apiQuestionSettingsWarning)
          )
        : null,
      isTotalHidden: apiQuestionSettings.isTotalHidden
    };

    return convertToPartial(questionSettings);
  }

  questionSettingsFromModelToApi(questionSettings: Partial<QuestionSettings>): Partial<ApiQuestionSettings> {
    return convertToPartial({
      columns: questionSettings.columns,
      columnsTypes: isNotNullNorUndefined(questionSettings.columnsTypes)
        ? questionSettings.columnsTypes.map(columnType => this.fieldTypeFromModelToApi(columnType))
        : null,
      lines: questionSettings.lines,
      visibleIf: isNotNullNorUndefined(questionSettings.visibleIf)
        ? questionSettings.visibleIf.map(questionSettingsCondition =>
            this.questionSettingsConditionFromModelToApi(questionSettingsCondition)
          )
        : null,
      calculatedValue: isNotNullNorUndefined(questionSettings.calculatedValue)
        ? this.questionSettingsCalculationFromModelToApi(questionSettings.calculatedValue)
        : null,
      min: questionSettings.min,
      max: questionSettings.max,
      optionalColumns: questionSettings.optionalColumns,
      columnsSettings: isNotNullNorUndefined(questionSettings.columnsSettings)
        ? questionSettings.columnsSettings.map(
            columnSettings => this.tableQuestionColumnSettingsFromModelToApi(columnSettings) as ApiTableQuestionColumnSettings
          )
        : null,
      cellsSettings: isNotNullNorUndefined(questionSettings.cellsSettings)
        ? questionSettings.cellsSettings.map(
            cellSettings => this.tableQuestionCellSettingsFromModelToApi(cellSettings) as ApiTableQuestionCellSettings
          )
        : null,
      disabledColumns: questionSettings.disabledColumns,
      lineColors: questionSettings.lineColors,
      errorIf: isNotNullNorUndefined(questionSettings.errorIf)
        ? questionSettings.errorIf.map(questionSettingsError => this.questionSettingsErrorFromModelToApi(questionSettingsError))
        : null,
      warningIf: isNotNullNorUndefined(questionSettings.warningIf)
        ? questionSettings.warningIf.map(questionSettingsWarning => this.questionSettingsWarningFromModelToApi(questionSettingsWarning))
        : null,
      isTotalHidden: questionSettings.isTotalHidden
    } as ApiQuestionSettings);
  }

  questionSettingsConditionFromApiToModel(apiQuestionSettingsCondition: ApiQuestionSettingsCondition): QuestionSettingsCondition {
    return {
      questionName: apiQuestionSettingsCondition.questionName,
      column: apiQuestionSettingsCondition.column,
      line: apiQuestionSettingsCondition.line,
      expectedValue: apiQuestionSettingsCondition.expectedValue,
      expectedValueQuestion: apiQuestionSettingsCondition.expectedValueQuestion,
      expectedCalculatedValue: isNotNullNorUndefined(apiQuestionSettingsCondition.expectedCalculatedValue)
        ? this.questionSettingsCalculationFromApiToModel(apiQuestionSettingsCondition.expectedCalculatedValue)
        : null,
      operationType: this.questionSettingsConditionOperationTypeFromApitoModel(apiQuestionSettingsCondition.operationType),
      mandatory: apiQuestionSettingsCondition.mandatory
    };
  }

  questionSettingsConditionFromModelToApi(
    questionSettingsCondition: Partial<QuestionSettingsCondition>
  ): Partial<ApiQuestionSettingsCondition> {
    return convertToPartial({
      questionName: questionSettingsCondition.questionName,
      column: questionSettingsCondition.column,
      line: questionSettingsCondition.line,
      expectedValue: questionSettingsCondition.expectedValue,
      expectedValueQuestion: questionSettingsCondition.expectedValueQuestion,
      expectedCalculatedValue: this.questionSettingsCalculationFromModelToApi(questionSettingsCondition.expectedCalculatedValue),
      operationType: this.questionSettingsConditionOperationTypeFromModelToApi(questionSettingsCondition.operationType),
      mandatory: questionSettingsCondition.mandatory
    } as ApiQuestionSettingsCondition);
  }

  questionSettingsConditionOperationTypeFromApitoModel(
    apiQuestionSettingsConditionOperationType: ApiQuestionSettingsConditionOperationType
  ): QuestionSettingsConditionOperationType {
    switch (apiQuestionSettingsConditionOperationType) {
      case ApiQuestionSettingsConditionOperationType.GREATER_THAN:
        return QuestionSettingsConditionOperationType.GREATER_THAN;
      case ApiQuestionSettingsConditionOperationType.LOWER_THAN:
        return QuestionSettingsConditionOperationType.LOWER_THAN;
      case ApiQuestionSettingsConditionOperationType.GREATER_THAN_OR_EQUALS_TO:
        return QuestionSettingsConditionOperationType.GREATER_THAN_OR_EQUALS_TO;
      case ApiQuestionSettingsConditionOperationType.LOWER_THAN_OR_EQUALS_TO:
        return QuestionSettingsConditionOperationType.LOWER_THAN_OR_EQUALS_TO;
      case ApiQuestionSettingsConditionOperationType.EQUALS_TO:
        return QuestionSettingsConditionOperationType.EQUALS_TO;
      case ApiQuestionSettingsConditionOperationType.NOT_EQUALS_TO:
        return QuestionSettingsConditionOperationType.NOT_EQUALS_TO;
      case ApiQuestionSettingsConditionOperationType.IS_NULL:
        return QuestionSettingsConditionOperationType.IS_NULL;
      case ApiQuestionSettingsConditionOperationType.IS_NOT_NULL:
        return QuestionSettingsConditionOperationType.IS_NOT_NULL;
      default:
        console.log(`apiQuestionSettingsConditionOperationType was not recognized: ${apiQuestionSettingsConditionOperationType}`);
        return null;
    }
  }

  questionSettingsConditionOperationTypeFromModelToApi(
    questionSettingsConditionOperationType: QuestionSettingsConditionOperationType
  ): ApiQuestionSettingsConditionOperationType {
    switch (questionSettingsConditionOperationType) {
      case QuestionSettingsConditionOperationType.GREATER_THAN:
        return ApiQuestionSettingsConditionOperationType.GREATER_THAN;
      case QuestionSettingsConditionOperationType.LOWER_THAN:
        return ApiQuestionSettingsConditionOperationType.LOWER_THAN;
      case QuestionSettingsConditionOperationType.GREATER_THAN_OR_EQUALS_TO:
        return ApiQuestionSettingsConditionOperationType.GREATER_THAN_OR_EQUALS_TO;
      case QuestionSettingsConditionOperationType.LOWER_THAN_OR_EQUALS_TO:
        return ApiQuestionSettingsConditionOperationType.LOWER_THAN_OR_EQUALS_TO;
      case QuestionSettingsConditionOperationType.EQUALS_TO:
        return ApiQuestionSettingsConditionOperationType.EQUALS_TO;
      case QuestionSettingsConditionOperationType.NOT_EQUALS_TO:
        return ApiQuestionSettingsConditionOperationType.NOT_EQUALS_TO;
      case QuestionSettingsConditionOperationType.IS_NULL:
        return ApiQuestionSettingsConditionOperationType.IS_NULL;
      case QuestionSettingsConditionOperationType.IS_NOT_NULL:
        return ApiQuestionSettingsConditionOperationType.IS_NOT_NULL;
      default:
        console.log(`questionSettingsConditionOperationType was not recognized: ${questionSettingsConditionOperationType}`);
        return null;
    }
  }

  questionSettingsCalculationFromApiToModel(apiQuestionSettingsCalculation: ApiQuestionSettingsCalculation): QuestionSettingsCalculation {
    return {
      questionsNames: apiQuestionSettingsCalculation.questionsNames,
      questionCells: isNotNullNorUndefined(apiQuestionSettingsCalculation.questionCells)
        ? apiQuestionSettingsCalculation.questionCells.map(apiTableQuestionCell =>
            this.tableQuestionCellFromApiToModel(apiTableQuestionCell)
          )
        : null,
      operationType: this.questionSettingsCalculationOperationTypeFromApitoModel(apiQuestionSettingsCalculation.operationType),
      operationSpecificNumber: apiQuestionSettingsCalculation.operationSpecificNumber
    };
  }

  questionSettingsCalculationFromModelToApi(
    questionSettingsCalculation: Partial<QuestionSettingsCalculation>
  ): Partial<ApiQuestionSettingsCalculation> {
    return convertToPartial({
      questionsNames: questionSettingsCalculation.questionsNames,
      questionCells: isNotNullNorUndefined(questionSettingsCalculation.questionCells)
        ? questionSettingsCalculation.questionCells.map(tableQuestionCell => this.tableQuestionCellFromModelToApi(tableQuestionCell))
        : null,
      operationType: this.questionSettingsCalculationOperationTypeFromModelToApi(questionSettingsCalculation.operationType),
      operationSpecificNumber: questionSettingsCalculation.operationSpecificNumber
    } as ApiQuestionSettingsCalculation);
  }

  questionSettingsCalculationOperationTypeFromApitoModel(
    apiQuestionSettingsCalculationOperationType: ApiQuestionSettingsCalculationOperationType
  ): QuestionSettingsCalculationOperationType {
    switch (apiQuestionSettingsCalculationOperationType) {
      case ApiQuestionSettingsCalculationOperationType.SUM:
        return QuestionSettingsCalculationOperationType.SUM;
      case ApiQuestionSettingsCalculationOperationType.SUM_PLUS_MARGIN:
        return QuestionSettingsCalculationOperationType.SUM_PLUS_MARGIN;
      case ApiQuestionSettingsCalculationOperationType.SUM_DIVIDED_BY_SPECIFIC_NUMBER:
        return QuestionSettingsCalculationOperationType.SUM_DIVIDED_BY_SPECIFIC_NUMBER;
      case ApiQuestionSettingsCalculationOperationType.SUM_MULTIPLED_BY_SPECIFIC_NUMBER:
        return QuestionSettingsCalculationOperationType.SUM_MULTIPLED_BY_SPECIFIC_NUMBER;
      default:
        console.log(`apiQuestionSettingsCalculationOperationType was not recognized: ${apiQuestionSettingsCalculationOperationType}`);
        return null;
    }
  }

  questionSettingsCalculationOperationTypeFromModelToApi(
    questionSettingsCalculationOperationType: QuestionSettingsCalculationOperationType
  ): ApiQuestionSettingsCalculationOperationType {
    switch (questionSettingsCalculationOperationType) {
      case QuestionSettingsCalculationOperationType.SUM:
        return ApiQuestionSettingsCalculationOperationType.SUM;
      case QuestionSettingsCalculationOperationType.SUM_PLUS_MARGIN:
        return ApiQuestionSettingsCalculationOperationType.SUM_PLUS_MARGIN;
      case QuestionSettingsCalculationOperationType.SUM_DIVIDED_BY_SPECIFIC_NUMBER:
        return ApiQuestionSettingsCalculationOperationType.SUM_DIVIDED_BY_SPECIFIC_NUMBER;
      case QuestionSettingsCalculationOperationType.SUM_MULTIPLED_BY_SPECIFIC_NUMBER:
        return ApiQuestionSettingsCalculationOperationType.SUM_MULTIPLED_BY_SPECIFIC_NUMBER;
      default:
        console.log(`questionSettingsCalculationOperationType was not recognized: ${questionSettingsCalculationOperationType}`);
        return null;
    }
  }

  questionSettingsErrorFromApiToModel(apiQuestionSettingsError: ApiQuestionSettingsError): QuestionSettingsError {
    return {
      errorTranslationKey: apiQuestionSettingsError.errorTranslationKey,
      conditions: isNotNullNorUndefined(apiQuestionSettingsError.conditions)
        ? apiQuestionSettingsError.conditions.map(apiQuestionSettingsErrorCondition =>
            this.questionSettingsConditionFromApiToModel(apiQuestionSettingsErrorCondition)
          )
        : null
    };
  }

  questionSettingsErrorFromModelToApi(questionSettingsError: Partial<QuestionSettingsError>): Partial<ApiQuestionSettingsError> {
    return convertToPartial({
      errorTranslationKey: questionSettingsError.errorTranslationKey,
      conditions: isNotNullNorUndefined(questionSettingsError.conditions)
        ? questionSettingsError.conditions.map(questionSettingsErrorCondition =>
            this.questionSettingsConditionFromModelToApi(questionSettingsErrorCondition)
          )
        : null
    } as ApiQuestionSettingsError);
  }

  questionSettingsWarningFromApiToModel(apiQuestionSettingsWarning: ApiQuestionSettingsWarning): QuestionSettingsWarning {
    return {
      warningTranslationKey: apiQuestionSettingsWarning.warningTranslationKey,
      conditions: isNotNullNorUndefined(apiQuestionSettingsWarning.conditions)
        ? apiQuestionSettingsWarning.conditions.map(apiQuestionSettingsWarningCondition =>
            this.questionSettingsConditionFromApiToModel(apiQuestionSettingsWarningCondition)
          )
        : null
    };
  }

  questionSettingsWarningFromModelToApi(questionSettingsWarning: Partial<QuestionSettingsWarning>): Partial<ApiQuestionSettingsWarning> {
    return convertToPartial({
      warningTranslationKey: questionSettingsWarning.warningTranslationKey,
      conditions: isNotNullNorUndefined(questionSettingsWarning.conditions)
        ? questionSettingsWarning.conditions.map(questionSettingsWarningCondition =>
            this.questionSettingsConditionFromModelToApi(questionSettingsWarningCondition)
          )
        : null
    } as ApiQuestionSettingsWarning);
  }

  tableQuestionColumnSettingsFromApitoModel(apiTableQuestionColumnSettings: ApiTableQuestionColumnSettings): TableQuestionColumnSettings {
    return {
      column: apiTableQuestionColumnSettings.column,
      answerOptions: isNotNullNorUndefined(apiTableQuestionColumnSettings.propositions)
        ? apiTableQuestionColumnSettings.propositions.map(apiAnswerOption => this.answerOptionFromApiToModel(apiAnswerOption))
        : null
    };
  }

  tableQuestionColumnSettingsFromModelToApi(
    tableQuestionColumnSettings: TableQuestionColumnSettings
  ): Partial<ApiTableQuestionColumnSettings> {
    return convertToPartial({
      column: tableQuestionColumnSettings.column,
      propositions: isNotNullNorUndefined(tableQuestionColumnSettings.answerOptions)
        ? tableQuestionColumnSettings.answerOptions.map(answerOption => this.answerOptionFromModelToApi(answerOption))
        : null
    } as ApiTableQuestionColumnSettings);
  }

  tableQuestionCellSettingsFromApitoModel(apiTableQuestionCellSettings: ApiTableQuestionCellSettings): TableQuestionCellSettings {
    return {
      column: apiTableQuestionCellSettings.column,
      line: apiTableQuestionCellSettings.line,
      visibleIf: isNotNullNorUndefined(apiTableQuestionCellSettings.visibleIf)
        ? apiTableQuestionCellSettings.visibleIf.map(apiTableQuestionCellSettingsCondition =>
            this.questionSettingsConditionFromApiToModel(apiTableQuestionCellSettingsCondition)
          )
        : null,
      answerOptions: isNotNullNorUndefined(apiTableQuestionCellSettings.propositions)
        ? apiTableQuestionCellSettings.propositions.map(apiAnswerOption => this.answerOptionFromApiToModel(apiAnswerOption))
        : null
    };
  }

  tableQuestionCellSettingsFromModelToApi(tableQuestionCellSettings: TableQuestionCellSettings): Partial<ApiTableQuestionCellSettings> {
    return convertToPartial({
      column: tableQuestionCellSettings.column,
      line: tableQuestionCellSettings.line,
      visibleIf: isNotNullNorUndefined(tableQuestionCellSettings.visibleIf)
        ? tableQuestionCellSettings.visibleIf.map(tableQuestionCellSettingsCondition =>
            this.questionSettingsConditionFromModelToApi(tableQuestionCellSettingsCondition)
          )
        : null,
      propositions: isNotNullNorUndefined(tableQuestionCellSettings.answerOptions)
        ? tableQuestionCellSettings.answerOptions.map(answerOption => this.answerOptionFromModelToApi(answerOption))
        : null
    } as ApiTableQuestionCellSettings);
  }

  tableQuestionCellFromApiToModel(apiTableQuestionCell: ApiTableQuestionCell): TableQuestionCell {
    return {
      questionName: apiTableQuestionCell.questionName,
      column: apiTableQuestionCell.column,
      line: apiTableQuestionCell.line
    };
  }

  tableQuestionCellFromModelToApi(tableQuestionCell: Partial<TableQuestionCell>): Partial<ApiTableQuestionCell> {
    return convertToPartial({
      questionName: tableQuestionCell.questionName,
      column: tableQuestionCell.column,
      line: tableQuestionCell.line
    } as ApiTableQuestionCell);
  }
}
