import { Site } from '@app/core/model';
import { EstablishmentUnit } from '@app/core/model/establishment-unit.model';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { establishmentUnitAdapter, EstablishmentUnitState } from '../reducers/establishment-unit.reducers';
import { selectAllRoles, selectCurrentUserCompaniesId } from './role.selectors';
import { selectAllSites, selectSiteIds } from './site.selectors';
import { Role, RoleStatus } from '@app/core/model/role.model';

export const selectEstablishmentUnitState = createFeatureSelector<EstablishmentUnitState>('establishmentUnits');

const { selectIds, selectEntities, selectAll, selectTotal } = establishmentUnitAdapter.getSelectors();

export const selectEstablishmentUnitIds = createSelector(
  selectEstablishmentUnitState,
  selectIds
);

export const selectEstablishmentUnitEntities = createSelector(
  selectEstablishmentUnitState,
  selectEntities
);

export const selectAllEstablishmentUnits = createSelector(
  selectEstablishmentUnitState,
  selectAll
);

export const selectEstablishmentUnitTotal = createSelector(
  selectEstablishmentUnitState,
  selectTotal
);

export const selectEstablishmentUnitById = () =>
  createSelector(
    selectEstablishmentUnitEntities,
    (entities: Dictionary<EstablishmentUnit>, props: { establishmentUnitId: number }) => entities[props.establishmentUnitId]
  );

export const selectEstablishmentUnitsBySiteId = () =>
  createSelector(
    selectAllEstablishmentUnits,
    (establishmentUnits: EstablishmentUnit[], props: { siteId: number }) =>
      establishmentUnits.filter(establishmentUnit => establishmentUnit.siteId === props.siteId)
  );

export const selectMainEstablishmentUnitBySiteId = () =>
  createSelector(
    selectAllEstablishmentUnits,
    (establishmentUnits: EstablishmentUnit[], props: { siteId: number }) =>
      establishmentUnits.find(establishmentUnit => establishmentUnit.siteId === props.siteId && establishmentUnit.isMainEstablishmentUnit)
  );

export const selectMainEstablishmentUnitsMap = createSelector(
  selectAllEstablishmentUnits,
  selectSiteIds,
  (establishmentUnits: EstablishmentUnit[], siteIds: number[]) => {
    const mainEstablishmentUnits = establishmentUnits.filter(establishmentUnit => establishmentUnit);
    if (mainEstablishmentUnits.length === 0) {
      return new Map<number, EstablishmentUnit>();
    }

    const mainEstablishmentUnitsEntries: ReadonlyArray<[number, EstablishmentUnit]> = siteIds
      .map(siteId => {
        const mainEstablishmentUnit = mainEstablishmentUnits.find(
          establishmentUnit => establishmentUnit.siteId === siteId && establishmentUnit.isMainEstablishmentUnit
        );
        return [siteId, mainEstablishmentUnit] as [number, EstablishmentUnit];
      })
      .filter(entry => !entry.includes(null) && !entry.includes(undefined));
    return new Map<number, EstablishmentUnit>(mainEstablishmentUnitsEntries);
  }
);

export const selectCurrentUserEstablishmentUnits = createSelector(
  selectAllEstablishmentUnits,
  selectCurrentUserCompaniesId,
  (establishmentUnits: EstablishmentUnit[], currentUserCompaniesId: number[]) => {
    return establishmentUnits.filter(establishmentUnit => currentUserCompaniesId.includes(establishmentUnit.companyId));
  }
);

export const selectValidatedEstablishmentsUnits = createSelector(
  selectAllRoles,
  selectCurrentUserEstablishmentUnits,
  (roles: Role[], currentUserEstablishmentUnits: EstablishmentUnit[]) => {
    const establishmentUnitsCompanies = currentUserEstablishmentUnits.map(establishmentUnit => establishmentUnit.companyId);
    const currentRoles = roles.filter(
      role =>
        establishmentUnitsCompanies.includes(role.companyId) && (role.status === RoleStatus.Affiliated || role.status === RoleStatus.Admin)
    );
    return currentUserEstablishmentUnits.filter(establishmentUnit =>
      currentRoles.map(role => role.companyId).includes(establishmentUnit.companyId)
    );
  }
);

export const selectCurrentUserSites = createSelector(
  selectAllSites,
  selectValidatedEstablishmentsUnits,
  (sites: Site[], currentUserEstablishmentUnits: EstablishmentUnit[]) => {
    const currentUserSitesId = currentUserEstablishmentUnits.map(eu => eu.siteId);
    return sites.filter(site => currentUserSitesId.includes(site.id));
  }
);
