import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { isNullOrUndefined } from 'util';

export interface CustomTableAction {
  name: string;
  iconName: string;
  iconNameWhenHover?: string;
  color?: string;
  tooltip?: string;
  tooltipWhenDisabled?: string;
  isDisabled?(element: CustomTableDataType): boolean;
  isHidden?(element: CustomTableDataType): boolean;
}

export interface CustomTableActionEvent {
  actionName: string;
  row: any;
}

export interface ColumnSettings {
  key: string;
  headerText: string;
  isAction?: boolean;
  color?: string;
  conditionalColor?: (element: CustomTableDataType) => string;
  isBold?: boolean;
  prefixIconName?: (element: CustomTableDataType) => string;
}

export interface CustomTableDataType {
  id: number;
}

export abstract class CustomTableExpandableDataType implements CustomTableDataType {
  id: number;
  expandableDataSource: any[] = [];

  constructor(id: number) {
    this.id = id;
  }
}

const expandableDataTypeAttributes = ['expandableDataSource'];

export class CustomTableSettings {
  displayedColumns: string[] = [];
  columnSettings: ColumnSettings[] = [];
  dataSource: CustomTableDataType[] = [];
  emptyDataSourceText = 'No data to show';
  actions: CustomTableAction[] = [];
  hasSelectableRows = false;
  allowMultiSelect = true;
  allowNoSelection = true;
  selectionColumnHeaderText: string;
  selectedElementsIds: number[] = [];
  isSelectionReadOnly = false;
  isRowReadOnly?: (element: CustomTableDataType) => boolean;
  isTableExpandable = false; // TODO: Make this attribute constant
}

export class CustomExpandableTableSettings extends CustomTableSettings {
  dataSource: CustomTableExpandableDataType[] = [];
  emptyExpandableDataSourceText = 'No expandable data to show';
  expandedRowDisplayedColumns: string[] = [];
  expandedTableActions: CustomTableAction[] = [];
  isExpandIndicatorHidden: (element: CustomTableExpandableDataType) => boolean;
  isTableExpandable = true;

  constructor() {
    super();
  }
}

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandIndicatorRotate', [
      state('collapsed, void', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(90deg)' })),
      transition('expanded <=> collapsed, void => collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', minHeight: '48px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class CustomTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() settings: CustomTableSettings = new CustomTableSettings();
  @Output() selectionChanged = new EventEmitter<CustomTableDataType[]>();
  @Output() actionTriggered = new EventEmitter<CustomTableActionEvent>();

  selection: SelectionModel<CustomTableDataType> = new SelectionModel<CustomTableDataType>(true, []);
  actionColumnKey = 'customTableAction';
  expandIndicatorColumnKey = 'customTableExpandIndicator';
  selectionColumnKey = 'customTableSelection';
  expandedRowId: number;
  maximumNumberOfVisibleActions = 0;

  // MatPaginator Inputs
  currentPageDataSource: CustomTableDataType[] = [];
  readonly minimumToDisplayPaginator: number = 25;
  pageSize = 25;
  pageSizeOptions: number[] = [25, 50];

  subSink = new SubSink();

  constructor(private translateService: TranslateService) {}

  ngOnInit() {}

  updateCurrentPageDataSource(pageSize: number, pageIndex: number): void {
    this.pageSize = pageSize;

    const startIndex: number = pageIndex * pageSize;
    const endIndex: number = startIndex + pageSize;

    this.currentPageDataSource = this.settings.dataSource.filter((value, index) => index >= startIndex && index < endIndex);
  }

  updateCurrentPageDataSourceWithPageEvent(pageEvent: PageEvent): void {
    this.updateCurrentPageDataSource(pageEvent.pageSize, pageEvent.pageIndex);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.subSink.unsubscribe();

    this.updateCurrentPageDataSource(this.pageSize, 0);

    const selectedElementsIds = this.settings.selectedElementsIds;
    const selectedElements: CustomTableDataType[] = this.settings.dataSource.filter(element => selectedElementsIds.includes(element.id));

    this.selection = new SelectionModel<CustomTableDataType>(this.settings.allowMultiSelect, selectedElements);

    this.subSink.sink = this.selection.changed.subscribe(changes => {
      this.selectionChanged.emit(this.selection.selected);
    });

    this.maximumNumberOfVisibleActions = this.getMaximumNumberOfVisibleActions();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  getDataSourceKeys(): string[] {
    return this.isDataSourceEmpty()
      ? []
      : Object.keys(this.settings.dataSource[0]).filter(key => !expandableDataTypeAttributes.includes(key));
  }

  getColumnHeaderText(columnKey: string): string {
    const columnSettings = this.settings.columnSettings.find(x => x.key === columnKey);
    return columnSettings ? columnSettings.headerText : columnKey;
  }

  isColumnAction(columnKey: string): boolean {
    const columnSettings = this.settings.columnSettings.find(x => x.key === columnKey);
    return columnSettings && columnSettings.isAction ? true : false;
  }

  isDataSourceEmpty(): boolean {
    return this.settings.dataSource.length === 0;
  }

  getDisplayedColumns(): string[] {
    let displayedColumns = [];

    if (this.isTableExpandable()) {
      displayedColumns = [...displayedColumns, this.expandIndicatorColumnKey];
    }

    displayedColumns = [...displayedColumns, ...this.settings.displayedColumns];

    if (!this.isActionListEmpty()) {
      displayedColumns = [...displayedColumns, this.actionColumnKey];
    }

    if (this.hasTableSelectableRows()) {
      displayedColumns = [...displayedColumns, this.selectionColumnKey];
    }

    return displayedColumns;
  }

  // Actions

  triggerAction(actionName: string, row: CustomTableDataType): void {
    this.actionTriggered.emit({ actionName, row });
  }

  isActionListEmpty(): boolean {
    if (!this.settings.actions || this.settings.actions.length === 0) {
      return true;
    }
    return false;
  }

  isActionHidden(element: CustomTableDataType, action: CustomTableAction): boolean {
    if (!action.isHidden) {
      return false;
    }
    return action.isHidden(element);
  }

  isActionDisabled(element: CustomTableDataType, action: CustomTableAction): boolean {
    if (!action.isDisabled) {
      return false;
    }
    return action.isDisabled(element);
  }

  hasActionTooltip(element: CustomTableDataType, action: CustomTableAction): boolean {
    return isNotNullNorUndefined(this.getActionTooltip(element, action));
  }

  getActionTooltip(element: CustomTableDataType, action: CustomTableAction): string {
    const textToTranslate: string = this.isActionDisabled(element, action)
      ? action.tooltipWhenDisabled
        ? action.tooltipWhenDisabled
        : action.tooltip
      : action.tooltip;

    if (isNullOrUndefined(textToTranslate)) {
      return null;
    }

    return this.translateService.instant(textToTranslate);
  }

  getNumberOfVisibleActions(element: CustomTableDataType): number {
    const isHiddenFunctionsList = this.settings.actions.map(action => {
      return action.isHidden ? action.isHidden : () => false;
    });

    let numberVisibleActions = 0;
    isHiddenFunctionsList.forEach(isHiddenFunction => {
      if (!isHiddenFunction(element)) {
        numberVisibleActions++;
      }
    });

    return numberVisibleActions;
  }

  /** Return the number of visible actions of the inner element with
   *  the most actions or the number of actions in the element if it's higher
   */
  getMaximumNumberOfVisibleActionsInExpandableElement(element: CustomTableExpandableDataType): number {
    let maxNumberVisibleActions = this.getNumberOfVisibleActions(element);

    if (!this.isTableExpandable()) {
      return maxNumberVisibleActions;
    }

    const isHiddenFunctionsList = (this.settings as CustomExpandableTableSettings).expandedTableActions.map(action => {
      return action.isHidden ? action.isHidden : () => false;
    });

    element.expandableDataSource.forEach(innerElement => {
      let numberVisibleActions = 0;
      isHiddenFunctionsList.forEach(isHiddenFunction => {
        if (!isHiddenFunction(innerElement)) {
          numberVisibleActions++;
        }
      });

      if (numberVisibleActions > maxNumberVisibleActions) {
        maxNumberVisibleActions = numberVisibleActions;
      }
    });

    return maxNumberVisibleActions;
  }

  /** Return the number of visible actions of the element with the most actions */
  getMaximumNumberOfVisibleActions(): number {
    let maxNumberVisibleActions = 0;

    this.settings.dataSource.forEach(element => {
      let numberVisibleActions = 0;

      if (this.isTableExpandable()) {
        numberVisibleActions = this.getMaximumNumberOfVisibleActionsInExpandableElement(element as CustomTableExpandableDataType);
      } else {
        numberVisibleActions = this.getNumberOfVisibleActions(element);
      }

      if (numberVisibleActions > maxNumberVisibleActions) {
        maxNumberVisibleActions = numberVisibleActions;
      }
    });

    return maxNumberVisibleActions;
  }

  // Expandable table

  isTableExpandable(): boolean {
    return this.settings.isTableExpandable;
  }

  expandRow(row: CustomTableDataType): void {
    this.expandedRowId = this.expandedRowId === row.id ? null : row.id;
  }

  isColumnDisplayedInExpandableTable(columnKey: string): boolean {
    return this.isTableExpandable() && (this.settings as CustomExpandableTableSettings).expandedRowDisplayedColumns.includes(columnKey);
  }

  isExpandableDataSourceEmpty(element: CustomTableExpandableDataType): boolean {
    return element.expandableDataSource.length === 0;
  }

  getExpandableDataSource(element: CustomTableExpandableDataType): any[] {
    return this.isExpandableDataSourceEmpty(element) ? [] : element.expandableDataSource;
  }

  getEmptyExpandableDataSourceText(): string {
    return this.isTableExpandable() ? (this.settings as CustomExpandableTableSettings).emptyExpandableDataSourceText : null;
  }

  getExpandedTableActions(): CustomTableAction[] {
    return this.isTableExpandable() ? (this.settings as CustomExpandableTableSettings).expandedTableActions : [];
  }

  isExpandIndicatorHidden(element: CustomTableExpandableDataType): boolean {
    if (this.isTableExpandable()) {
      const isExpandIndicatorHiddenFunction = (this.settings as CustomExpandableTableSettings).isExpandIndicatorHidden;
      return isExpandIndicatorHiddenFunction ? isExpandIndicatorHiddenFunction(element) : false;
    }
    return true;
  }

  // Custom color

  getCustomColor(columnKey: string, element: any): string {
    if (!element) {
      return;
    }

    const columnSettings = this.settings.columnSettings.find(settings => settings.key === columnKey);
    if (!columnSettings) {
      throw new Error(`Settings for column '${columnKey}' not found!`);
    }

    let color;
    if (columnSettings.conditionalColor) {
      color = columnSettings.conditionalColor(element);
    } else {
      color = columnSettings.color;
    }

    return color;
  }

  getCustomColorClassName(columnKey: string, element: any): string {
    const customColor = this.getCustomColor(columnKey, element);
    switch (customColor) {
      case 'primary':
        return 'primary-color';
      case 'success':
        return 'success-color';
      case 'incomplete':
        return 'incomplete-color';
      case 'expired':
        return 'expired-color';
      default:
        return;
    }
  }

  isBold(columnKey: string): boolean {
    const columnSettings = this.settings.columnSettings.find(settings => settings.key === columnKey);
    if (!columnSettings) {
      throw new Error(`Settings for column '${columnKey}' not found!`);
    }

    return columnSettings.isBold;
  }

  getPrefixIconName(columnKey: string, element: any): string {
    if (!element) {
      return;
    }

    const columnSettings = this.settings.columnSettings.find(settings => settings.key === columnKey);
    if (!columnSettings) {
      throw new Error(`Settings for column '${columnKey}' not found!`);
    }

    return columnSettings.prefixIconName ? columnSettings.prefixIconName(element) : null;
  }

  // Selection

  hasTableSelectableRows(): boolean {
    return this.settings.hasSelectableRows;
  }

  checkboxAriaLabel(row: CustomTableDataType): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  isOnlyElementSelected(element: CustomTableDataType): boolean {
    return this.selection.isSelected(element) && this.selection.selected.length === 1;
  }

  isRowReadOnly(row: CustomTableDataType): boolean {
    return isNotNullNorUndefined(this.settings.isRowReadOnly) && this.settings.isRowReadOnly(row);
  }
}
