import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { MyAccountDetailsComponent } from './components';
// tslint:disable-next-line: max-line-length
import { AffiliationApplicationDialogComponent } from './components/affiliation-application-dialog/affiliation-application-dialog.component';
import { MyAffiliationsComponent } from './components/my-affiliations/my-affiliations.component';
import { UserContactDetailsDialogComponent } from './components/user-contact-details-dialog/user-contact-details-dialog.component';
import { MyAccountContainerComponent } from './containers';
// tslint:disable-next-line: max-line-length
import { AffiliationApplicationDialogContainerComponent } from './containers/affiliation-application-dialog-container/affiliation-application-dialog.container';
import { UserContactDetailsDialogContainerComponent } from './containers/user-contact-details-dialog-container/user-contact-details-dialog-container.component';
import { MyAccountRoutingModule } from './my-account-routing.module';
import { AffiliationPendingMessageComponent } from './components/affiliation-pending-message/affiliation-pending-message.component';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { ChangePasswordDialogContainerComponent } from './containers/change-password-dialog-container/change-password-dialog.container';

@NgModule({
  declarations: [
    MyAccountContainerComponent,
    MyAccountDetailsComponent,
    MyAffiliationsComponent,
    AffiliationApplicationDialogComponent,
    AffiliationApplicationDialogContainerComponent,
    UserContactDetailsDialogContainerComponent,
    UserContactDetailsDialogComponent,
    AffiliationPendingMessageComponent,
    ChangePasswordDialogContainerComponent,
    ChangePasswordDialogComponent
  ],
  imports: [CommonModule, MyAccountRoutingModule, SharedModule],
  entryComponents: [
    AffiliationApplicationDialogContainerComponent,
    UserContactDetailsDialogContainerComponent,
    ChangePasswordDialogContainerComponent
  ]
})
export class MyAccountModule {}
