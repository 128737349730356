import { Site } from '@app/core/model/site.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SiteActions, SiteActionTypes } from '../actions/site.actions';

export interface SiteState extends EntityState<Site> {
  // additional entity state properties
}

export const siteAdapter: EntityAdapter<Site> = createEntityAdapter<Site>();

const initialState: SiteState = siteAdapter.getInitialState({
  // additional entity state properties
});

export function siteReducer(state = initialState, action: SiteActions): SiteState {
  switch (action.type) {
    case SiteActionTypes.GET_ALL_SITES_SUCCESS: {
      return siteAdapter.addAll(action.sites, state);
    }
    case SiteActionTypes.GET_SITE_SUCCESS: {
      return siteAdapter.addOne(action.site, state);
    }
    case SiteActionTypes.ADD_SITE_SUCCESS: {
      return siteAdapter.addOne(action.site, state);
    }
    case SiteActionTypes.UPDATE_SITE_SUCCESS: {
      return siteAdapter.updateOne(action.update, state);
    }
    case SiteActionTypes.DELETE_SITE_SUCCESS: {
      return siteAdapter.removeOne(action.siteId, state);
    }
    default: {
      return state;
    }
  }
}
