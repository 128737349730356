export class Address {
  constructor(
    public id: number,
    public streetFR: string,
    public streetNL: string,
    public streetNumber: string,
    public box: string,
    public postalCode: number,
    public localityFR: string,
    public localityNL: string
  ) {}
}
