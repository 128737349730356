<div mat-dialog-content fxLayout="column">
  <p class="confirmation-text">{{ getText() | translate }}</p>
</div>

<div mat-dialog-actions fxLayout="row">
  <button mat-stroked-button [color]="getColor()" [mat-dialog-close]="false" fxFlex="1 0 auto">
    {{ 'i18n.Common.cancel' | translate }}
  </button>
  <button mat-flat-button [color]="getColor()" [mat-dialog-close]="true" cdkFocusInitial fxFlex="1 0 auto">
    {{ 'i18n.Common.confirm' | translate }}
  </button>
</div>
