import { TravelPlan } from '@app/core/model';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

export enum TravelPlanActionTypes {
  GET_ALL_TRAVEL_PLANS = '[TravelPlan] Get All Travel Plans',
  GET_ALL_TRAVEL_PLANS_SUCCESS = '[TravelPlan] Get All Travel Plans Success',
  GET_ALL_TRAVEL_PLANS_FAILURE = '[TravelPlan] Get All Travel Plans Failure',
  GET_TRAVEL_PLAN = '[TravelPlan] Get Travel Plan',
  GET_TRAVEL_PLAN_SUCCESS = '[TravelPlan] Get Travel Plan Success',
  GET_TRAVEL_PLAN_FAILURE = '[TravelPlan] Get Travel Plan Failure',
  GET_TRAVEL_PLAN_BY_SITE_ID_AND_FORM_ID = '[TravelPlan] Get Travel Plan By SiteId and FormId',
  GET_TRAVEL_PLANS_BY_SITE_ID = '[TravelPlan] Get Travel Plans By SiteId',
  GET_TRAVEL_PLANS_BY_SITE_ID_SUCCESS = '[TravelPlan] Get Travel Plans By SiteId Success',
  GET_TRAVEL_PLANS_BY_SITE_ID_FAILURE = '[TravelPlan] Get Travel Plans By SiteId Failure',
  ADD_TRAVEL_PLAN = '[TravelPlan] Add Travel Plan',
  ADD_TRAVEL_PLAN_SUCCESS = '[TravelPlan] Add Travel Plan Success',
  ADD_TRAVEL_PLAN_FAILURE = '[TravelPlan] Add Travel Plan Failure',
  UPDATE_TRAVEL_PLAN = '[TravelPlan] Update Travel Plan',
  UPDATE_TRAVEL_PLAN_SUCCESS = '[TravelPlan] Update Travel Plan Success',
  UPDATE_TRAVEL_PLAN_FAILURE = '[TravelPlan] Update Travel Plan Failure',
  UPDATE_TRAVEL_PLAN_IMPORT_WORKER_RATIO = '[TravelPlan] Update Travel Plan Import Worker Ratio',
  ACCEPT_TRAVEL_PLAN = '[TravelPlan] Accept Travel Plan',
  ACCEPT_TRAVEL_PLAN_SUCCESS = '[TravelPlan] Accept Travel Plan Success',
  ACCEPT_TRAVEL_PLAN_FAILURE = '[TravelPlan] Accept Travel Plan Failure',
  DELETE_TRAVEL_PLAN = '[TravelPlan] Delete Travel Plan',
  DELETE_TRAVEL_PLAN_SUCCESS = '[TravelPlan] Delete Travel Plan Success',
  DELETE_TRAVEL_PLAN_FAILURE = '[TravelPlan] Delete Travel Plan Failure',
  COPY_TRAVEL_PLAN = '[TravelPlan] Copy Travel Plan',
  COPY_TRAVEL_PLAN_SUCCESS = '[TravelPlan] Copy Travel Plan Success',
  COPY_TRAVEL_PLAN_FAILURE = '[TravelPlan] Copy Travel Plan Failure',
  UPDATE_COMMENT_TRAVEL_PLAN_SUCCESS = '[TravelPlan] Update comment in Travel Plan Success'
}

export class GetAllTravelPlans implements Action {
  readonly type = TravelPlanActionTypes.GET_ALL_TRAVEL_PLANS;
}

export class GetAllTravelPlansSuccess implements Action {
  readonly type = TravelPlanActionTypes.GET_ALL_TRAVEL_PLANS_SUCCESS;
  constructor(public travelPlans: TravelPlan[]) {}
}

export class GetAllTravelPlansFailure implements Action {
  readonly type = TravelPlanActionTypes.GET_ALL_TRAVEL_PLANS_FAILURE;
}

export class GetTravelPlan implements Action {
  readonly type = TravelPlanActionTypes.GET_TRAVEL_PLAN;
  constructor(public travelPlanId: number) {}
}

export class GetTravelPlanSuccess implements Action {
  readonly type = TravelPlanActionTypes.GET_TRAVEL_PLAN_SUCCESS;
  constructor(public travelPlan: TravelPlan) {}
}

export class GetTravelPlanFailure implements Action {
  readonly type = TravelPlanActionTypes.GET_TRAVEL_PLAN_FAILURE;
}

export class GetTravelPlanBySiteIdAndFormId implements Action {
  readonly type = TravelPlanActionTypes.GET_TRAVEL_PLAN_BY_SITE_ID_AND_FORM_ID;
  constructor(public siteId: number, public formId: number) {}
}

export class GetTravelPlansBySiteId implements Action {
  readonly type = TravelPlanActionTypes.GET_TRAVEL_PLANS_BY_SITE_ID;
  constructor(public siteId: number) {}
}

export class GetTravelPlansBySiteIdSuccess implements Action {
  readonly type = TravelPlanActionTypes.GET_TRAVEL_PLANS_BY_SITE_ID_SUCCESS;
  constructor(public travelPlans: TravelPlan[]) {}
}

export class GetTravelPlansBySiteIdFailure implements Action {
  readonly type = TravelPlanActionTypes.GET_TRAVEL_PLANS_BY_SITE_ID_FAILURE;
}

export class UpdateTravelPlan implements Action {
  readonly type = TravelPlanActionTypes.UPDATE_TRAVEL_PLAN;
  constructor(public update: Update<TravelPlan>) {}
}

export class UpdateTravelPlanSuccess implements Action {
  readonly type = TravelPlanActionTypes.UPDATE_TRAVEL_PLAN_SUCCESS;
  constructor(public update: Update<TravelPlan>) {}
}

export class UpdateTravelPlanFailure implements Action {
  readonly type = TravelPlanActionTypes.UPDATE_TRAVEL_PLAN_FAILURE;
}

export class AcceptTravelPlan implements Action {
  readonly type = TravelPlanActionTypes.ACCEPT_TRAVEL_PLAN;
  constructor(public travelPlanId: number, public file: File) {}
}

export class AcceptTravelPlanSuccess implements Action {
  readonly type = TravelPlanActionTypes.ACCEPT_TRAVEL_PLAN_SUCCESS;
  constructor(public travelPlanId: number) {}
}

export class AcceptTravelPlanFailure implements Action {
  readonly type = TravelPlanActionTypes.ACCEPT_TRAVEL_PLAN_FAILURE;
}

export class DeleteTravelPlan implements Action {
  readonly type = TravelPlanActionTypes.DELETE_TRAVEL_PLAN;
  constructor(public id: number) {}
}

export class DeleteTravelPlanSuccess implements Action {
  readonly type = TravelPlanActionTypes.DELETE_TRAVEL_PLAN_SUCCESS;
  constructor(public id: number) {}
}

export class DeleteTravelPlanFailure implements Action {
  readonly type = TravelPlanActionTypes.DELETE_TRAVEL_PLAN_FAILURE;
}

export class CopyTravelPlan implements Action {
  readonly type = TravelPlanActionTypes.COPY_TRAVEL_PLAN;
  constructor(public oldPlanId: number, public formId: number, public siteId: number) {}
}

export class CopyTravelPlanSuccess implements Action {
  readonly type = TravelPlanActionTypes.COPY_TRAVEL_PLAN_SUCCESS;
  constructor(public id: number) {}
}

export class CopyTravelPlanFailure implements Action {
  readonly type = TravelPlanActionTypes.COPY_TRAVEL_PLAN_FAILURE;
}

export class AddTravelPlan implements Action {
  readonly type = TravelPlanActionTypes.ADD_TRAVEL_PLAN;
  constructor(public travelPlan: TravelPlan) {}
}

export class UpdateCommentInTravelPlanSuccess implements Action {
  readonly type = TravelPlanActionTypes.UPDATE_COMMENT_TRAVEL_PLAN_SUCCESS;
  constructor(public update: any) {}
}

export class AddTravelPlanSuccess implements Action {
  readonly type = TravelPlanActionTypes.ADD_TRAVEL_PLAN_SUCCESS;
  constructor(public travelPlan: TravelPlan) {}
}

export class AddTravelPlanFailure implements Action {
  readonly type = TravelPlanActionTypes.ADD_TRAVEL_PLAN_FAILURE;
}

export class UpdateTravelPlanWorkerRatio implements Action {
  readonly type = TravelPlanActionTypes.UPDATE_TRAVEL_PLAN_IMPORT_WORKER_RATIO;
  constructor(public travelPlanId: number, public trav: number, public ratio: number) {}
}

export type TravelPlanActions =
  | AddTravelPlan
  | AddTravelPlanSuccess
  | AddTravelPlanFailure
  | GetAllTravelPlans
  | GetAllTravelPlansSuccess
  | GetAllTravelPlansFailure
  | GetTravelPlan
  | GetTravelPlanSuccess
  | GetTravelPlanFailure
  | GetTravelPlanBySiteIdAndFormId
  | GetTravelPlansBySiteId
  | GetTravelPlansBySiteIdSuccess
  | GetTravelPlansBySiteIdFailure
  | UpdateTravelPlan
  | UpdateTravelPlanSuccess
  | UpdateCommentInTravelPlanSuccess
  | UpdateTravelPlanFailure
  | UpdateTravelPlanWorkerRatio
  | AcceptTravelPlan
  | AcceptTravelPlanSuccess
  | AcceptTravelPlanFailure
  | DeleteTravelPlan
  | DeleteTravelPlanSuccess
  | DeleteTravelPlanFailure
  | CopyTravelPlan
  | CopyTravelPlanSuccess
  | CopyTravelPlanFailure;
