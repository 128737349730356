import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User, UserType } from '@app/core/model';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { LEGAL_NOTICES_FR_PDF_URL, LEGAL_NOTICES_NL_PDF_URL } from './core/components/legal-notices-dialog/legal-notices-dialog.component';
import { currentTokenCookieName } from './core/interceptors';
import { AuthService, SiteService, SurveyService, UserService } from './core/services';
import { LoginDialogContainerComponent } from './modules/auth';
import { UserContactDetailsDialogContainerComponent } from './modules/my-account/containers/user-contact-details-dialog-container/user-contact-details-dialog-container.component';

export const currentLanguageCookieName = 'currentLanguage';
const numberOfDaysExpirationCurrentLanguageCookie = 30;
const cookiesPath = '/';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  currentUser$: Observable<User>;

  currentLanguage: string;
  currentUserType: UserType;
  helpContactUser: User = new User();

  subSink = new SubSink();

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private dialogService: MatDialog,
    private authService: AuthService,
    private userService: UserService,
    private siteService: SiteService,
    private surveyService: SurveyService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this._changeToDefaultLanguage();

    this.authService.loadCurrentUser();
    this.currentUser$ = this.userService.getCurrentUser();

    this.authService.getCurrentUserType().subscribe(currentUserType => {
      this.currentUserType = currentUserType;
    });

    this.subSink.sink = this.translateService
      .stream('i18n.AppComponent.helpContactUser.name')
      .subscribe(name => (this.helpContactUser.lastName = name));

    this.subSink.sink = this.translateService
      .stream('i18n.AppComponent.helpContactUser.email')
      .subscribe(email => (this.helpContactUser.emailAddress = email));

    this.subSink.sink = this.translateService
      .stream('i18n.AppComponent.helpContactUser.phoneNumber')
      .subscribe(phoneNumber => (this.helpContactUser.phoneNumber = phoneNumber));
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  changeLanguage(newLanguage: string): void {
    this.translateService.use(newLanguage).subscribe(_ => {
      this.currentLanguage = this.translateService.currentLang;
      // remove defaultLang each time it's set again
      this.translateService.defaultLang = null;

      this._setCurrentLanguageCookie(newLanguage);
    });
  }

  isCurrentLanguageFrench(): boolean {
    return this.currentLanguage === 'fr';
  }

  isCurrentLanguageDutch(): boolean {
    return this.currentLanguage === 'nl';
  }

  openLoginDialog(): void {
    const loginDialogRef: MatDialogRef<LoginDialogContainerComponent> = this.dialogService.open(LoginDialogContainerComponent, {
      width: '90%',
      maxWidth: '640px',
      minWidth: '320px'
    });
  }

  openLegalNoticesDialog(): void {
    window.open(this.isCurrentLanguageDutch() ? LEGAL_NOTICES_NL_PDF_URL : LEGAL_NOTICES_FR_PDF_URL, '_blank');
  }

  openHelpContactDetailsDialog(): void {
    const userContactDetailsDialogRef: MatDialogRef<UserContactDetailsDialogContainerComponent> = this.dialogService.open(
      UserContactDetailsDialogContainerComponent,
      {
        width: '400px',
        data: this.helpContactUser
      }
    );
  }

  navigateToHome(): void {
    this.router.navigate(['/home']);
  }

  navigateToFaq(): void {
    this.router.navigate(['/faq']);
  }

  navigateToSites(): void {
    this.siteService.navigateToSitesAccordingToCurrentUserType(this.currentUserType);
  }

  navigateToGismob(): void {
    const token: string = this.cookieService.get(currentTokenCookieName);
    this.surveyService.navigateToGismob(token);
  }

  navigateToMyAccount(): void {
    this.authService.navigateToMyAccount();
  }

  private _changeToDefaultLanguage(): void {
    if (this._hasCurrentLanguageCookie()) {
      const currentLanguageCookieValue: string = this._getCurrentLanguageCookie();
      this.changeLanguage(currentLanguageCookieValue);
    } else if (window.navigator.language.slice(0, 2) === 'nl') {
      this.changeLanguage('nl');
    } else {
      this.changeLanguage('fr');
    }
  }

  private _hasCurrentLanguageCookie(): boolean {
    return this.cookieService.check(currentLanguageCookieName);
  }

  private _setCurrentLanguageCookie(newLanguage: string): void {
    this.cookieService.set(currentLanguageCookieName, newLanguage, numberOfDaysExpirationCurrentLanguageCookie, cookiesPath);
  }

  private _getCurrentLanguageCookie(): string {
    const possibleLanguages: string[] = ['fr', 'nl'];
    const currentLanguageCookieValue: string = this.cookieService.get(currentLanguageCookieName);
    return possibleLanguages.includes(currentLanguageCookieValue) ? currentLanguageCookieValue : 'fr';
  }
}
