import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Company, User } from '@app/core/model';
import { Role, RoleStatus } from '@app/core/model/role.model';
import { AuthService, CompanyService, UserService } from '@app/core/services';
import { RoleService } from '@app/core/services/role.service';
import { ConfirmationDialogService } from '@app/shared/services/confirmation-dialog.service';
import { isNotNullNorUndefined } from '@app/shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { AffiliationApplicationDialogContainerComponent } from '../affiliation-application-dialog-container/affiliation-application-dialog.container';
// tslint:disable-next-line: max-line-length
import { UserContactDetailsDialogContainerComponent } from '../user-contact-details-dialog-container/user-contact-details-dialog-container.component';
import { ChangePasswordDialogContainerComponent } from './../change-password-dialog-container/change-password-dialog.container';

@Component({
  selector: 'app-my-account-container',
  templateUrl: './my-account.container.html',
  styleUrls: ['./my-account.container.scss']
})
export class MyAccountContainerComponent implements OnInit {
  currentUser$: Observable<User>;
  currentUserRoles$: Observable<Role[]>;
  currentUserCompanies$: Observable<Company[]>;
  allRolesFromCurrentUserCompanies$: Observable<Role[]>;
  allUsersFromCurrentUserCompanies$: Observable<User[]>;
  isCurrentUserCompanyAdmin$: Observable<boolean>;
  isPendingRolesFromCompaniesWhereCurrentUserIsAdmin$: Observable<boolean>;
  numberOfPendingRolesFromCompaniesWhereCurrentUserIsAdmin$: Observable<number>;
  pendingRolesFromCompaniesWhereCurrentUserIsAdmin$: Observable<Role[]>;

  constructor(
    private dialogService: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    private authService: AuthService,
    private userService: UserService,
    private roleService: RoleService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.userService.loadAllUsers();
    this.companyService.loadAllCompanies();
    this.roleService.loadAllRoles();

    this.currentUser$ = this.userService.getCurrentUser();
    this.currentUserRoles$ = this.roleService.getCurrentUserRoles();
    this.currentUserCompanies$ = this.companyService.getCurrentUserCompanies();
    this.allRolesFromCurrentUserCompanies$ = this.roleService.getAllRolesFromCurrentUserCompanies();
    this.allUsersFromCurrentUserCompanies$ = this.userService.getAllUsersFromCurrentUserCompanies();

    this.isCurrentUserCompanyAdmin$ = this.roleService.getCurrentUserRoles().pipe(
      map(roles => {
        return isNotNullNorUndefined(roles.find(role => role.status === RoleStatus.Admin));
      })
    );

    this.isPendingRolesFromCompaniesWhereCurrentUserIsAdmin$ = this.roleService
      .getPendingRolesFromCompaniesWhereCurrentUserIsAdmin()
      .pipe(map(pendingRoles => pendingRoles.length !== 0));

    this.numberOfPendingRolesFromCompaniesWhereCurrentUserIsAdmin$ = this.roleService
      .getPendingRolesFromCompaniesWhereCurrentUserIsAdmin()
      .pipe(map(pendingRoles => pendingRoles.length));

    this.pendingRolesFromCompaniesWhereCurrentUserIsAdmin$ = this.roleService.getPendingRolesFromCompaniesWhereCurrentUserIsAdmin();
  }

  logout(): void {
    this.authService.logout();
  }

  saveAccountDetails(user: User): void {
    this.userService.updateUser(user);
  }

  deleteAccount(): void {
    this.confirmationDialogService
      .openConfirmationDialog('i18n.MyAccountContainerComponent.delete-account-confirmation-text', 'warn')
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.authService.deleteAccount();
        }
      });
  }

  openAffiliationApplicationDialog(): void {
    const affiliationApplicationDialogRef: MatDialogRef<AffiliationApplicationDialogContainerComponent> = this.dialogService.open(
      AffiliationApplicationDialogContainerComponent,
      {
        width: '90%',
        maxWidth: '1050px',
        minWidth: '320px'
      }
    );
  }

  openUserContactDetailsDialog(user: User): void {
    const userContactDetailsDialogRef: MatDialogRef<UserContactDetailsDialogContainerComponent> = this.dialogService.open(
      UserContactDetailsDialogContainerComponent,
      {
        width: '400px',
        data: user
      }
    );
  }

  deleteAffiliation(roleId: number): void {
    this.confirmationDialogService
      .openConfirmationDialog('i18n.MyAccountContainerComponent.delete-affiliation-confirmation-text', 'warn')
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.roleService.deleteRole(roleId);
        }
      });
  }

  promoteUser(role: Partial<Role>): void {
    this.confirmationDialogService
      .openConfirmationDialog('i18n.MyAccountContainerComponent.promote-user-confirmation-text')
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.roleService.promoteUser(role.id, { status: RoleStatus.Admin });
        }
      });
  }

  acceptPendingApplication(roleId: number): void {
    this.roleService.patchRole(roleId, { status: RoleStatus.Affiliated });
  }

  declinePendingApplication(roleId: number): void {
    this.confirmationDialogService
      .openConfirmationDialog('i18n.MyAccountContainerComponent.decline-pending-application-confirmation-text', 'warn')
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.roleService.patchRole(roleId, { status: RoleStatus.Refused });
        }
      });
  }

  onChangePasswordPressed(): void {
    const newPasswordDialogRef: MatDialogRef<ChangePasswordDialogContainerComponent> = this.dialogService.open(
      ChangePasswordDialogContainerComponent,
      {
        maxHeight: '90%',
        width: '90%',
        maxWidth: '1050px',
        minWidth: '640px'
      }
    );
  }
}
