import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterOldUserContainerComponent } from './containers/register-old-user-container/register-old-user-container.component';

const routes: Routes = [{ path: 'register-old/:token', component: RegisterOldUserContainerComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
