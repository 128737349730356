import { Role } from '@app/core/model/role.model';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

export enum RoleActionTypes {
  GET_ALL_ROLES = '[Role] Get All Roles',
  GET_ROLES_BY_USER_ID = '[Role] Get Roles By UserId',
  GET_ROLES_BY_COMPANY_ID = '[Role] Get Roles By CompanyId',
  GET_ROLES_SUCCESS = '[Role] Get Roles Success',
  GET_ROLES_FAILURE = '[Role] Get Roles Failure',
  GET_ROLE = '[Role] Get Role',
  GET_ROLE_SUCCESS = '[Role] Get Role Success',
  GET_ROLE_FAILURE = '[Role] Get Role Failure',
  ADD_ROLE = '[Role] Add Role',
  ADD_ROLE_SUCCESS = '[Role] Add Role Success',
  ADD_ROLE_FAILURE = '[Role] Add Role Failure',
  UPDATE_ROLE = '[Role] Update Role',
  UPDATE_ROLE_SUCCESS = '[Role] Update Role Success',
  UPDATE_ROLE_FAILURE = '[Role] Update Role Failure',
  DELETE_ROLE = '[Role] Delete Role',
  DELETE_ROLE_SUCCESS = '[Role] Delete Role Success',
  DELETE_ROLE_FAILURE = '[Role] Delete Role Failure',
  PROMOTE_USER = '[Role] Promote User',
  PROMOTE_USER_SUCCESS = '[Role] Promote User success',
  PROMOTE_USER_FAILURE = '[Role] Promote User failure'
}

export class GetAllRoles implements Action {
  readonly type = RoleActionTypes.GET_ALL_ROLES;
}

export class GetRolesByUserId implements Action {
  readonly type = RoleActionTypes.GET_ROLES_BY_USER_ID;
  constructor(public userId: number) {}
}

export class GetRolesByCompanyId implements Action {
  readonly type = RoleActionTypes.GET_ROLES_BY_COMPANY_ID;
  constructor(public companyId: number) {}
}

export class GetRolesSuccess implements Action {
  readonly type = RoleActionTypes.GET_ROLES_SUCCESS;
  constructor(public roles: Role[]) {}
}

export class GetRolesFailure implements Action {
  readonly type = RoleActionTypes.GET_ROLES_FAILURE;
}

export class GetRole implements Action {
  readonly type = RoleActionTypes.GET_ROLE;
  constructor(public roleId: number) {}
}

export class GetRoleSuccess implements Action {
  readonly type = RoleActionTypes.GET_ROLE_SUCCESS;
  constructor(public role: Role) {}
}

export class GetRoleFailure implements Action {
  readonly type = RoleActionTypes.GET_ROLE_FAILURE;
}

export class AddRole implements Action {
  readonly type = RoleActionTypes.ADD_ROLE;
  constructor(public role: Role) {}
}

export class AddRoleSuccess implements Action {
  readonly type = RoleActionTypes.ADD_ROLE_SUCCESS;
  constructor(public role: Role) {}
}

export class AddRoleFailure implements Action {
  readonly type = RoleActionTypes.ADD_ROLE_FAILURE;
}

export class UpdateRole implements Action {
  readonly type = RoleActionTypes.UPDATE_ROLE;
  constructor(public update: Update<Role>) {}
}

export class UpdateRoleSuccess implements Action {
  readonly type = RoleActionTypes.UPDATE_ROLE_SUCCESS;
  constructor(public update: Update<Role>) {}
}

export class UpdateRoleFailure implements Action {
  readonly type = RoleActionTypes.UPDATE_ROLE_FAILURE;
}

export class DeleteRole implements Action {
  readonly type = RoleActionTypes.DELETE_ROLE;
  constructor(public roleId: number) {}
}

export class DeleteRoleSuccess implements Action {
  readonly type = RoleActionTypes.DELETE_ROLE_SUCCESS;
  constructor(public roleId: number) {}
}

export class DeleteRoleFailure implements Action {
  readonly type = RoleActionTypes.DELETE_ROLE_FAILURE;
}

export class PromoteUser implements Action {
  readonly type = RoleActionTypes.PROMOTE_USER;
  constructor(public payload: Update<Role>) {} // TODO: fix type
}

export class PromoteUserSuccess implements Action {
  readonly type = RoleActionTypes.PROMOTE_USER_SUCCESS;
  constructor(public payload: Update<Role>) {}
}

export class PromoteUserFailure implements Action {
  readonly type = RoleActionTypes.PROMOTE_USER_FAILURE;
}
export type RoleActions =
  | GetAllRoles
  | GetRolesByUserId
  | GetRolesByCompanyId
  | GetRolesSuccess
  | GetRolesFailure
  | GetRole
  | GetRoleSuccess
  | GetRoleFailure
  | AddRole
  | AddRoleSuccess
  | AddRoleFailure
  | UpdateRole
  | UpdateRoleSuccess
  | UpdateRoleFailure
  | DeleteRole
  | DeleteRoleSuccess
  | DeleteRoleFailure
  | PromoteUser
  | PromoteUserSuccess
  | PromoteUserFailure;
