import { Survey, TravelPlan } from '@app/core/model';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

export enum SurveyActionTypes {
  GET_ALL_SURVEYS = '[Survey] Get All Surveys',
  GET_ALL_SURVEYS_SUCCESS = '[Survey] Get All Surveys Success',
  GET_ALL_SURVEYS_FAILURE = '[Survey] Get All Surveys Failure',
  IMPORT_CLEAR_STATE = '[Survey] Import Clear State',
  IMPORT_SURVEY = '[Survey] Import Survey',
  IMPORT_SURVEY_SUCCESS = '[Survey] Import Survey Success',
  IMPORT_SURVEY_FAILURE = '[Survey] Import Survey Failure',
  IMPORT_EXCEL_FILE = '[Survey] Import Excel File',
  IMPORT_EXCEL_FILE_SUCCESS = '[Survey] Import Excel File Success',
  IMPORT_EXCEL_FILE_FAILURE = '[Survey] Import Excel File Failure',
  IMPORT_EXCEL_FILE_DENIED = '[Survey] Import Excel File Denied',
  IMPORT_EXCEL_FILE_NOT_VALID = '[Survey] Import Excel File Not Valid'
}

export class GetAllSurveys implements Action {
  readonly type = SurveyActionTypes.GET_ALL_SURVEYS;
}

export class GetAllSurveysSuccess implements Action {
  readonly type = SurveyActionTypes.GET_ALL_SURVEYS_SUCCESS;
  constructor(public surveys: Survey[]) {}
}

export class GetAllSurveysFailure implements Action {
  readonly type = SurveyActionTypes.GET_ALL_SURVEYS_FAILURE;
}

export class ImportClearState implements Action {
  readonly type = SurveyActionTypes.IMPORT_CLEAR_STATE;
}

export class ImportSurvey implements Action {
  readonly type = SurveyActionTypes.IMPORT_SURVEY;
  constructor(public planId: number, public exportURL: string, public surveyID: number) {}
}

export class ImportSurveySuccess implements Action {
  readonly type = SurveyActionTypes.IMPORT_SURVEY_SUCCESS;
  constructor(public travelPlan: Update<TravelPlan>) {}
}

export class ImportSurveyFailure implements Action {
  readonly type = SurveyActionTypes.IMPORT_SURVEY_FAILURE;
}

export class ImportExcelFile implements Action {
  readonly type = SurveyActionTypes.IMPORT_EXCEL_FILE;
  constructor(public planId: number, public excelFileName: string, public excelFileAsBase64: string, public ignoreValidation: boolean) {}
}

export class ImportExcelFileSuccess implements Action {
  readonly type = SurveyActionTypes.IMPORT_EXCEL_FILE_SUCCESS;
  constructor(public travelPlan: Update<TravelPlan>) {}
}

export class ImportExcelFileNotValid implements Action {
  readonly type = SurveyActionTypes.IMPORT_EXCEL_FILE_NOT_VALID;
  constructor(public result: any) {}
}

export class ImportExcelFileFailure implements Action {
  readonly type = SurveyActionTypes.IMPORT_EXCEL_FILE_FAILURE;
}

export class ImportExcelFileDenied implements Action {
  readonly type = SurveyActionTypes.IMPORT_EXCEL_FILE_DENIED;
  constructor(public errorMessage: string) {}
}

export type SurveyActions =
  | GetAllSurveys
  | GetAllSurveysSuccess
  | GetAllSurveysFailure
  | ImportClearState
  | ImportSurvey
  | ImportSurveySuccess
  | ImportSurveyFailure
  | ImportExcelFileDenied
  | ImportExcelFile
  | ImportExcelFileSuccess
  | ImportExcelFileFailure
  | ImportExcelFileNotValid;
