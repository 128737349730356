import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { Action, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthActionTypes } from './actions';
import { AppState, reducers } from './app.states';
import {
  AuthEffects,
  CompanyEffects,
  EstablishmentUnitEffects,
  FormEffects,
  RoleEffects,
  SiteEffects,
  SurveyEffects,
  TravelPlanEffects,
  UserEffects,
  PermissionEffect
} from './effects';

export function clearState(reducer) {
  return (state: AppState, action: Action) => {
    if (action.type === AuthActionTypes.LOGOUT) {
      state = {
        auth: state.auth,
        companies: undefined,
        establishmentUnits: undefined,
        forms: undefined,
        roles: undefined,
        sites: undefined,
        surveys: undefined,
        travelPlans: undefined,
        users: undefined,
        permissions: undefined
      };
    }

    return reducer(state, action);
  };
}

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
    EffectsModule.forRoot([
      AuthEffects,
      UserEffects,
      CompanyEffects,
      RoleEffects,
      SiteEffects,
      EstablishmentUnitEffects,
      FormEffects,
      TravelPlanEffects,
      SurveyEffects,
      PermissionEffect
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ]
})
export class AppStoreModule {}
