<h3 mat-dialog-title>
  {{ 'i18n.ForgottenDialogComponent.title' | translate }}
</h3>

<div mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div *ngFor="let settings of fieldsSettings" fxFlex.gt-xs="0 0 100%">
        <app-custom-form-field [settings]="settings"></app-custom-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="10px">
        <button mat-stroked-button color="primary" (click)="onForgottenButtonPressed()">
          {{ 'i18n.ForgottenDialogComponent.reset-password' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
